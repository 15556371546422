import React, { useContext, useEffect, useState } from 'react';
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Card,
  Container,
  Row,
  Modal,
  CardBody,
  CardHeader,
  Col,
  Button
} from "reactstrap";
// core components
import TablesHeader from "components/Headers/TablesHeader.js";
import ExpotCsv from "services/ExpotCsv.js";
//context
import UsersContext from 'context/UsersContext';
import UserContext from 'context/UserContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import getUsers from 'services/getUsers';
//
import Loader from "components/custom/Loader";
import ResetPass from '../forms/ResetPass';
import { Person } from 'react-bootstrap-icons';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        הצג{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        רשומות.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function UsersTable() {
  const { users, setUsers } = useContext(UsersContext);
  const { user } = useContext(UserContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [pending, setPending] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [formModal, setformModal] = useState(false);

  function modalCallback(status) {
    setformModal(status)
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setCurrentUser(row)
      setformModal(true)
    }
  };

  function callGetUsers() {
    if (localStorage.getItem("token")) {
      setPending(true);
      let token = localStorage.getItem("token");
      if (token) {
        getUsers()
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setUsers([]);
                setErrorMsg({ error: true, msg: 'שעות לימוד - error' });
                setPending(false);

              }
              else {
                setUsers(data);
                setPending(false);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setUsers([]);
            setErrorMsg({ error: true, msg: 'שעות לימוד - error' });
            setPending(false);
          }
          );
      }
    }
  }

  useEffect(() => {
    callGetUsers();
  }, [])

  return (
    <>
      <TablesHeader name="סטודנטים" parentName="Tables" color="blue" add_text='סטודנט חדש'
        add_link = 'https://app.powerlink.co.il/app/views/1'
        add_icon = {<Person size={30} className="mb-2" />} link = {false}/>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0">
            <ResetPass
              user={currentUser}
              modalCallback={modalCallback}
            />
          </Card>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={users}
                keyField="ID"
                columns={[
                  {
                    dataField: "ID",
                    text: "ID",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "display_name",
                    text: "שם",
                    sort: true,
                  },
                  {
                    dataField: "user_email",
                    text: "אימייל",
                    sort: true,
                  },
                  {
                    dataField: "phone",
                    text: "פלאפון",
                    sort: true,
                  },
                  {
                    dataField: "guide",
                    text: "מנחה",
                    sort: true,
                  },
                  {
                    dataField: "contact",
                    text: "איש קשר",
                    sort: true,
                  },
                  {
                    dataField: "positive",
                    text: "שעות שנצברו",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "negative",
                    text: "שעות שנוצלו",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "total",
                    text: "יתרת שעות",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "passport",
                    text: "תעודת זהות",
                    sort: true,
                  },
                  {
                    dataField: "olami",
                    text: "עולמי",
                    sort: true,
                  },
                  {
                    dataField: "create_time",
                    text: "עדכון אחרון",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row className="d-flex justify-content-between w-100 px-4 pb-1">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label className="ml-1">
                          חיפוש:
                        </label>
                        <SearchBar
                          className="form-control-sm"
                          placeholder="חיפוש וסינון"
                          {...props.searchProps}
                        />
                      </div>
                      <div className="text-div">
                        <ExpotCsv url='/wp-json/achim/v1/users/hours' size='sm' color='info' text='ייצוא לאקסל' fileTitle='משתמשים' />
                      </div>
                    </Row>
                    {pending && <Loader text="" />}
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      rowEvents={rowEvents}
                      bordered={false}
                      className="text-right"
                      rowClasses="text-right hover-row-class"
                      id="react-bs-table"
                      defaultSorted={[{
                        dataField: 'ID',
                        order: 'asc'
                      }]}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default UsersTable;
