export default function resetWinners(lottery) {
    let token = localStorage.getItem("token");
    let url = `/wp-json/achim/v1/lottery/${lottery}/resetwinners`;


    let headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Authorization', 'Bearer ' + token);

    let options = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}