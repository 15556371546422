import React, { useState, useContext } from 'react';
//context
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import resetPass from 'services/resetPass';
// reactstrap components
import {
  Button,
  CardBody,
  Row,
  Col,
  CardHeader
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";

function ResetPass({ user, modalCallback }) {
  const { display_name, user_email, ID } = user;
  const [pending, setPending] = useState(false);
  const { setErrorMsg } = useContext(ErrorMsgContext);

  function postReset() {
    setPending(true);
    resetPass(ID)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            setErrorMsg({ error: true, msg: data });
            setPending(false);
          }
          else {
            setPending(false);
            modalCallback(false);
          }
        })
      .catch((err) => {
        setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending text="" />}
      <CardHeader className="text-center ">
        <h1 className='text-achim-black'>משתמש: {display_name}</h1>
        <h3 className='text-achim-black'>אימייל: {user_email}</h3>
      </CardHeader>
      <CardBody className="text-center">
        <Row className="p-0 m-0">
          <Col>
            <Button
              color="dark"
              className="bg-achim-orange achim-text-black border-0"
              size="md"
              onClick={() => {
                postReset();
              }}
            >
              שלח מייל לאיפוס / הגדרת סיסמה
            </Button>
          </Col>
        </Row>
      </CardBody>
    </>
  );
}

export default ResetPass;
