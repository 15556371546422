function getSummaries1() {
    let token = localStorage.getItem("token");

    let urls = [
        `/wp-json/achim/v1/hours/summary`,
        `/wp-json/achim/v1/hours/summary/week`,
        `/wp-json/achim/v1/hours/summary/week/days`,
        `/wp-json/achim/v1/hours/summary/month/days`,
        `/wp-json/achim/v1/hours/summary/months`,
        `/wp-json/achim/v1/hours/summary/days`,
        `/wp-json/achim/v1/hours/summary/hours`,
        `/wp-json/achim/v1/hours/summary/withoutbonus`,
        `/wp-json/achim/v1/users/summary`,
        `/wp-json/achim/v1/hours/summary/month`,
        `/wp-json/achim/v1/hours/last`
    ]
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return Promise.all(urls.map(u => fetch(u, options))).then(responses =>
        Promise.all(responses.map(res => res.json()))
    ).then(res => {
        return res;
    })
}

export default function getSummaries() {
    let token = localStorage.getItem("token");
    let url = `/wp-json/achim/v1/summaries`;
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}