import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

function TimelineHeader({ name, add_text, add_link, add_icon, color, link }) {
  return (
    <>
      <div className={classnames("header", "bg-achim-" + color, "pb-6 pt-1")}>
        <Container fluid>
          <Row className="align-items-center py-4 text-center justify-content-end">
            <Col>
            <h6 className="display-2 text-white mb-0 m-auto">
                {name}
              </h6>
            </Col>
            <div className="position-absolute text-white pt-3 p-md-4 slide-down">
              {link ? <Link to={add_link}>
                <div className="text-center text-white"> {add_icon}</div>
                <h2 className="h5 text-center text-white lh-100 font-weight-300">{add_text}</h2>
              </Link>
                :
                <a href={add_link} target={"_blank"}>
                  <div className="text-center text-white"> {add_icon}</div>
                  <h2 className="h5 text-center text-white lh-100 font-weight-300">{add_text}</h2>
                </a>
              }
            </div>
          </Row>
          <div className="slide-top header-body">
            {/*  <Row className="d-none align-items-center py-4">
              <Breadcrumb
                className="d-md-inline-block ml-lg-4"
                listClassName="breadcrumb-links breadcrumb-dark"
              >
                <BreadcrumbItem className="ml-2">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="fas fa-home" />
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {parentName}
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  {name}
                </BreadcrumbItem>
              </Breadcrumb>
            </Row> */}
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
