/* import Alternative from "views/pages/dashboards/Alternative.js"; */
import Benefits from "views/pages/benefits/Benefits.js";
import BenefitPage from "views/pages/benefits/BenefitPage.js";
import BenefitAdminPage from "views/pages/benefits/BenefitAdminPage.js";
import LotteryAdminPage from "views/pages/lotteties/LotteryAdminPage.js";
/* import Buttons from "views/pages/components/Buttons.js";
import Calendar from "views/pages/Calendar.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import ContactForm from "views/pages/forms/ContactForm.js";
import Components from "views/pages/forms/Components.js"; */
import Dashboard from "views/pages/dashboards/Dashboard.js";
import DashboardAchim from "views/pages/dashboards/DashboardAchim.js";
/* import Elements from "views/pages/forms/Elements.js"; */
import Events from "views/pages/events/Events.js";
/* import Google from "views/pages/maps/Google.js";
import Grid from "views/pages/components/Grid.js"; */
import Hours from "views/pages/hours/Hours.js";
import HoursAdd from "views/pages/forms/HoursAdd.js";
import NewLottery from "views/pages/forms/NewLottery.js";
import Payment from "views/pages/forms/Payment.js";
/* import IndexView from "views/Index.js"; */
/* import Icons from "views/pages/components/Icons.js";
import Lock from "views/pages/examples/Lock.js";*/
import Login from "views/pages/examples/Login.js";
import Profile from "views/pages/examples/Profile.js";
/*import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Register from "views/pages/examples/Register.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js"; */
import Studyes from "views/pages/studyes/Studyes.js";
import StudyAdninPage from "views/pages/studyes/StudyAdninPage.js";
import UsersTable from "views/pages/tables/UsersTable.js";
import HoursTable from "views/pages/tables/HoursTable.js";
import BenefitsTable from "views/pages/tables/BenefitsTable.js";
import LotteriesTable from "views/pages/tables/LotteriesTable.js";
/*import Timeline from "views/pages/examples/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
import Vector from "views/pages/maps/Vector.js";
import Widgets from "views/pages/Widgets.js"; */
import { PersonFill, DoorOpen, House, Book, Clock, Speedometer2, CalendarEvent, CurrencyDollar, Gift } from 'react-bootstrap-icons';
import Lotteries from "views/pages/benefits/Lotteries";
import StudyPage from "views/pages/studyes/StudyPage";
import LotteryPage from "views/pages/benefits/LotteryPage";
import StudyesTable from "views/pages/tables/StudyesTable";

const routes = [
  {
    path: "/home",
    name: "דף הבית",
    icon: <House size={19} className="mb-1" />,
    component: DashboardAchim,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/hours",
    name: "שעות לימוד",
    icon: <Clock size={19} className="mb-1" />,
    component: Hours,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/benefits",
    name: "הטבות",
    icon: <DoorOpen size={19} className="mb-1" />,
    component: Benefits,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/Lotteries",
    name: "הטבות",
    icon: <DoorOpen size={19} className="mb-1" />,
    component: Lotteries,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/benefit/:id",
    name: "הטבות",
    icon: <DoorOpen size={19} className="mb-1" />,
    component: BenefitPage,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/study",
    name: "תכני לימוד",
    icon: <Book size={19} className="mb-1" />,
    component: Studyes,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/studies/:id",
    name: "תוכן",
    icon: <Book size={19} className="mb-1" />,
    component: StudyPage,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/lottery/:id",
    name: "תוכן",
    icon: <Book size={19} className="mb-1" />,
    component: LotteryPage,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/events",
    name: "אירועים",
    icon: <CalendarEvent size={19} className="mb-1" />,
    component: Events,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/profile",
    name: "פרופיל",
    icon: <CalendarEvent size={19} className="mb-1" />,
    component: Profile,
    menu: "app",
    layout: "/app",
  },
  ///
  {
    path: "/add-hour",
    name: "דיווח שעת לימוד",
    icon: <Clock size={19} className="mb-1" />,
    component: HoursAdd,
    menu: "action",
    layout: "/app",
  },
  {
    path: "/dashboard",
    name: "דשבורד",
    icon: <Speedometer2 size={19} className="mb-1" />,
    component: Dashboard,
    menu: "admin",
    layout: "/app",
  },
  {
    path: "/users",
    name: "משתמשים",
    icon: <PersonFill size={19} className="mb-1" />,
    component: UsersTable,
    menu: "admin",
    layout: "/app",
  },
  {
    path: "/all-hours",
    name: "שעות לימוד",
    icon: <Clock size={19} className="mb-1" />,
    component: HoursTable,
    menu: "admin",
    layout: "/app",
  },
  {
    path: "/benefits-admin",
    name: "הטבות",
    icon: <DoorOpen size={19} className="mb-1" />,
    component: BenefitsTable,
    menu: "admin",
    layout: "/app",
  },
  {
    path: "/benefit-admin/:id",
    name: "הטבה",
    icon: <DoorOpen size={19} className="mb-1" />,
    component: BenefitAdminPage,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/studyes-admin",
    name: "מערכי לימוד",
    icon: <Book size={19} className="mb-1" />,
    component: StudyesTable,
    menu: "admin",
    layout: "/app",
  },
  {
    path: "/study-admin/:id",
    name: "תוכן",
    icon: <DoorOpen size={19} className="mb-1" />,
    component: StudyAdninPage,
    menu: "app",
    layout: "/app",
  },
  {
    collapse: true,
    name: "הגרלות",
    icon: <Gift size={19} className="mb-1" />,
    state: "mapsCollapse",
    menu: "admin",
    layout: "/app",
    views: [
      {
        path: "/lotteriestable",
        name: "הגרלות",
        miniName: "ה",
        component: LotteriesTable,
        menu: "admin",
        layout: "/app",
      },
      {
        path: "/new-lottery",
        name: "הגרלה חדשה",
        miniName: "ג",
        component: NewLottery,
        menu: "admin",
        layout: "/app",
      },
    ],
  },
  {
    path: "/lottery-admin/:id",
    name: "הגרלה",
    icon: <DoorOpen size={19} className="mb-1" />,
    component: LotteryAdminPage,
    menu: "app",
    layout: "/app",
  },
  {
    path: "/payment",
    name: "טופס תשלום",
    icon: <CurrencyDollar size={19} className="mb-1" />,
    component: Payment,
    menu: "admin",
    layout: "/app",
  },
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
  },
  /* {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [

      {
        path: "",
        name: "index",
        miniName: "D",
        component: IndexView,
        layout: "/index",
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        miniName: "D",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/alternative-dashboard",
        name: "Alternative",
        miniName: "A",
        component: Alternative,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        miniName: "P",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/auth",
      },
      {
        path: "/timeline",
        name: "Timeline",
        miniName: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        miniName: "RS",
        component: RTLSupport,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    icon: "ni ni-ui-04 text-info",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        miniName: "B",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/cards",
        name: "Cards",
        miniName: "C",
        component: Cards,
        layout: "/admin",
      },
      {
        path: "/grid",
        name: "Grid",
        miniName: "G",
        component: Grid,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        miniName: "N",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        miniName: "I",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        miniName: "T",
        component: Typography,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "Multi Level",
        miniName: "M",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => { },
            layout: "/index",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => { },
            layout: "/index",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => { },
            layout: "/index",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    icon: "ni ni-single-copy-04 text-pink",
    state: "formsCollapse",
    views: [
      {
        path: "/elements",
        name: "Elements",
        miniName: "E",
        component: Elements,
        layout: "/admin",
      },
      {
        path: "/components",
        name: "Components",
        miniName: "C",
        component: Components,
        layout: "/admin",
      },
      {
        path: "/validation",
        name: "Validation",
        miniName: "V",
        component: Validation,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    icon: "ni ni-align-left-2 text-default",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Tables",
        miniName: "T",
        component: Tables,
        layout: "/admin",
      },
      {
        path: "/sortable",
        name: "Sortable",
        miniName: "S",
        component: Sortable,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "React BS Tables",
        miniName: "RBT",
        component: ReactBSTables,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    icon: "ni ni-map-big text-primary",
    state: "mapsCollapse",
    views: [
      {
        path: "/google",
        name: "Google",
        miniName: "G",
        component: Google,
        layout: "/admin",
      },
      {
        path: "/vector",
        name: "Vector",
        miniName: "V",
        component: Vector,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    icon: "ni ni-archive-2 text-green",
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "ni ni-chart-pie-35 text-info",
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/admin",
  },
 */];
/* const routes1 = [
  {
    path: "/home",
    name: "דף הבית",
    icon: <House size={19} className="mb-1" />,
    component: DashboardAchim,
    layout: "/app",
  },
  {
    path: "/hours",
    name: "שעות לימוד",
    icon: <Clock size={19} className="mb-1" />,
    component: Hours,
    layout: "/app",
  },
  {
    collapse: true,
    name: "הטבות",
    icon: <DoorOpen size={19} className="mb-1" />,
    state: "benefitsCollapse",
    layout: "/app",
    views: [
      {
        path: "/benefits",
        name: "הכל",
        miniName: "נ",
        component: Benefits,
        layout: "/app",
      },
      {
        path: "/benefits/courses",
        name: "קורסים",
        miniName: "מ",
        component: Tables,
        layout: "/app",
      },
      {
        path: "/benefits/doors",
        name: "דלתות",
        miniName: "נ",
        component: Tables,
        layout: "/app",
      },
      {
        path: "/benefits/new",
        name: "מתנות",
        miniName: "נ",
        component: Tables,
        layout: "/app",
      },
      {
        path: "/benefits/vip",
        name: "vip",
        miniName: "נ",
        component: Tables,
        layout: "/app",
      }
    ],
  },
  {
    collapse: true,
    name: "תכני לימוד",
    icon: <Book size={19} className="mb-1" />,
    state: "studyCollapse",
    layout: "/app",
    views: [
      {
        path: "/study",
        name: "הכל",
        miniName: "ה",
        component: Studyes,
        layout: "/app",
      },
      {
        path: "/study/series",
        name: "סדרות לימוד",
        miniName: "ס",
        component: Tables,
        layout: "/app",
      },
      {
        path: "/study/idea",
        name: "יש לי רעיון",
        miniName: "ר",
        component: Tables,
        layout: "/app",
      },
      {
        path: "/study/weekly",
        name: "פרשת השבוע",
        miniName: "נ",
        component: Tables,
        layout: "/app",
      },
      {
        path: "/study/new",
        name: "החדשים",
        miniName: "נ",
        component: Tables,
        layout: "/app",
      }
    ],
  },
  {
    collapse: true,
    name: "אירועים",
    icon: <CalendarEvent size={19} className="mb-1" />,
    state: "eventsCollapse",
    layout: "/app",
    views: [
      {
        path: "/events/reg",
        name: "הרשמה לאירועים",
        miniName: "ה",
        component: Events,
        layout: "/app",
      },
      {
        path: "/events/archive",
        name: "ארכיון אירועים",
        miniName: "א",
        component: Events,
        layout: "/app",
      }
    ],
  },
  ///
  {
    path: "/add-hour",
    name: "דיווח שעת לימוד",
    icon: <Clock size={19} className="mb-1" />,
    component: HoursAdd,
    layout: "/action",
  },
  {
    path: "/benefits",
    name: "הרשמה להטבות",
    icon: <DoorOpen size={19} className="mb-1" />,
    component: ContactForm,
    layout: "/action",
  },
  {
    path: "/tickets",
    name: "פתיחת פניה",
    icon: <Envelope size={19} className="mb-1" />,
    component: ContactForm,
    layout: "/action",
  },
  {
    collapse: true,
    name: "Dashboards",
    icon: "ni ni-shop text-primary",
    state: "dashboardsCollapse",
    views: [

      {
        path: "",
        name: "index",
        miniName: "D",
        component: IndexView,
        layout: "/index",
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        miniName: "D",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/alternative-dashboard",
        name: "Alternative",
        miniName: "A",
        component: Alternative,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        miniName: "P",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/auth",
      },
      {
        path: "/timeline",
        name: "Timeline",
        miniName: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        miniName: "RS",
        component: RTLSupport,
        layout: "",
      },
    ],
  },
  {
    collapse: true,
    name: "Components",
    icon: "ni ni-ui-04 text-info",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        miniName: "B",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/cards",
        name: "Cards",
        miniName: "C",
        component: Cards,
        layout: "/admin",
      },
      {
        path: "/grid",
        name: "Grid",
        miniName: "G",
        component: Grid,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        miniName: "N",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        miniName: "I",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        miniName: "T",
        component: Typography,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "Multi Level",
        miniName: "M",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => { },
            layout: "/index",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => { },
            layout: "/index",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => { },
            layout: "/index",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    icon: "ni ni-single-copy-04 text-pink",
    state: "formsCollapse",
    views: [
      {
        path: "/elements",
        name: "Elements",
        miniName: "E",
        component: Elements,
        layout: "/admin",
      },
      {
        path: "/components",
        name: "Components",
        miniName: "C",
        component: Components,
        layout: "/admin",
      },
      {
        path: "/validation",
        name: "Validation",
        miniName: "V",
        component: Validation,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    icon: "ni ni-align-left-2 text-default",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Tables",
        miniName: "T",
        component: Tables,
        layout: "/admin",
      },
      {
        path: "/sortable",
        name: "Sortable",
        miniName: "S",
        component: Sortable,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "React BS Tables",
        miniName: "RBT",
        component: ReactBSTables,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    icon: "ni ni-map-big text-primary",
    state: "mapsCollapse",
    views: [
      {
        path: "/google",
        name: "Google",
        miniName: "G",
        component: Google,
        layout: "/admin",
      },
      {
        path: "/vector",
        name: "Vector",
        miniName: "V",
        component: Vector,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    icon: "ni ni-archive-2 text-green",
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "ni ni-chart-pie-35 text-info",
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: "ni ni-calendar-grid-58 text-red",
    component: Calendar,
    layout: "/admin",
  },
]; */

export default routes;
