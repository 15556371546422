import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import HoursHeader from "components/Headers/HoursHeader.js";
//context
import HoursDetailsContext from 'context/HoursDetailsContext';
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import getHours from 'services/getHours';

function Tables() {
  const { hoursDetails, setHoursDetails } = useContext(HoursDetailsContext);
  const { setHours } = useContext(HoursContext);
  const { user } = useContext(UserContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [typeView, setTypeView] = useState('positive');

  function handletypeView(type) {
    setTypeView(type);
  };

  function callGetHours() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getHours(user.ID)
          .then(
            (response) => {
              const { success, data } = response[1];
              if (!success) {
                setHours({ negative: 0, positive: 0, total: 0 });
                setHoursDetails([]);
                setErrorMsg({ error: true, msg: 'שעות לימוד - error' });
              }
              else {
                setHours(data[0]);
                setHoursDetails(response[0].data);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setHours({ negative: 0, positive: 0, total: 0 });
            setHoursDetails([]);
            setErrorMsg({ error: true, msg: 'שעות לימוד - error' });
          }
          );
      }
    }
  }

  useEffect(() => {
    callGetHours();
  }, [])

  function getRows() {

    if (hoursDetails.length < 1 || hoursDetails === "No record found") {
      return <tr>
        <th scope="row" colSpan="4" className="text-center">אין מידע על שעות {typeView === 'negative' ? 'שניצלת' : 'שצברת'}</th>
      </tr>
    }
    return hoursDetails.reduce(function (filtered, hour) {
      let type = '';
      //positive
      typeView === 'negative' ? type = -1 : type = 1;
      if (Math.sign(hour.amount) === type) {
        const someNewValue =
          <tr key={hour.ID} className="text-right">
            <td>{hour.type}</td>
            <td>{hour.amount}</td>
            <td>{hour.study_topic}</td>
            <td>{hour.date}</td>
          </tr>
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);
  }

  return (
    <>
      <HoursHeader typeView={typeView} setTypeView={handletypeView} />
      <Container className="mt--6" fluid>
        <Row className="justify-content-center">
          <Col sm="10" md="8" xl="6">
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0 text-center">שעות {typeView === 'negative' ? 'שניצלתי' : 'לימוד שצברתי'}</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">סוג</th>
                    <th scope="col">שעות</th>
                    <th scope="col">{typeView === 'negative' ? 'נושא' : 'הנושא הנלמד'}</th>
                    <th scope="col">תאריך</th>
                  </tr>
                </thead>
                <tbody>
                  {getRows()}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Tables;
