import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import CardsHeaderAchim from "components/Headers/CardsHeaderAchim.js";
import CarouselStudy from "components/carousel/CarouselStudy";
import CarouselBenefits from "components/carousel/CarouselBenefits";
import EventSlider from "views/pages/events/EventSlider";
import Lottery from "views/pages/benefits/Lottery";

function DashboardAchim() {

  return (
    <>
      <CardsHeaderAchim />
      <div fluid className="bg-achim-pink px-lg-5">
        <Row className="p-0 m-0">
          <Col md="6" xl="5"
            className="d-flex align-items-center
            py-md-4 pt-xl-0 pt-4"
          >
            <Container fluid>
              <h2 className="display-2 text-white mb-3 text-center">ההטבות החדשות:</h2>
              <Col className="p-0 w-100">
                <Col className="p-0">
                  <Lottery />
                </Col>
                {/* <CarouselBenefits /> */}
                <Link to="/app/benefits">
                  <div className="d-flex justify-content-center">
                    <Button className="bg-white text-achim-pink" color="pink">
                      לכל ההטבות <i className="fas fa-chevron-left fa-xs"></i>
                    </Button>
                  </div>
                </Link>
              </Col>
            </Container>
          </Col>
          <Col md="6" xl="7" className="mt--3 mt-sm-0 pt-0 pt-sm-4 d-flex align-items-end">
            <img
              alt="Image placeholder"
              src={require("assets/img/theme/door_gifts.svg").default}
              width="100%"
            />
          </Col>
        </Row>
      </div>
      <div fluid className="bg-achim-orange px-lg-5">
        <Row className="p-0 m-0">
          <Col md="6" xl="5"
            className="d-flex align-items-center
            py-md-4 pt-xl-0 pt-4"
          >
            <Container fluid>
              <h2 className="display-2 text-white mb-3 text-center">תכני לימוד</h2>
              <Col className="p-0 w-100">
                <Col className="p-0">
                  <CarouselStudy />
                </Col>
                <Link to="/app/study">
                  <div className="d-flex justify-content-center">
                    <Button className="bg-white text-achim-orange" color="orange">
                      לכל תכני הלימוד <i className="fas fa-chevron-left fa-xs"></i>
                    </Button>
                  </div>
                </Link>
              </Col>
            </Container>
          </Col>
          <Col md="6" xl="7" className="mt--3 mt-sm-0 pt-0 pt-sm-4 d-flex align-items-end">
            <img
              alt="Image placeholder"
              src={require("assets/img/theme/books.svg").default}
              width="100%"
            />
          </Col>
        </Row>
      </div>
      <div fluid className="bg-achim-green px-lg-5">
        <Row className="p-0 m-0">
          <Col md="6" xl="5"
            className="d-flex align-items-center
            py-md-4 pt-xl-0 pt-4"
          >
            <Container fluid>
              <h2 className="display-2 text-white mb-3 text-center">אירועי אחים לנפש</h2>
              <Col className="p-0 w-100">
                <Col className="p-0">
                  <EventSlider />
                </Col>
                <Link to="/app/events">
                  <div className="d-flex justify-content-center">
                    <Button className="bg-white text-achim-green" color="green">
                      לכל האירועים <i className="fas fa-chevron-left fa-xs"></i>
                    </Button>
                  </div>
                </Link>
              </Col>
            </Container>
          </Col>
          <Col md="6" xl="7" className="mt--3 mt-sm-0 pt-0 pt-sm-4 d-flex align-items-end">
            <img
              alt="Image placeholder"
              src={require("assets/img/theme/event.svg").default}
              width="100%"
            />
          </Col>
        </Row>
      </div>
      {/*       <Row className="p-0 m-0">
          <Container fluid className="p-5">
            <h2 className="text-achim-blue text-center display-4 font-weight-400">אחים לנפש מספרים:</h2>
            <p className="lead text-center">
              "שעת הלימוד השבועית מכניסה לי אור לכל השבוע. ההטבות והדלתות זה בונוס לנפש"
            </p>
            <p className="blockquote-footer text-center display-4 font-weight-500 text-achim-blue">
              ישראל ישראלי
            </p>
          </Container>
      </Row> */}
    </>
  );
}

export default DashboardAchim;
