import React from 'react';

const NotificationsContext = React.createContext({
    notifications: [
        {
            subject: 'ברוכים הבאים',
            notification: 'זו הודעה זמנית',
            link: 'link',
            create_time: 'לפני יומיים'
        },
        {
            subject: 'ברוכים הבאים',
            notification: 'זו הודעה זמנית נוספת',
            link: 'link',
            create_time: 'לפני 3 שעות'
        }
    ],
    setNotificationsContext: () => { }
})

export default NotificationsContext;