import React, { useContext, useEffect, useState } from 'react';
//context
import StudyesContext from 'context/StudyesContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import CardsHeader from "components/Headers/StudyHeader";
import Study from "./Study";
import Lottery from "views/pages/benefits/Lottery";
//services
import getStudyes from 'services/getStudyes';


function Studyes() {
  const { studyes, setStudyes } = useContext(StudyesContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [isLoad, setIsLoad] = useState(studyes.length);

  function callGet() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getStudyes()
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setStudyes([]);
                setIsLoad(true);
                setErrorMsg({ error: true, msg: 'תכנים - error' });
              }
              else {
                setStudyes(data);
                setIsLoad(true);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setStudyes([]);
            setErrorMsg({ error: true, msg: 'תכנם - error c' });
            setIsLoad(true);
          }
          );
      }
    }
  }

  useEffect(() => {
    callGet();
  }, [])

  const createBenefits = () => {
    return studyes.map((prop, key) => {
      return (
        <Col key={key} md="6" lg="5" xl="4" className="scale-in-center d-flex align-self-stretch">
          <Study
            key={key}
            props={prop}
            num={key}
          />
        </Col>
      )
    })
  }

  return (
    <>
      <CardsHeader name="תכני לימוד" parentName="Components" />
      <Container className="mt--6" >
        <Row className="slide-top card-wrapper justify-content-center px-3 px-sm-0 ">
          {/* <Col className="px-2" md="6" lg="5" xl="4" className="scale-in-center">
            <Lottery />
          </Col> */}
          {isLoad ?
            <>
              {createBenefits()}
            </>
            :
            <div>
              <Row className="w-100 p-0 m-0 justify-content-center">
                <img
                  alt="load"
                  className=""
                  style={{
                    maxHeight: 128,
                    maxWidth: 160
                  }}
                  src={require("assets/img/brand/nefesh to achim.svg").default}
                />

              </Row>
              <Row className="p-0 m-0 w-100 justify-content-center">
                <h1 className="load-text">ההטבות בדרך</h1>
              </Row>
            </div>
          }
        </Row>
      </Container>
    </>
  );
}

export default Studyes;

