import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from "react-router-dom";
//context
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import postStudyComment from 'services/postStudyComment';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import ActionHeader from "components/Headers/ActionHeader.js";
import Pending from "components/custom/Pending";

function StudyComment({ id, question, answer, answered, setAnswered, setAnswer, study }) {
  const [pending, setPending] = useState(false);
  const { user } = useContext(UserContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [formValues, setFormValues] = useState({
    user: user.ID,
    study: id,
    answer: answer,
    note: 'תשובה למערך "' + study.post_title + '"'
  });

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  useEffect(() => {
    setFormValues({
      ...formValues,
      ['answer']: answer
    });
  }, [answer])

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setHour();
  }

  function setHour() {
    setPending(true);
    postStudyComment(user.ID, formValues)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            setErrorMsg({ error: true, msg: 'העדכון נכשל, נסה/י שוב' });
            setPending(false);
          }
          else {
            setAnswered(true);
            setAnswer(answer);
            setPending(false);
          }
        })
      .catch((err) => {
        setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending />}
      <Form role="form" onSubmit={handleSubmit} className="p-3">
        <Row>
        </Row>
        <Row className='mb-3'>
          <label
            className="form-control-label"
            htmlFor="answer"
          >
            <span className='text-right font-weight-500' dangerouslySetInnerHTML={{ __html: question }} />
          </label>
          <Input
            disabled={answered}
            id="answer"
            rows="3"
            type="textarea"
            name="answer"
            className='block'
            placeholder="התשובה"
            onChange={handleChange}
            onBlur={handleChange}
            value={formValues.answer}
          />
        </Row>
        <Row>
          {!answered ?
            <Button
              block
              color="green"
              className="bg-achim-green text-white"
              type="submit"
            >
              שליחה  <i className="text-white fas fa-chevron-left fa-xs"></i>
            </Button>
            :
            <div className='text-achim-green h3'>
              ענית על מערך זה, וזכית בכרטיס הגרלה נוסף!
            </div>
          }
        </Row>
      </Form>

    </>
  );
}

export default StudyComment;
