import React from 'react';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";

import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";

function Bars({ dataToBar }) {

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // Colors
  var colors = {
    gray: {
      100: "#f6f9fc",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#8898aa",
      700: "#525f7f",
      800: "#32325d",
      900: "#212529",
    },
    theme: {
      default: "#172b4d",
      primary: "#5e72e4",
      secondary: "#f4f5f7",
      info: "#11cdef",
      success: "#2dce89",
      danger: "#f5365c",
      warning: "#172b4d",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
  };

  let chartExample2 = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: colors.gray[200],
              zeroLineColor: colors.gray[200],
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  return value + " שעות";
                }
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || "";
            var yLabel = item.yLabel;
            var content = "";
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          },
        },
      },
    },
    data: {
      labels: dataToBar.map(obj => { return obj.h }),
      datasets: [
        {
          label: "שעות",
          data: dataToBar.map(obj => { return obj.positive }),
          maxBarThickness: 10,
        },
      ],
    },
  };

  return (
    <>
      <div className="chart">
        <Bar
          data={chartExample2.data}
          options={chartExample2.options}
          className="chart-canvas"
          id="chart-bars"
        />
      </div>
    </>
  );
}

export default Bars;
