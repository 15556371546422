import React, { useState, useContext } from 'react';
//context
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import unsubscribeBenefit from 'services/unsubscribeBenefit';
// reactstrap components
import {
  Button,
  CardBody,
  Row,
  Col,
  CardHeader
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";

function UnsubscribeBenefit({ user, modalCallback, title, id }) {
  const { display_name, user_email, amount, hoursID } = user;
  const [pending, setPending] = useState(false);
  const { setErrorMsg } = useContext(ErrorMsgContext);

  function Unsubscribe() {
    setPending(true);
    unsubscribeBenefit(hoursID)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            setErrorMsg({ error: true, msg: data });
            setPending(false);
          }
          else {
            setPending(false);
            modalCallback(false);
          }
        })
      .catch((err) => {
        setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending text="" />}
      <CardHeader className="text-center ">
        <h1 className='text-achim-pink'>ביטול הרשמה</h1>
        <h3 className='text-achim-black'>משתמש: <span className='font-weight-300'>{display_name}</span></h3>
        <h3 className='text-achim-black'>אימייל: <span className='font-weight-300'>{user_email}</span></h3>
        <h3 className='text-achim-black'>ההטבה: <span className='font-weight-300'>{id} - {title}</span></h3>
      </CardHeader>
      <CardBody className="text-center">
        <Row className="p-0 m-0">
          <Col>
        <h3 className='text-achim-pink'>המשתמש יזוכה ב {amount} שעות</h3>
            <Button
              color="dark"
              className="bg-achim-orange achim-text-black border-0"
              size="md"
              onClick={() => {
                Unsubscribe();
              }}
            >
              ביטול הרשמה
            </Button>
          </Col>
        </Row>
      </CardBody>
    </>
  );
}

export default UnsubscribeBenefit;
