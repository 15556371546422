import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from "react-router-dom";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
//context
import BenefitsContext from 'context/BenefitsContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
import HoursContext from 'context/HoursContext';
import HoursDetailsContext from 'context/HoursDetailsContext';
import UsersContext from 'context/UsersContext';
import UserContext from 'context/UserContext';
//services
import getBenefits from 'services/getBenefits';
import getBenefitHours from 'services/getBenefitHours';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Container,
  Col,
  Modal,
} from "reactstrap";
import { DoorOpen, MusicNoteBeamed, Cup, CalendarEvent, Award, Clock, HourglassSplit, GeoAlt, ArrowRight, Pen, Person, PersonPlus, ClockFill } from 'react-bootstrap-icons';
// nodejs library that concatenates classes
import classnames from "classnames";
//my components
import Loader from "components/custom/Loader";
import UnsubscribeBenefit from '../forms/UnsubscribeBenefit';
import BenefitAdminHeader from "components/Headers/BenefitAdminHeader.js";
import ExpotCsv from "services/ExpotCsv.js";
import RegBenefit from "views/pages/forms/RegBenefit.js";


function BenefitAdminPage() {
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const ID = pathArray[3];
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const { benefits, setBenefits } = useContext(BenefitsContext);
  const benefit = benefits.filter(obj => {
    return obj.ID == ID;
  })
  const [usersReg, setUsersReg] = useState([]);
  const [pending, setPending] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [formModal, setformModal] = useState(false);
  const [formModal2, setformModal2] = useState(false);

  const { post_title, meta } = benefit[0];
  const { sessions, hours_price, time, location: door_location } = meta;
  const type = meta["benefits-type"][0];
  const start_date = meta["start-date"][0];
  const mentor = meta["instructor-name"][0];
  const active = meta["active-benefits"][0];
  const open_soon = meta["open-soon"][0];
  const week_day = meta["week-day"][0];


  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          הצג{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          רשומות.
        </label>
      </div>
    ),
  });

  const { SearchBar } = Search;

  function modalCallback(status) {
    setformModal(status)
  }
  function modalCallback2(status) {
    setformModal2(status)
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setCurrentUser(row)
      setformModal(true)
    }
  };

  function callGetHours() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      setPending(true);
      if (token) {
        getBenefitHours(ID)
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setUsersReg([]);
                setErrorMsg({ error: true, msg: 'נרשמים - error' });
                setPending(false);
              }
              else {
                setUsersReg(data);
                setPending(false);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setUsersReg([]);
            setErrorMsg({ error: true, msg: 'נרשמים - error c' });
            setPending(false);
          }
          );
      }
    }
  }

  useEffect(() => {
    callGetHours();
  }, [])

  return (
    <>
      <BenefitAdminHeader title={post_title} mentor={mentor + '. קוד קופון: ' + Math.floor(ID * (ID / 2) + (new Date().getMonth() * 3))}
        color="pink" id={ID} modalCallback={modalCallback2}
      />
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0">
            <UnsubscribeBenefit
              user={currentUser}
              title={post_title}
              id={ID}
              modalCallback={modalCallback}
            />
          </Card>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        backdrop={true}
        backdropClassName="bg-achim-black"
        size="md"
        isOpen={formModal2}
        toggle={() => setformModal2(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0">
            <h2 className='text-center p-6'>  הוספת נרשמ/ת להטבה
              <br />- בקרוב-</h2>
            {/* <RegBenefit
              benefit={benefit}
              ID={ID}
              modalCallback={modalCallback2}
            /> */}
          </Card>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <Row className="achim-shadow text-right m-0">
                <Col
                  className={classnames(
                    "px-4 py-2 text-center text-white",
                    type === 'door' && "bg-achim-green",
                    type === 'course' && "bg-achim-orange",
                    type === 'event' && "bg-achim-blue"
                  )}>
                  <Row className="text-right">
                    <div>
                      {type === 'door' && <DoorOpen size={39} className="m-1 ml-2 mr-3" />}
                      {type === 'course' && <Award size={39} className="m-1 ml-2 mr-3" />}
                      {type === 'event' && <MusicNoteBeamed size={39} className="m-1 ml-2 mr-3" />}
                    </div>
                    <div>
                      <div className="h3 p-0 m-0 text-achim-black">
                        {type === 'door' && 'דלת'}
                        {type === 'course' && 'קורס'}
                        {type === 'event' && 'אירוע'}
                      </div>
                      <div>
                        <span className="font-weight-500">
                          {active === 'true' ?
                            'הרשמה פתוחה'
                            : open_soon === 'true' ?
                              'הרשמה בקרוב'
                              : 'הרשמה סגורה'}
                        </span>
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col className="py-2 px-4">
                  <Row className="text-right">
                    <div>
                      <CalendarEvent size={39} className="m-1 ml-2 text-achim-blue" />
                    </div>
                    <div>
                      <div className="h3 p-0 m-0 text-achim-blue">{type !== 'door' ? 'תאריך התחלה:' : 'תאריך:'}</div>
                      <div className="lh-110">{start_date}</div>
                    </div>
                  </Row>
                </Col>
                <Col className="py-2 px-4">
                  <Row className="text-right">
                    <div>
                      <Cup size={39} className="m-1 ml-2 text-achim-blue" />
                    </div>
                    <div>
                      <div className="h3 p-0 m-0 text-achim-blue">{sessions} מפגשים</div>
                      <div className="lh-110">{week_day}</div>
                    </div>
                  </Row>
                </Col>
                <Col className="py-2 px-4">
                  <Row className="text-right">
                    <div>
                      <Clock size={39} className="m-1 ml-2 text-achim-blue" />
                    </div>
                    <div>
                      <div className="h3 p-0 m-0 text-achim-blue">שעת המפגש:</div>
                      <div className="lh-110">{time}</div>
                    </div>
                  </Row>
                </Col>
                <Col className="py-2 px-4">
                  <Row className="text-right">
                    <div>
                      <GeoAlt size={39} className="m-1 ml-2 text-achim-blue" />
                    </div>
                    <div>
                      <div className="h3 p-0 m-0 text-achim-blue">מיקום:</div>
                      <div className="lh-110">{door_location}</div>
                    </div>
                  </Row>
                </Col>
                <Col className="py-2 px-4">
                  <Row className="text-right">
                    <div>
                      <ClockFill size={39} className="m-1 ml-2 text-achim-blue" />
                    </div>
                    <div>
                      <div className="h3 p-0 m-0 text-achim-blue">עלות:</div>
                      <div className="lh-110">{hours_price} שעות לימוד</div>
                    </div>
                  </Row>
                </Col>
              </Row>
              <ToolkitProvider
                data={usersReg}
                keyField="ID"
                columns={[
                  {
                    dataField: "ID",
                    text: "ID",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "display_name",
                    text: "שם",
                    sort: true,
                  },
                  {
                    dataField: "user_email",
                    text: "אימייל",
                    sort: true,
                  },
                  {
                    dataField: "phone",
                    text: "פלאפון",
                    sort: true,
                  },
                  {
                    dataField: "guide",
                    text: "מנחה",
                    sort: true,
                  },
                  {
                    dataField: "contact",
                    text: "איש קשר",
                    sort: true,
                  },
                  {
                    dataField: "amount",
                    text: "ניצול שעות",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "passport",
                    text: "תעודת זהות",
                    sort: true,
                  },
                  {
                    dataField: "create_time",
                    text: "תאריך הרשמה",
                    sort: true,
                  },
                  {
                    dataField: "note",
                    text: "הערות",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row className="d-flex justify-content-between w-100 px-4 pb-1">
                      <div className="text-div">
                        הנרשמים:
                      </div>
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label className="ml-1">
                          חיפוש:
                        </label>
                        <SearchBar
                          className="form-control-sm"
                          placeholder="חיפוש וסינון"
                          {...props.searchProps}
                        />
                      </div>
                      <div className="text-div">
                        <ExpotCsv url={`/wp-json/achim/v1/benefit/${ID}/users/hours`} size='sm' color='info' text='ייצוא לאקסל' fileTitle={`נרשמים ל-${post_title}`} />
                      </div>
                    </Row>
                    {pending && <Loader text="" />}
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      rowEvents={rowEvents}
                      bordered={false}
                      className="text-right"
                      rowClasses="text-right hover-row-class"
                      id="react-bs-table"
                      defaultSorted={[{
                        dataField: 'create_time',
                        order: 'desc'
                      }]}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
export default BenefitAdminPage;
