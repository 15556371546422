import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from "react-router-dom";
//context
import UserContext from 'context/UserContext';
import BenefitsContext from 'context/BenefitsContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
import HoursContext from 'context/HoursContext';
import HoursDetailsContext from 'context/HoursDetailsContext';
//services
import getBenefits from 'services/getBenefits';
import getBenefitUser from 'services/getBenefitUser';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Container,
  Col,
  Modal,
} from "reactstrap";
import { DoorOpen, MusicNoteBeamed, Cup, CalendarEvent, Award, Clock, HourglassSplit, X, DoorClosedFill, Geo, GeoAlt, Plus, Back, ArrowLeft, ArrowRight, Pen, Gift } from 'react-bootstrap-icons';
// nodejs library that concatenates classes
import classnames from "classnames";
// core components
import RegBenefit from "views/pages/forms/RegBenefit.js";

function BenefitPage() {
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const ID = pathArray[3];
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const { benefits, setBenefits } = useContext(BenefitsContext);
  const { hoursDetails } = useContext(HoursDetailsContext);
  const { hours } = useContext(HoursContext);
  const { user } = useContext(UserContext);
  const [formModal, setformModal] = useState(false);
  const [isReg, setiIsReg] = useState(false);

  const [benefit, setBenefit] = useState([{
    meta: {
      ["benefits-type"]: [],
      ["benefits-type"]: [],
      ["start-date"]: [],
      ["sub-title"]: [],
      ["instructor-name"]: [],
      ["active-benefits"]: [],
      ["open-soon"]: [],
      ["door-content"]: [],
      ["about-instructor"]: [],
      ["more-details"]: [],
      ["week-day"]: []
    }
  }]);

  const { post_title, meta, benefit_image, mentor_image, } = benefit[0];
  const { sessions, hours_price, time, location: door_location, hours_cost, min_hours } = meta;
  const type = meta["benefits-type"][0];
  const start_date = meta["start-date"][0];
  const sub_title = meta["sub-title"][0];
  const mentor = meta["instructor-name"][0];
  const active = meta["active-benefits"][0];
  const open_soon = meta["open-soon"][0];
  const door_content = meta["door-content"][0];
  const about_mentor = meta["about-instructor"][0];
  const more_details = meta["more-details"][0];
  const week_day = meta["week-day"][0];

  function modalCallback(status) {
    setformModal(status)
  }

  function callGet() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getBenefits()
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setBenefits([]);
                setErrorMsg({ error: true, msg: 'הטבות - error' });
              }
              else {
                setBenefits(data);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setBenefits([]);
            setErrorMsg({ error: true, msg: 'הטבות - error c' });
          }
          );
      }
    }
  }

  function callGetUserSub() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getBenefitUser(ID, user.ID)
          .then(
            (response) => {
              const { success, data } = response;
              if (success) {
                setiIsReg(data[0].date);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setBenefits([]);
            setErrorMsg({ error: true, msg: 'הטבות - error c' });
          }
          );
      }
    }
  }

  function regThisBenefit() {
    const hourReg = hoursDetails.find(({ chevruta }) => chevruta == ID);
    if (hourReg) { setiIsReg(hourReg.date); }
  }

  function setThisBenefit() {
    console.log(benefits);
    const a = setBenefit(benefits.filter(obj => {
      return obj.ID == ID;
    }))
    console.log(a);
  }

  useEffect(() => {
    if (!benefits.length) {
      callGet();
    }

    if (hoursDetails.length) {
      regThisBenefit();
    } else {
      callGetUserSub();
    }
  }, [])

  useEffect(() => {
    if (benefits.length > 0) {
      setThisBenefit();
    }
  }, [benefits])

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        backdrop={true}
        backdropClassName="bg-achim-black"
        size="md"
        isOpen={formModal}

        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0">
            <RegBenefit
              benefit={benefit}
              ID={ID}
              modalCallback={modalCallback}
            />
          </Card>
        </div>
      </Modal>
      {type === 'gift' &&
        <div className="bg-white">
          <div className="d-lg-none bg-light p-2 text-right">
            <Link to={`/app/benefits`} className="text-achim-black">
              <ArrowRight size={19} className="my-2 mr-0 ml-1" /> לכל ההטבות
            </Link>
          </div>
          <div className="img-anima1 bg-achim-pink m-0"
            style={{
              backgroundImage: `url(${benefit_image})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              minHeight: '90vh',
              opacity: '1',
            }}>
            <div className="d-none d-lg-block text-right m-5">
              <Link to={`/app/benefits`} className="text-white">
                <ArrowRight size={19} className="my-2 mr-0 ml-1" /> לכל ההטבות
              </Link>
            </div>
            <Col className="d-lg-none p-0 m-0"
              style={{ height: "170px" }}>
              <div
                className="img-fluid img-anima p-2"
                style={{
                  backgroundImage: `url(${benefit_image})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  opacity: '0.8',
                }}
              >
              </div>
            </Col>
            <Col className="p-4 pb-5 p-lg-5 bg-white m-0 mr-lg-8 mt-lg-4 mb-lg-8 shadow-xl" lg={4}>
              <div className="text-center text-lg-right">
                <h1 className="display-1 lh-100 text-achim-blue">
                  {post_title}
                </h1>
                <div className="h1 pb-2 text-achim-black">
                  {sub_title}
                </div>
                <div className="h2 text-achim-black font-weight-400 lh-140"
                  dangerouslySetInnerHTML={{ __html: door_content }}>
                </div>
                {more_details && <div
                  className="text-right border-right border-info pr-2 mt-3 mb-3"
                  dangerouslySetInnerHTML={{ __html: more_details }}
                ></div>}
                <div>
                  <div className="w-100 h3 text-achim-blue ">
                    {isReg ?
                      <div className="w-100 h2 text-red">נרשמת למתנה זו!</div>
                      :
                      <Button
                        onClick={() => setformModal(true)}
                        color="black"
                        disabled={active === 'true' ? false : true}
                        size="md"
                        className={classnames(
                          active === 'true' ? "bg-achim-pink text-white" : "bg-light text-black",
                          "d-inline"
                        )}
                      >
                        {active === 'true' ?
                          type === 'door' ?
                            <DoorOpen size={19} className="my-2 mr-0 ml-1" /> :
                            <Pen size={19} className="my-2 mr-0 ml-1" />
                          : open_soon === 'true' ?
                            <HourglassSplit size={19} className="my-2 mr-0 ml-1" />
                            : <Pen size={19} className="my-2 mr-0 ml-1" />}
                        <span className="font-weight-500">
                          {active === 'true' ?
                            'הרשמה למתנה'
                            : open_soon === 'true' ?
                              'ההרשמה בקרוב'
                              : 'ההרשמה סגורה'}
                        </span>
                      </Button>
                    }
                  </div>
                  <span className='h3 text-achim-blue '>
                    {hours_cost == 'true' ?
                      `עלות: ${hours_price} שעות לימוד, יש לך ${hours.total ? hours.total : '0'} שעות למימוש`
                      :
                      `מינימום ${min_hours} שעות לימוד צבורות. יש לך ${hours.positive || '0'} שעות, (עלות: ${hours_price} שעות)`
                    }
                  </span>
                </div>
              </div>
            </Col>
          </div>
        </div>
      }
      {type === 'event' &&
        <div className="bg-white">
          <div className="d-lg-none bg-light p-2 text-right">
            <Link to={`/app/benefits`} className="text-achim-black">
              <ArrowRight size={19} className="my-2 mr-0 ml-1" /> לכל ההטבות
            </Link>
          </div>
          <div className="img-anima1 bg-achim-pink m-0"
            style={{
              backgroundImage: `url(${benefit_image})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              minHeight: '90vh',
              opacity: '1',
            }}>
            <div className="d-none d-lg-block text-right m-5">
              <Link to={`/app/benefits`} className="text-white">
                <ArrowRight size={19} className="my-2 mr-0 ml-1" /> לכל ההטבות
              </Link>
            </div>
            <Col className="d-lg-none p-0 m-0"
              style={{ height: "170px" }}>
              <div
                className="img-fluid img-anima p-2"
                style={{
                  backgroundImage: `url(${benefit_image})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  opacity: '0.8',
                }}
              >
              </div>
            </Col>
            <Col className="p-4 pb-5 p-lg-5 bg-white m-0 mr-lg-8 mt-lg-4 mb-lg-8 shadow-xl" lg={4}>
              <div className="text-center text-lg-right">
                <h1 className="display-1 lh-100 text-achim-blue">
                  {post_title}
                </h1>
                <div className="h1 pb-2 text-achim-black">
                  {sub_title}
                </div>
                <div className="h2 text-achim-black font-weight-400 lh-140"
                  dangerouslySetInnerHTML={{ __html: door_content }}>
                </div>
                {more_details && <div
                  className="text-right border-right border-info pr-2 mt-3 mb-3"
                  dangerouslySetInnerHTML={{ __html: more_details }}
                ></div>}
                <div>
                  <div className="w-100 h3 text-achim-blue ">
                    {isReg ?
                      <div className="w-100 h2 text-red">נרשמת להטבה זו!</div>
                      :
                      <Button
                        onClick={() => setformModal(true)}
                        color="black"
                        disabled={active === 'true' ? false : true}
                        size="md"
                        className={classnames(
                          active === 'true' ? "bg-achim-pink text-white" : "bg-light text-black",
                          "d-inline"
                        )}
                      >
                        {active === 'true' ?
                          type === 'door' ?
                            <DoorOpen size={19} className="my-2 mr-0 ml-1" /> :
                            <Pen size={19} className="my-2 mr-0 ml-1" />
                          : open_soon === 'true' ?
                            <HourglassSplit size={19} className="my-2 mr-0 ml-1" />
                            : <Pen size={19} className="my-2 mr-0 ml-1" />}
                        <span className="font-weight-500">
                          {active === 'true' ?
                            'הרשמה לאירוע'
                            : open_soon === 'true' ?
                              'ההרשמה בקרוב'
                              : 'ההרשמה סגורה'}
                        </span>
                      </Button>
                    }
                  </div>
                  <span className='h3 text-achim-blue '>
                    {hours_cost == 'true' ?
                      `עלות: ${hours_price} שעות לימוד, יש לך ${hours.total ? hours.total : '0'} שעות למימוש`
                      :
                      `מינימום ${min_hours} שעות לימוד צבורות. יש לך ${hours.positive || '0'} שעות, (עלות: ${hours_price} שעות)`
                    }
                  </span>
                </div>
              </div>
            </Col>
          </div>
          <Row className="achim-shadow text-right m-0 pb-3 pb-lg-0 fixed-bottom-lg bg-white">
            <Col lg={2}
              className={classnames(
                "py-2 pr-4 ml-lg-5 text-center text-white",
                type === 'door' && "bg-achim-green",
                type === 'course' && "bg-achim-orange",
                type === 'event' && "bg-achim-blue",
                type === 'gift' && "bg-achim-pink"
              )}
            >
              {type === 'door' && <DoorOpen size={30} className="my-2 mr-2 ml-1" />}
              {type === 'course' && <Award size={30} className="my-2 mr-2 ml-1" />}
              {type === 'event' && <MusicNoteBeamed size={30} className="my-2 mr-2 ml-1" />}
              {type === 'gift' && <Gift size={30} className="my-2 mr-2 ml-1" />}
              <span className="h1 text-white font-weight-500">
                {type === 'door' && 'דלת'}
                {type === 'course' && 'קורס'}
                {type === 'event' && 'אירוע'}
                {type === 'gift' && 'מתנה'}
              </span>
            </Col>
            <Col lg={2} className="py-2 px-4 col-6">
              <Row className="text-right">
                <div>
                  <CalendarEvent size={39} className="m-1 ml-2 text-achim-blue" />
                </div>
                <div>
                  <div className="h3 p-0 m-0 text-achim-blue">{type !== 'door' ? 'תאריך התחלה:' : 'תאריך:'}</div>
                  <div className="lh-110">{start_date}</div>
                </div>
              </Row>
            </Col>
            <Col lg={2} className="py-2 px-4 col-6">
              <Row className="text-right">
                <div>
                  <Cup size={39} className="m-1 ml-2 text-achim-blue" />
                </div>
                <div>
                  <div className="h3 p-0 m-0 text-achim-blue">{sessions} מפגשים</div>
                  <div className="lh-110">{week_day}</div>
                </div>
              </Row>
            </Col>
            <Col lg={2} className="py-2 px-4 col-6">
              <Row className="text-right">
                <div>
                  <Clock size={39} className="m-1 ml-2 text-achim-blue" />
                </div>
                <div>
                  <div className="h3 p-0 m-0 text-achim-blue">שעת המפגש:</div>
                  <div className="lh-110">{time}</div>
                </div>
              </Row>
            </Col>
            <Col lg={2} className="py-2 px-4 col-6">
              <Row className="text-right">
                <div>
                  <GeoAlt size={39} className="m-1 ml-2 text-achim-blue" />
                </div>
                <div>
                  <div className="h3 p-0 m-0 text-achim-blue">מיקום:</div>
                  <div className="lh-110">{door_location}</div>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      }
      {(type !== 'event' && type !== 'gift') &&
        <div className="bg-white">
          <div className="d-lg-none bg-light p-2 text-right">
            <Link to={`/app/benefits`} className="text-achim-black">
              <ArrowRight size={19} className="my-2 mr-0 ml-1" /> לכל ההטבות
            </Link>
          </div>
          <Row className="img-anima1 h-400 bg-achim-pink m-0">
            <Col className="d-lg-none p-0 m-0 mb-3"
              style={{ height: "140px" }}>
              <div
                className="img-fluid img-anima p-2"
                style={{
                  backgroundImage: `url(${benefit_image})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  opacity: '0.8',
                }}
              >
              </div>
            </Col>
            <Col className="p-4 pb-5 pb-lg-6 pt-lg-4 pr-lg-9 pl-lg-8" lg={6}>
              <div className="d-none d-lg-block text-right mb-5">
                <Link to={`/app/benefits`} className="text-white">
                  <ArrowRight size={19} className="my-2 mr-0 ml-1" /> לכל ההטבות
                </Link>
              </div>
              <div className="text-center text-lg-right">
                <h1 className="display-1 lh-100 text-achim-black">
                  {post_title}
                </h1>
                <div className="h1 pb-2 text-achim-black">
                  {sub_title}
                </div>
                <div className="h2 text-achim-black font-weight-400">
                  {door_content}
                </div>
                <div className="h3 pb-2 text-achim-black font-weight-500">
                  מרצה: {mentor}
                </div>
                {isReg ?
                  <div className="w-100 h2 text-white">נרשמת להטבה זו!</div>
                  :
                  <Button
                    onClick={() => setformModal(true)}
                    color="black"
                    disabled={active === 'true' ? false : true}
                    size="md"
                    className={classnames(
                      active === 'true' ? "bg-white text-achim-pink" : "bg-light text-black",
                      ""
                    )}
                  >
                    {active === 'true' ?
                      type === 'door' ?
                        <DoorOpen size={19} className="my-2 mr-0 ml-1" /> :
                        <Award size={19} className="my-2 mr-0 ml-1" />
                      : open_soon === 'true' ?
                        <HourglassSplit size={19} className="my-2 mr-0 ml-1" />
                        : <DoorClosedFill size={19} className="my-2 mr-0 ml-1" />}
                    <span className="font-weight-500">
                      {active === 'true' ?
                        type === 'door' ?
                          'הרשמה לדלת' :
                          'הרשמה לקורס'
                        : open_soon === 'true' ?
                          'ההרשמה בקרוב'
                          : 'ההרשמה סגורה'}
                    </span>
                  </Button>
                }
              </div>
            </Col>
            <Col
              className="p-0 bg-achim-pink image-col"
            >
              <div
                className="img-fluid img-anima"
                style={{
                  backgroundImage: `url(${benefit_image})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  opacity: '0.8',
                }}
              >
              </div>
            </Col>
          </Row>
          <Row className="achim-shadow text-right m-0">
            <Col lg={2}
              className={classnames(
                "py-2 pr-4 ml-lg-5 text-center text-white",
                type === 'door' && "bg-achim-green",
                type === 'course' && "bg-achim-orange",
                type === 'event' && "bg-achim-blue",
                type === 'gift' && "bg-achim-pink",
              )}
            >
              {type === 'door' && <DoorOpen size={30} className="my-2 mr-2 ml-1" />}
              {type === 'course' && <Award size={30} className="my-2 mr-2 ml-1" />}
              {type === 'event' && <MusicNoteBeamed size={30} className="my-2 mr-2 ml-1" />}
              {type === 'gift' && <Gift size={30} className="my-2 mr-2 ml-1" />}
              <span className="h1 text-white font-weight-500">
                {type === 'door' && 'דלת'}
                {type === 'course' && 'קורס'}
                {type === 'event' && 'אירוע'}
                {type === 'gift' && 'מתנה'}
              </span>
            </Col>
            <Col lg={2} className="py-2 px-4 col-6">
              <Row className="text-right">
                <div>
                  <CalendarEvent size={39} className="m-1 ml-2 text-achim-blue" />
                </div>
                <div>
                  <div className="h3 p-0 m-0 text-achim-blue">{type !== 'door' ? 'תאריך התחלה:' : 'תאריך:'}</div>
                  <div className="lh-110">{start_date}</div>
                </div>
              </Row>
            </Col>
            <Col lg={2} className="py-2 px-4 col-6">
              <Row className="text-right">
                <div>
                  <Cup size={39} className="m-1 ml-2 text-achim-blue" />
                </div>
                <div>
                  <div className="h3 p-0 m-0 text-achim-blue">{sessions} מפגשים</div>
                  <div className="lh-110">{week_day}</div>
                </div>
              </Row>
            </Col>
            <Col lg={2} className="py-2 px-4 col-6">
              <Row className="text-right">
                <div>
                  <Clock size={39} className="m-1 ml-2 text-achim-blue" />
                </div>
                <div>
                  <div className="h3 p-0 m-0 text-achim-blue">שעת המפגש:</div>
                  <div className="lh-110">{time}</div>
                </div>
              </Row>
            </Col>
            <Col lg={2} className="py-2 px-4 col-6">
              <Row className="text-right">
                <div>
                  <GeoAlt size={39} className="m-1 ml-2 text-achim-blue" />
                </div>
                <div>
                  <div className="h3 p-0 m-0 text-achim-blue">מיקום:</div>
                  <div className="lh-110">{door_location}</div>
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="w-100 bg-achim-blue text-center p-1 pt-2 m-0">
            {isReg ?
              <div className="w-100 h2 text-achim-black">נרשמת להטבה זו בתאריך: {isReg}</div>
              :
              <div className="w-100 h2 text-achim-black">עלות: {hours_price} שעות לימוד, יש לך {hours.total ? hours.total : '0'} שעות למימוש
                <Button
                  onClick={() => setformModal(true)}
                  color="black"
                  disabled={active === 'true' ? false : true}
                  size="sm"
                  className={classnames(
                    active === 'true' ? "bg-achim-pink text-white" : "bg-light text-black",
                    "d-inline mr-3"
                  )}
                >
                  {active === 'true' ?
                    type === 'door' ?
                      <DoorOpen size={15} className="m-0 ml-1" /> :
                      <Award size={15} className="m-0 ml-1" />
                    : open_soon === 'true' ?
                      <HourglassSplit size={15} className="m-0 ml-1" />
                      : <DoorClosedFill size={15} className="m-0 ml-1" />}
                  <span className="font-weight-500">
                    {active === 'true' ?
                      type === 'door' ?
                        'הרשמה לדלת' :
                        'הרשמה לקורס'
                      : open_soon === 'true' ?
                        'ההרשמה בקרוב'
                        : 'ההרשמה סגורה'}
                  </span>
                </Button>
                <Link to={`/app/add-hour`}
                  className="mr-3"
                >
                  <Button
                    color="info"
                    size="sm"
                    className="text-achim-black"
                  >
                    <Plus size={16} className="m-0 ml-1" />
                    דיווח שעת לימוד
                  </Button>
                </Link>
              </div>
            }
          </Row>
          <Container>
            <Row>
              {more_details && <Col lg={6} className="pt-5 pt-lg-6 mx-2 mb-3">
                <div
                  dangerouslySetInnerHTML={{ __html: more_details }}
                  className="text-right border-right border-info pr-4"
                ></div></Col>}
              <Col className="pt-7 px-4 pt-lg-8 mb-3">
                <Card className="achim-shadow w-100">
                  <div
                    className="bg-achim-pink"
                    style={{ height: "6px" }}
                  >
                  </div>
                  <Row className="justify-content-center mt--6 mb-0" style={{ zIndex: "500" }}>
                    <div
                      className="rounded-circle border border-white bg-dark"
                      style={{
                        backgroundImage: `url(${mentor_image})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        height: "150px",
                        width: "150px",
                      }}>
                    </div>
                  </Row>
                  <CardBody className="text-center px-4 px-lg-5">
                    <div className="text-center">
                      <h5 className="h1 lh-110">
                        {mentor}
                      </h5>
                      <div className="h3 font-weight-400 pb-2">
                        {about_mentor}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </>
  );
}

export default BenefitPage;
