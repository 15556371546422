export default function getUser() {
    let token = localStorage.getItem("token");

    const url = '/wp-json/jwt-auth/v1/token/validate';
    const url2 = '/wp-json/achim/v1/current-user';
    const validate_options = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    const options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    return fetch(url, validate_options)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.data.status === 200) {
                return fetch(url2, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        const { success, data: user } = data;
                        if (!success) {
                            return false;
                        } else {
                            const { ID } = user;
                            const url3 = '/wp-json/achim/v1/hours/' + ID + '/summary';
                            return fetch(url3, options)
                                .then((response) => {
                                    return response.json();
                                })
                                .then((data) => {
                                    const res = [user, data]
                                    return res;
                                })
                        }
                    })
            }
            else {
                return false;
            }
        })
}

