import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
} from "reactstrap";
import { DoorOpen, Person, Cup, CalendarEvent, Award, Clock, HourglassSplit, X, DoorClosedFill, MusicNoteBeamed, Gift, GeoAlt } from 'react-bootstrap-icons';
// nodejs library that concatenates classes
import classnames from "classnames";
//services

function Benefit(props) {
  const { ID, post_title, meta, benefit_image, mentor_image } = props.props;
  const { sessions, hours_price, hours_cost, min_hours, location: door_location } = meta;
  const type = meta["benefits-type"][0];
  const start_date = meta["start-date"][0];
  const sub_title = meta["sub-title"][0];
  const mentor = meta["instructor-name"][0];
  const active = meta["active-benefits"][0];
  const open_soon = meta["open-soon"][0];

  return (
    <Card className="achim-shadow w-100">
      <div
        style={{
          backgroundColor: '#000'
        }}
      >
        <div
          className="img-fluid"
          style={{
            backgroundImage: `url(${benefit_image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '120px',
            opacity: '0.7',
          }}
        >

        </div>
      </div>
      <div
        className={classnames(
          "text-right text-white",
          type === 'door' && "bg-achim-green",
          type === 'course' && "bg-achim-orange",
          type === 'event' && "bg-achim-blue",
          type === 'gift' && "bg-achim-pink"
        )}
      >
        {type === 'door' && <DoorOpen size={20} className="my-2 mr-2 ml-1" />}
        {type === 'course' && <Award size={20} className="my-2 mr-2 ml-1" />}
        {type === 'event' && <MusicNoteBeamed size={20} className="my-2 mr-2 ml-1" />}
        {type === 'gift' && <Gift size={20} className="my-2 mr-2 ml-1" />}
        <span className="h2 text-white font-weight-500">
          {type === 'door' && 'דלת'}
          {type === 'course' && 'קורס'}
          {type === 'event' && 'אירוע'}
          {type === 'gift' && 'מתנה'}
        </span>
      </div>
      <Row className="justify-content-center mt--5 mb-0" style={{ zIndex: "500" }}>
        <div
          className="avatar-xl rounded-circle border border-white bg-dark"
          style={{
            backgroundImage: `url(${mentor_image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}>
        </div>
      </Row>
      <CardHeader className="text-center border-0 pt-3 pt-md-3 pb-0 pb-md-0">
        <div className="text-center">
          <h5 className="h1 lh-110">
            {post_title}
          </h5>
          <div className="h3 font-weight-400 pb-2 lh-130">
            {sub_title}
          </div>
        </div>
      </CardHeader>
      <div className="mt-auto">
        <div className="py-2 px-4 bg-gradient-lighter border-light border-top border-bottom text-achim- font-weight-400 text-right">
          <Row className="mb-1">
            <div
              className={classnames("col pl-0",
                open_soon === 'true' ?
                  'text-red'
                  : '')}
            >
              {active === 'true' ?
                <DoorOpen size={19} className="m-1 ml-2" />
                : open_soon === 'true' ?
                  <HourglassSplit size={19} className="m-1 ml-2" />
                  : <DoorClosedFill size={19} className="m-1 ml-2" />}
              <span className=""></span>
              <span className="font-weight-500">
                {active === 'true' ?
                  'ההרשמה פתוחה'
                  : open_soon === 'true' ?
                    'ההרשמה בקרוב'
                    : 'ההרשמה סגורה'}</span>
            </div>
            <div className="col pr-0 text-left">
              <CalendarEvent size={19} className="m-1 ml-2" />
              <span className="">{start_date}</span>
            </div>
          </Row>
          <Row>
            <div className="col pl-0">
              <Person size={19} className="m-1 ml-2" />
              <span>{mentor}</span>
            </div>
            <div className="col pr-0 text-left">
              {type === 'gift' ?
                <>
                  <GeoAlt size={19} className="m-1 ml-2" />
                  <span className="">{door_location}</span>
                </>
                :
                <>
                  <Cup size={19} className="m-1 ml-2" />
                  <span className="">{sessions} מפגשים</span>
                </>
              }
            </div>
          </Row>
        </div>
        <Row className="mt-2">
          <div className="col text-center text-dark">
            <Clock size={15} className="m-1 ml-2" />
            {hours_cost == 'true' ?
              <span>עלות: {hours_price} שעות לימוד </span> :
              <span>מינימום {min_hours} שעות לימוד (עלות {hours_price} שעות) </span>
            }
          </div>
        </Row>
        <div className="pb-3 pt-2 text-center">
          <Link to={`/app/benefit/${ID}`}>
            <Button
              color="black"
              /* href={'https://achimlanefesh.org.il/?p=' + ID} */
              size="md"
              className={classnames(
                active === 'true' ? "bg-achim-pink text-white" : "bg-light text-black",
                ""
              )}
            >
              {active === 'false' ? 'פרטים' : 'פרטים והרשמה'}
            </Button>
          </Link>
        </div>
      </div>
    </Card>
  );
}

export default Benefit;
