import React, { useState, useContext } from 'react';
import { Redirect } from "react-router-dom";
//context
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import postHours from 'services/postHours';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import Iframe from 'react-iframe'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import ActionHeader from "components/Headers/ActionHeader.js";
import Pending from "components/custom/Pending";

function Payment() {
  const [formValues, setFormValues] = useState({
    amount: 1,
    type: 'לימוד בחברותא',
    day: 'היום',
    chevruta: '',
    study_topic: '',
    insight: '',
    note: '',
    date: dateToDate1(new Date()),
    time_to: timeToTime(new Date()),
    time_from: timeToTime(new Date(new Date().getTime() - (1 * 60 * 60 * 1000)))
  });
  const [pending, setPending] = useState(false);
  const [sent, setSent] = useState(false);
  const { user } = useContext(UserContext);
  const { hours, setHours } = useContext(HoursContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  function handleReactDatetimeChange(who, date) {
    let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    let year = date.getFullYear() > 9 ? date.getFullYear() : '0' + date.getFullYear();
    let month = date.getMonth() > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    date = `${day}/${month}/${year}`
    setFormValues({
      ...formValues,
      [who]: date
    });
  };

  function dateToDate1(date) {
    let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    let year = date.getFullYear() > 9 ? date.getFullYear() : '0' + date.getFullYear();
    let month = date.getMonth() > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    return `${day}/${month}/${year}`
  };

  const handleReacttimeChange = (who, date) => {
    setFormValues({
      ...formValues,
      [who]: (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' + (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())
    });
  };

  function timeToTime(date) {
    return (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' + (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes());
  };

  function dateToDate(date) {
    const array = date.split('/');
    return `${array[2]}-${array[1]}-${array[0]}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setHour();
  }

  function setHour() {
    setPending(true);
    const values = {
      ...formValues,
      ['date']: dateToDate(formValues.date)
    };
    postHours(user.ID, values)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            setErrorMsg({ error: true, msg: 'העדכון נכשל, נסה/י שוב' });
            setPending(false);
          }
          else {
            setHours({
              ...hours,
              ['positive']: hours.positive * 1 + 1,
              ['total']: hours.total * 1 + 1
            });
            setSent(true);
          }
        })
      .catch((err) => {
        setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {sent && <Redirect to="/app/home" push={true} />}
      <ActionHeader name="תשלום" parentName="פעולות" />
      <Container className="mt--7  pb-5">
            <Iframe url="https://secure.cardcom.solutions/e/xfAZ"
              width="100%"
              height="1000px"
              display="initial"
              position="relative" />
      </Container>
    </>
  );
}

export default Payment;
