import React from 'react';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  Row,
} from "reactstrap";
import { Book, Bookmark } from 'react-bootstrap-icons';
// nodejs library that concatenates classes
import classnames from "classnames";
import { Link } from 'react-router-dom';

function Study(props) {
  const { ID, post_title, terms } = props.props;

  return (
    <Card className="achim-shadow w-100">
      <CardImg
        alt="..."
        src={require(`assets/img/theme/studyset.jpg`).default}
        top
        style={{ height: "140px" }}
      />
      <CardHeader className="text-center border-0 pt-3 pt-md-3 pb-0 pb-md-0">
        <div className="text-center">
          <h5 className="h1 lh-110">
            {post_title}
          </h5>
        </div>
      </CardHeader>
      <CardBody className="py-2 px-3 bg-gradient--lighter border-bottom text-achim-blue font-weight-400">
        <Row className="mb-1">
          <div
            className={classnames(
              "col font-weight-bold",
              "text-achim-orange"
            )}
          >
            <Book size={19} className="m-0 ml-2" />
            <span className="">סדרה: {terms &&  terms[0]?.name}</span>
          </div>
        </Row>
        <Row className="mb-1">
          <div className="col">
            <Bookmark size={19} className="m-0 ml-2" />
            <span className="">מערך {props.num + 1}</span>
          </div>
        </Row>
      </CardBody>
      <div className="p-3 d-flex justify-content-center">
      <Link to={`/app/studies/${ID}`}>
            <Button
              color="black"
              /* href={'https://achimlanefesh.org.il/?p=' + ID} */
              size="md"
              className="bg-achim-orange text-white"
            >
              למערך
            </Button>
          </Link>
      </div>
    </Card>
  );
}

export default Study;
