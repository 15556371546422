import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Card,
  Container,
  Row,
} from "reactstrap";
// core components
import TablesHeader from "components/Headers/TablesHeader.js";
import Loader from "components/custom/Loader";
//context
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import getLotteries from 'services/getLotteries';
import { DoorOpen, Gift } from 'react-bootstrap-icons';


function LotteriesTable() {
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [pending, setPending] = useState(false);
  const [lotteries, setLotteries] = useState([]);


  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          הצג{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          רשומות
        </label>
      </div>
    ),
  });

  const { SearchBar } = Search;

  const history = useHistory();

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      let path = `lottery-admin/${row.ID}`;
      history.push(path);
    }
  };

  function callGet() {
    if (localStorage.getItem("token")) {
      setPending(true);
      let token = localStorage.getItem("token");
      if (token) {
        getLotteries()
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setLotteries([]);
                setErrorMsg({ error: true, msg: 'הגרלות - error' });
                setPending(false);
              }
              else {
                let reformattedArray = data.map(obj => {
                  obj.active = obj.active == '1' ? 'פעיל' : 'לא פעיל';
                  return obj
                })
                setLotteries(reformattedArray);
                setPending(false);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setLotteries([]);
            setErrorMsg({ error: true, msg: 'הגרלות - error c' });
            setPending(false);
          }
          );
      }
    }
  }

  useEffect(() => {
    callGet();
  }, [])

  return (
    <>
      <TablesHeader name="הגרלות" parentName="Tables" color="orange" add_text='הגרלה חדשה'
        add_link='new-lottery'
        add_icon={<Gift size={30} className="mb-2" />} link={true} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={lotteries}
                keyField="ID"
                columns={[
                  {
                    dataField: "ID",
                    text: "ID",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "title",
                    text: "הגרלה",
                    sort: true,
                  },
                  {
                    dataField: "sub_title",
                    text: "כותרת משנה",
                    sort: true,
                  },
                  {
                    dataField: "subs",
                    text: "נרשמו",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "active",
                    text: "סטטוס",
                    sort: true,
                  },
                  {
                    dataField: "create_time",
                    text: "תאריך",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },

                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row className="d-flex justify-content-between w-100 px-4 pb-1">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label className="ml-1">
                          חיפוש:
                        </label>
                        <SearchBar
                          className="form-control-sm"
                          placeholder="חיפוש וסינון"
                          {...props.searchProps}
                        />
                      </div>
                    </Row>
                    {pending && <Loader text="" />}
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      rowEvents={rowEvents}
                      pagination={pagination}
                      bordered={false}
                      className="text-right"
                      rowClasses="text-right hover-row-class"
                      id="react-bs-table"
                      defaultSorted={[{
                        dataField: 'ID',
                        order: 'asc'
                      }]}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default LotteriesTable;