import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

function AuthHeader({ title, lead }) {


  return (
    <>
      <div className="header bg-gradient-info pt-5 pb-7 py-lg-9">
        <Container>
          <div className="slide-top header-body text-center mb-7">
            <Row className="justify-content-center">
              <Col>
                {title ? <h1 className="display-1 text-white  opacity-6 p-0 m-0">{title}</h1> : null}
                {lead ? <p className="text-lead text-white p-0 m-0">{lead}</p> : null}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;
