import React, { useContext, useEffect, useState } from 'react';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Card,
  Input,
  Nav,
  Col,
  NavItem,
  NavLink,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
// core components
import TablesHeader from "components/Headers/TablesHeader.js";
import Loader from "components/custom/Loader";
//context
import HoursDetailsContext from 'context/HoursDetailsContext';
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import getQueriesHours from 'services/getQueriesHours';
import ExpotCsv from "services/ExpotCsv.js";
import { PlusCircle } from 'react-bootstrap-icons';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        הצג{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        רשומות.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function Tables() {
  const { hoursDetails, setHoursDetails } = useContext(HoursDetailsContext);
  const { hours, setHours } = useContext(HoursContext);
  const { user } = useContext(UserContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [pending, setPending] = useState(false);

  const [activeNav, setActiveNav] = useState(1);
  const [bonusNav, setBonusNav] = useState(1);
  const [fields_select, setFields_select] = useState('display_name');
  const [text, setText] = useState('');
  const [sumBy, setSumBy] = useState(1);
  const [dateFrom, setDateFrom] = useState(monthAgo());
  const [dateTo, setDateTo] = useState(new Date());

  function monthAgo() {
    let date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  }

  function resetfilters() {
    setActiveNav(1);
    setBonusNav(1);
    setFields_select('display_name');
    setText('');
    setSumBy(1);
    setDateFrom(monthAgo());
    setDateTo(new Date());
    callGetHours();
  }

  const handleChange_fields_select = (e) => {
    setFields_select(e.target.value)
    /* if (text) callGetHours(); */
  }

  function handleReactDatetimeChange(who, date) {
    who === 'from' ? setDateFrom(date) : setDateTo(date);
  };

  const handleChangeSumBy = (e) => {
    setSumBy(e.target.value)
  }

  const handleChangeText = (e) => {
    setText(e.target.value)
  }

  const toggleBonusNavs = (e, index) => {
    if (e) e.preventDefault();
    setBonusNav(index);
  };

  const toggleNavs = (e, index) => {
    if (e) e.preventDefault();
    setActiveNav(index);
  };

  useEffect(() => {
    callGetHours();
  }, [activeNav, bonusNav, sumBy, dateFrom, dateTo])

  function dateToSql(who, date) {
    date = new Date(date)
    let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    let year = date.getFullYear() > 9 ? date.getFullYear() : '0' + date.getFullYear();
    let month = date.getMonth() > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    return who === 'from' ? date = `${year}-${month}-${day} 00:00:00` : date = `${year}-${month}-${day} 23:59:59`;
  }

  function callGetHours() {
    if (localStorage.getItem("token")) {
      setPending(true);
      let token = localStorage.getItem("token");
      if (token) {


        const queries = {
          date_from: dateToSql('from', dateFrom),
          date_to: dateToSql('to', dateTo),
          all: activeNav,
          bonus: bonusNav,
          group_by: sumBy,
          filter_by: fields_select,
          filter_like: text,
        }

        getQueriesHours(queries)
          .then(
            (response) => {
              const { success, data } = response[1];
              if (!success) {
                setHours({ negative: 0, positive: 0, total: 0 });
                setHoursDetails([{ ID: 'אין רשומות תואמות' }]);
                setPending(false);
              }
              else {
                setHours(data[0]);
                setHoursDetails(response[0].data);
                setPending(false);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setHours({ negative: 0, positive: 0, total: 0 });
            setHoursDetails([]);
            setErrorMsg({ error: true, msg: 'שעות לימוד - error' });
            setPending(false);
          }
          );
      }
    }
  }

  useEffect(() => {
    callGetHours();
  }, [])

  return (
    <>
      <TablesHeader name="שעות לימוד" color="blue" add_text='דיווח שעת לימוד'
        add_link='add-hour'
        add_icon={<PlusCircle size={30} className="mb-2" />} link={true} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <Row className='p-0 m-0'>
                <Col>
                  <h3 className='text-center p-0 m-0 pt-3'>{hoursDetails.length ? hoursDetails.map(item => item.amount).reduce((prev, next) => (prev * 1) + (next * 1)) : 0} שעות ({hoursDetails.length} רשומות)</h3>
                </Col>
              </Row>
              <ToolkitProvider
                data={hoursDetails}
                keyField="ID"
                columns={[
                  {
                    dataField: "ID",
                    text: "ID",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "display_name",
                    text: "שם",
                    sort: true,
                  },
                  {
                    dataField: "type",
                    text: "סוג",
                    sort: true,
                  },
                  {
                    dataField: "amount",
                    text: "שעות",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "chevruta",
                    text: "חברותא",
                    sort: true,
                  },
                  {
                    dataField: "guide",
                    text: "מנחה",
                    sort: true,
                  },
                  {
                    dataField: "contact",
                    text: "איש קשר",
                    sort: true,
                  },
                  {
                    dataField: "date",
                    text: "תאריך",
                    sort: true,
                  },
                  {
                    dataField: "time_from",
                    text: "משעה",
                    sort: true,
                  },
                  {
                    dataField: "time_to",
                    text: "עד שעה",
                    sort: true,
                  },
                  {
                    dataField: "create_time",
                    text: "תאריך עדכון",
                    sort: true,
                  },
                  {
                    dataField: "olami",
                    text: "עולמי",
                    sort: true,
                  },
                  {
                    dataField: "study_topic",
                    text: 'נושא',
                    sort: true,
                  },
                  {
                    dataField: "insight",
                    text: "תובנה",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row className="d-flex justify-content-between w-100 px-5">
                      <Nav className="justify-content-center ml-2 mb-4" pills>
                        <NavItem className="m-0 px-1">
                          <NavLink
                            className={classnames("py-1 px-3 form-control-sm", {
                              active: activeNav === 1,
                            })}
                            href="#pablo"
                            onClick={(e) => toggleNavs(e, 1)}
                          >
                            <span>הכל</span>
                          </NavLink>
                        </NavItem>
                        <NavItem className="m-0 px-1">
                          <NavLink
                            className={classnames("py-1 px-3 form-control-sm", {
                              active: activeNav === 2,
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={(e) => toggleNavs(e, 2)}
                          >
                            <span>נצברו</span>
                          </NavLink>
                        </NavItem>
                        <NavItem className="m-0 px-1">
                          <NavLink
                            className={classnames("py-1 px-3 form-control-sm", {
                              active: activeNav === 3,
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={(e) => toggleNavs(e, 3)}
                          >
                            <span>נוצלו</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <Nav className="justify-content-center ml-2 mb-4" pills>
                        <NavItem className="m-0 px-1">
                          <NavLink
                            className={classnames("py-1 px-3 form-control-sm", {
                              active: bonusNav === 1
                            }, 'pink')}
                            color='info'
                            href="#pablo"
                            onClick={(e) => toggleBonusNavs(e, 1)}
                          >
                            <span>עם בונוס</span>
                          </NavLink>
                        </NavItem>
                        <NavItem className="m-0 px-1">
                          <NavLink
                            className={classnames("py-1 px-3 form-control-sm", {
                              active: bonusNav === 2,
                            }, 'pink')}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={(e) => toggleBonusNavs(e, 2)}
                          >
                            <span>ללא בונוס</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <div className="form-inline ml-2 mb-4">
                        <label className="ml-1">
                          שדה סינון:
                        </label>
                        <Input
                          id="fields_select"
                          type="select"
                          name="fields_select"
                          className="form-control-sm"
                          onChange={handleChange_fields_select}
                          onBlur={handleChange_fields_select}
                          value={fields_select}
                        >
                          <option value="display_name" key="1">שם</option>
                          <option value="type" key="2">סוג</option>
                          <option value="guide" key="3">מנחה</option>
                          <option value="contact" key="4">איש קשר</option>
                          <option value="olami" key="5">עולמי</option>
                        </Input>
                        <label className="mr-2 ml-1">
                          ערך:
                        </label>
                        <Input
                          id="name_searche"
                          type="text"
                          name="name_searche"
                          className="form-control-sm"
                          onChange={handleChangeText}
                          onBlur={handleChangeText}
                          value={text}
                          placeholder='ניתן להקליד חלק מהערך'
                        >
                        </Input>
                        <Button
                          className="form-control-sm"
                          style={{ width: '90px' }}
                          color="info"
                          size="sm"
                          onClick={() => callGetHours()}
                        >
                          סינון
                        </Button>
                      </div>
                      <div className="form-inline ml-2 mb-4">
                        <FormGroup className="">
                          <label
                            className="form-control-label ml-2"
                            htmlFor="date"
                          >
                            מתאריך
                          </label>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "תאריך",
                              id: "date",
                              name: "date",
                              className: 'form-control-sm border-0 bg-lighter'
                            }}
                            value={dateFrom}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            onChange={(e) =>
                              handleReactDatetimeChange("from", e._d)
                            }
                            required
                            closeOnSelect={true}
                          />
                        </FormGroup>
                        <FormGroup className="">
                          <label
                            className="form-control-label mr-2 ml-2"
                            htmlFor="date"
                          >
                            עד תאריך
                          </label>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "תאריך",
                              id: "date",
                              name: "date",
                              className: 'form-control-sm border-0 bg-lighter'
                            }}
                            value={dateTo}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            onChange={(e) =>
                              handleReactDatetimeChange("to", e._d)
                            }
                            required
                            closeOnSelect={true}
                          />
                        </FormGroup>
                      </div>
                      <div className="form-inline ml-2 mb-4">
                        <FormGroup className="">
                          <label
                            className="form-control-label ml-1"
                            htmlFor="note"
                          >
                            סיכום לפי
                          </label>
                          <Input
                            id="fields_select"
                            type="select"
                            name="fields_select"
                            onChange={handleChangeSumBy}
                            onBlur={handleChangeSumBy}
                            value={sumBy}
                            className='form-control-sm'
                          >
                            <option value={1} key="1">פירוט ללא סיכום</option>
                            <option value="user_id" key="2">סטודנט</option>
                            <option value="guide" key="3">מנחה</option>
                            <option value="contact" key="4">איש קשר</option>
                          </Input>
                        </FormGroup>
                      </div>
                      <div className="form-inline ml-2 mb-4">
                        <FormGroup className="">
                          <label
                            className="form-control-label ml-1"
                            htmlFor="note"
                          >
                            חיפוש:
                          </label>
                          <SearchBar
                            placeholder="חיפוש בכל השדות"
                            {...props.searchProps}
                            className='form-control-sm'

                          />
                        </FormGroup>
                      </div>
                      <div className="form-inline ml-2 mb-4">
                        <Button
                          className="form-control-sm bg-achim-orange m-0"
                          style={{ width: '90px' }}
                          color="warning"
                          size="sm"
                          onClick={() => resetfilters()}
                        >
                          איפוס מסננים
                        </Button>
                      </div>

                      <div className="d-flex align-items-center mb-4">
                        <ExpotCsv url={`/wp-json/achim/v1/hours/queries?limit=15&offset=0&date_from=${dateToSql('from', dateFrom)}&date_to=${dateToSql('to', dateTo)}&
                            all=${activeNav}&bonus=${bonusNav}&group_by=${sumBy}&filter_by=${fields_select}&filter_like=${text}&summary=0`}
                          size='sm' color='primary' text='ייצוא לאקסל' fileTitle='שעות לימוד' />
                      </div>
                    </Row>
                    {pending && <Loader text="" />}
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      className="text-right"
                      rowClasses="text-right hover-row-class"
                      id="react-bs-table"
                      defaultSorted={[{
                        dataField: 'ID',
                        order: 'asc'
                      }]}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Tables;
