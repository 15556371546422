import React from 'react';
import { Link } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  PlusCircle
} from 'react-bootstrap-icons';
//
import Loader from "components/custom/Loader2";

function CardsHeader({ name, hours, weekSummary, pending, hoursWithoutBonus, usersSummary, monthSummary }) {
  const { total, positive, negative, count_positive, count_negative } = hours;
  function math(num) {
    return (Math.round(num * 100) / 100).toFixed(0);
  }

  return (
    <>
      <div className="header bg-achim-blue pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4 text-center justify-content-end">
              <Col>
                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{" "}
              </Col>
              <div className="position-absolute text-white pt-3 p-md-4 slide-down">
                <Link to="/app/add-hour">
                  <div className="text-center text-white"> <PlusCircle size={30} className="m-2" /></div>
                  <h2 className="h5 text-center text-white lh-100 font-weight-300">דיווח שעת לימוד</h2>
                </Link>
              </div>
            </Row>
            <Row>
              <Col md="6" xl="3">
                {pending && <Loader />}
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h4"
                          className="font-weight-500 text-achim-blue mb-0"
                        >
                          שעות לימוד
                        </CardTitle>
                        <h2 className="h2 font-weight-bold mb-0">
                          {hoursWithoutBonus.positive} שעות
                        </h2>
                        <h3 className="h3 font-weight-300 mb-0">
                          {hoursWithoutBonus.count_positive} סטודנטים
                        </h3>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-achim-blue text-white rounded-circle shadow">
                          <i className="ni ni-bold-up" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">ממוצע: {math(hoursWithoutBonus.positive / hoursWithoutBonus.count_positive)} שעות</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                {pending && <Loader />}
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h4"
                          className="font-weight-500 text-achim-blue mb-0"
                        >
                          שעות לימוד - כולל בונוס
                        </CardTitle>
                        <h2 className="h2 font-weight-bold mb-0">
                          {positive} שעות
                        </h2>
                        <h3 className="h3 font-weight-300 mb-0">
                          {count_positive} סטודנטים
                        </h3>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-achim-blue text-white rounded-circle shadow">
                          <i className="ni ni-bold-up" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">ממוצע: {math(positive / count_positive)} שעות לימוד</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                {pending && <Loader />}
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h4"
                          className="font-weight-500 text-achim-blue mb-0"
                        >
                          שעות לימוד החודש:
                        </CardTitle>
                        <h2 className="h2 font-weight-bold mb-0">
                          {monthSummary.positive} שעות
                        </h2>
                        <h3 className="h3 font-weight-300 mb-0">
                          {monthSummary.count_positive} סטודנטים
                        </h3>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-achim-blue text-white rounded-circle shadow">
                          <i className="ni ni-calendar-grid-58" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">ממוצע: {math(monthSummary.positive / monthSummary.count_positive)} שעות לימוד החודש</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                {pending && <Loader />}
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h4"
                          className="font-weight-500 text-achim-blue mb-0"
                        >
                          שעות לימוד השבוע:
                        </CardTitle>
                        <h2 className="h2 font-weight-bold mb-0">
                          {weekSummary.positive} שעות
                        </h2>
                        <h3 className="h3 font-weight-300 mb-0">
                          {weekSummary.count_positive} סטודנטים
                        </h3>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-achim-blue text-white rounded-circle shadow">
                          <i className="ni ni-calendar-grid-58" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">ממוצע: {math(weekSummary.positive / weekSummary.count_positive)} שעות לימוד השבוע</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                {pending && <Loader />}
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h4"
                          className="font-weight-500 text-achim-pink mb-0"
                        >
                          שעות נוצלו להטבות
                        </CardTitle>
                        <h2 className="h2 font-weight-bold mb-0">
                          {negative} שעות
                        </h2>
                        <h3 className="h3 font-weight-300 mb-0">
                          {count_negative} סטודנטים
                        </h3>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-achim-pink text-white rounded-circle shadow">
                          <i className="ni ni-bold-down" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">ממוצע: {math(negative / count_negative)} שעות נוצלו</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                {pending && <Loader />}
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h4"
                          className="font-weight-500 text-achim-pink mb-0"
                        >
                          שעות נוצלו השבוע
                        </CardTitle>
                        <h2 className="h2 font-weight-bold mb-0">
                          {weekSummary.negative} שעות
                        </h2>
                        <h3 className="h3 font-weight-300 mb-0">
                          {weekSummary.count_negative} סטודנטים
                        </h3>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-achim-pink text-white rounded-circle shadow">
                          <i className="ni ni-bold-down" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">ממוצע: {math(weekSummary.negative / weekSummary.count_negative)} שעות נוצלו</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                {pending && <Loader />}
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h4"
                          className="font-weight-500 text-achim-orange mb-0"
                        >
                          יתרת שעות לניצול
                        </CardTitle>
                        <h2 className="h2 font-weight-bold mb-0">
                          {total} שעות
                        </h2>
                        <h3 className="h3 font-weight-300 mb-0">
                          {count_positive} סטודנטים
                        </h3>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-achim-orange text-white rounded-circle shadow">
                          <i className="ni ni-bold-left" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">ממוצע {math(total / count_positive)}  שעות לניצול</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                {pending && <Loader />}
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h4"
                          className="font-weight-500 text-achim-green mb-0"
                        >
                          סטודנטים
                        </CardTitle>
                        <h2 className="h2 font-weight-bold mb-0">
                          {usersSummary.count} במערכת
                        </h2>
                        <h3 className="h3 font-weight-300 mb-0">
                          {hoursWithoutBonus.count_positive} עדכנו שעות
                        </h3>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-achim-green text-white rounded-circle shadow">
                          <i className="ni ni-single-02" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-nowrap">{usersSummary.passport} נכנסו לאיזור האישי</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
