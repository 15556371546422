import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from "react-router-dom";
//context
import UserContext from 'context/UserContext';
import StudyesContext from 'context/StudyesContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import getStudyComment from 'services/getStudyComment';
import getStudyes from 'services/getStudyes';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { ArrowRight } from 'react-bootstrap-icons';
// nodejs library that concatenates classes
// core components
import RegBenefit from "views/pages/forms/RegBenefit.js";
import StudyComment from '../forms/StudyComment';

function StudyPage() {
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const ID = pathArray[3];
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const { studyes, setStudyes } = useContext(StudyesContext);
  const { user } = useContext(UserContext);

  const [study, setStudy] = useState(null);
  const [answer, setAnswer] = useState('');
  const [answered, setAnswered] = useState(false);
  const [loadAnswer, setLoadAnswer] = useState(false);

  function callGet() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getStudyes()
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setStudyes([]);
                setErrorMsg({ error: true, msg: 'הטבות - error' });
              }
              else {
                callGetStudyComment();
                setStudyes(data);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setStudyes([]);
            setErrorMsg({ error: true, msg: 'הטבות - error c' });
          }
          );
      }
    }
  }

  function callGetStudyComment() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getStudyComment(user.ID, ID)
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setAnswer('');
                setAnswered(false);
                setLoadAnswer(true);
              }
              else {
                setAnswer(data[0].answer);
                setAnswered(true);
                setLoadAnswer(true);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setLoadAnswer(true);
            setAnswer('');
            setAnswered(false);
            setErrorMsg({ error: true, msg: 'תשובות - error c' });
          }
          );
      }
    }
  }

  function setThisStudy() {
    setStudy(studyes.filter(obj => {
      return obj.ID == ID;
    })[0])
  }

  useEffect(() => {
    callGet();
  }, [])

  useEffect(() => {
    if (studyes.length > 0) {
      setThisStudy();
    }
  }, [studyes])

  return (
    <>
      <div className="bg-white">
        <div className="d-lg-none bg-light p-2 text-right">
          <Link to={`/app/study`} className="text-achim-black">
            <ArrowRight size={19} className="my-2 mr-0 ml-1" /> לכל התכנים
          </Link>
        </div>
        {(!study || !loadAnswer) ?
          <Row className="p-8 bg-achim-orange m-0">
            <h1 className="display-4 lh-100 text-achim-black">
              רק רגע, המערך בדרך...
            </h1>
          </Row>
          :
          <Row className="img-anima1 h-400 bg-achim-orange m-0">
            <Col className="d-lg-none p-0 m-0 mb-3"
              style={{ height: "140px" }}>
              <div
                className="img-fluid p-2"
                style={{
                  backgroundImage: `url(${require(`assets/img/theme/studyset.jpg`).default})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  opacity: '0.8',
                }}
              >
              </div>
            </Col>
            <Col className="p-4 pb-5 pb-lg-6 pt-lg-4 pr-lg-9 pl-lg-8" lg={6}>
              <div className="d-none d-lg-block text-right mb-5">
                <Link to={`/app/study`} className="text-white">
                  <ArrowRight size={19} className="my-2 mr-0 ml-1" /> לכל התכנים
                </Link>
              </div>
              <div className="text-center text-lg-right">
                <h1 className="display-1 lh-100 text-achim-black">
                  מערך: {study.post_title}
                </h1>
                <div className="h2 pb-2 text-achim-black font-weight-500">
                  סדרה: {study.terms[0]?.name}
                </div>
                <Card className="achim-shadow w-100">
                  <CardBody className="text-center">
                    <StudyComment
                      question={study.meta.question[0]}
                      answer={answer}
                      answered={answered}
                      setAnswered={setAnswered}
                      setAnswer={setAnswer}
                      study={study}
                      id={ID}
                    />
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col
              className="p-0 bg-achim-orange image-col"
            >
              <div
                className="img-fluid"
                style={{
                  backgroundImage: `url(${require(`assets/img/theme/studyset.jpg`).default})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '100%',
                  opacity: '0.9',
                }}
              >
              </div>
            </Col>
          </Row>
        }
      </div>
    </>
  );
}

export default StudyPage;
