import React, { useState, useContext } from 'react';
//context
import UserContext from 'context/UserContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
import HoursContext from 'context/HoursContext';

//services
import getUser from 'services/getUser';
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import Pending from "components/custom/PendingLoad";

function Login() {
  /* const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false); */
  const { setUser } = useContext(UserContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pending, setPending] = useState(false);
  const { setHours } = useContext(HoursContext);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (name === "email") return setEmail(value);
    if (name === "password") return setPassword(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPending(true);
    setEmail(e.target.email.value);
    setPassword(e.target.password.value);

    const newUser = {
      username: e.target.email.value,
      password: e.target.password.value,
    }

    try {
      let url = '/wp-json/jwt-auth/v1/token';
      let options = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newUser)
      };

      const res = await fetch(url, options);
      const { token } = await res.json();
      if (token) {
        localStorage.setItem("token", token);
        getUserData(token)
      }
      else {
        setUser('');
        setErrorMsg({ error: true, msg: '3משתמש לא מחובר' });
        setPending(false);
      }
    }
    catch (err) {
      console.log(err);
      setUser('');
      setErrorMsg({ error: true, msg: '2משתמש לא מחובר' });
      setPending(false);
    }

  }

  function getUserData(token) {
    getUser()
      .then(
        (response) => {
          const user = response[0];
          setUser(user);
          const { data: hoursJson } = response[1];
          setHours(hoursJson[0]);
          localStorage.setItem("token", token);
          setPending(false);
        })
      .catch((err) => {
        console.log('Error! c', err);
        setUser('');
        setErrorMsg({ error: true, msg: 'הלכנו לאיבוד, תיכף נשוב' });
        setPending(false);
      }
      );
  }

  const imgStyle = {
    maxHeight: 128,
    maxWidth: 160
  }


  return (
    <>
      <AuthHeader
        title="יחד מחברים עולמות"
      />
      <Container className="mt--9 pb-5 ">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              {pending && <Pending title="יחד מחברים עולמות" />}
              <CardBody className="px-lg-5 py-lg-5">
                <Row className="pb-2 m-0 w-100 justify-content-center">
                  <img
                    alt="..."
                    className=""
                    style={imgStyle}
                    src={require("assets/img/brand/blue.png").default}
                  />
                </Row>
                <div className="text-center text-muted mb-4">
                  <h1>ברוכים הבאים</h1>
                </div>
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: email,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="אימייל"
                        type="email"
                        name="email"
                        onFocus={handleChange}
                        onBlur={handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: password,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="סיסמה"
                        type="password"
                        name="password"
                        onFocus={handleChange}
                        onBlur={handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4" color="info" type="submit">
                      כניסה
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6" className="text-right">
                <a
                  className="text-light"
                  href="https://app.achimlanefesh.org.il/wp-login.php?action=lostpassword"
                >
                  <small>שכחת את הסיסמה?</small>
                </a>
              </Col>
              <Col className="text-left" xs="6">
                <a
                  className="text-light"
                  href="https://achimlanefesh.org.il/reg-achim/"
                >
                  <small>הרשמה לתוכנית אחים לנפש</small>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
