import React, { useState, useEffect } from 'react';
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
//context
import SidenavOpenContext from 'context/SidenavOpenContext';
import SettingsContext from 'context/SettingsContext';
import UserContext from 'context/UserContext';
import UsersContext from 'context/UsersContext';
import HoursContext from 'context/HoursContext';
import HoursDetailsContext from 'context/HoursDetailsContext';
import BenefitsContext from 'context/BenefitsContext';
import StudyesContext from 'context/StudyesContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import getUser from 'services/getUser';
import getHours from 'services/getHours';
import getPowerUser from 'services/getPowerUser';
// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/icomoon/style.css";
import "assets/css/custom/custom.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import AppLayout from "./layouts/AppLayout.js";
import AactionLayout from "./layouts/AactionLayout.js";
import AuthLayout from "layouts/Auth.js";
import IndexView from "views/Index.js";
import Load from "views/pages/examples/Load";
import PassportModal from "components/custom/PassportModal";
import AlertError from "components/custom/AlertError";

const App = () => {
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [settings, setSettings] = useState('');
  const [user, setUser] = useState('');
  const [users, setUsers] = useState([]);
  const [hours, setHours] = useState({ negative: 0, positive: 0, total: 0 });
  const [hoursDetails, setHoursDetails] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [studyes, setStudyes] = useState([
    {
      title: 'תכני הלימוד מתעדכנים באתר',
      type: 'מצגת',
      subject: 'ערכת לימוד',
      image: 'studyset.jpg'
    },
  ]);

  const [errorMsg, setErrorMsg] = useState('');
  const [checkAuth, setCheckAuth] = useState(false);

  function isAuth() {
    setUser('');
    setCheckAuth(false);
    if (!localStorage.getItem("token")) {
      setUser('');
      setCheckAuth(true);
    } else {
      let token = localStorage.getItem("token");
      if (!token) {
        localStorage.removeItem("token");
        setUser('');
        setCheckAuth(true);
      } else {
        getUser()
          .then(
            (response) => {
              const user = response[0];
              setUser(user);
              const { data: hoursJson } = response[1];
              setHours(hoursJson[0]);
              localStorage.setItem("token", token);
              /* getPowerUser1(user.ID, user.data.user_login); */
              /* callGetHours(user, token); */
              setCheckAuth(true);
            })
          .catch((err) => {
            console.log('Error! c', err);
            setUser('');
            setErrorMsg({ error: true, msg: 'משתמש לא מחובר' });
            setCheckAuth(true);
          }
          );
      }
    }
  }

  function callGetHours(user, token) {
    if (token) {
      getHours(user.ID)
        .then(
          (response) => {
            const { success, data } = response[1];
            if (!success) {
              setHours({ negative: 0, positive: 0, total: 0 });
              setHoursDetails([]);
              setErrorMsg({ error: true, msg: '1שעות לימוד - error' });
            }
            else {
              setHours(data[0]);
              setHoursDetails(response[0].data);
            }
          })
        .catch((err) => {
          console.log('Error! c', err);
          setHours({ negative: 0, positive: 0, total: 0 });
          setHoursDetails([]);
          setErrorMsg({ error: true, msg: '1שעות לימוד - error' });
        }
        );
    }
  }

  useEffect(() => {
    isAuth();
  }, [])

  return (
    <BrowserRouter>
      {!checkAuth ? <Load /> :
        <SidenavOpenContext.Provider value={{ sidenavOpen, setSidenavOpen }}>
          <SettingsContext.Provider value={{ settings, setSettings }}>
            <UserContext.Provider value={{ user, setUser }}>
              <UsersContext.Provider value={{ users, setUsers }}>
                <HoursContext.Provider value={{ hours, setHours }}>
                  <HoursDetailsContext.Provider value={{ hoursDetails, setHoursDetails }}>
                    <BenefitsContext.Provider value={{ benefits, setBenefits }}>
                      <StudyesContext.Provider value={{ studyes, setStudyes }}>
                        <ErrorMsgContext.Provider value={{ errorMsg, setErrorMsg }}>
                          {errorMsg && <AlertError />}
                          {user && <PassportModal />}
                          <Switch>
                            <Route path="/app" render={(props) => <AppLayout {...props}/>}>
                              {!user && <Redirect to="/auth/login" push={true} />}
                            </Route>
                            <Route path="/action" render={(props) => <AactionLayout {...props} />}>
                              {!user && <Redirect to="/auth/login" push={true} />}
                            </Route>
                            <Route path="/auth" render={(props) => <AuthLayout {...props}/>}>
                              {user && <Redirect to="/app/home" push={true} />}
                            </Route>
                            {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
                            {/* <Route path="/index" render={(props) => <IndexView {...props} />} /> */}
                            {/* <Route path="/rtl" render={(props) => <RTLLayout {...props} />} /> */}
                            <Redirect from="/app" to="/app/home" />
                            <Redirect from="*" to="/app/home" />
                            {/* <Redirect from="*" to="/" /> */}
                          </Switch>
                        </ErrorMsgContext.Provider>
                      </StudyesContext.Provider>
                    </BenefitsContext.Provider>
                  </HoursDetailsContext.Provider>
                </HoursContext.Provider>
              </UsersContext.Provider>
            </UserContext.Provider>
          </SettingsContext.Provider>
        </SidenavOpenContext.Provider>
      }
    </BrowserRouter>
  );
}
export default App;