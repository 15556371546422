import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from "react-router-dom";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
//context
import ErrorMsgContext from 'context/ErrorMsgContext';
import StudyesContext from 'context/StudyesContext';
import UsersContext from 'context/UsersContext';
import UserContext from 'context/UserContext';
//services
import getStudyes from 'services/getStudyes';
import getStudyComments from 'services/getStudyComments';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Container,
  Col,
  Modal,
} from "reactstrap";
import { DoorOpen, MusicNoteBeamed, Cup, CalendarEvent, Award, Clock, HourglassSplit, GeoAlt, ArrowRight, Pen, Person, PersonPlus, ClockFill, Book } from 'react-bootstrap-icons';
// nodejs library that concatenates classes
import classnames from "classnames";
//my components
import Loader from "components/custom/Loader";
import UnsubscribeBenefit from '../forms/UnsubscribeBenefit';
import BenefitAdminHeader from "components/Headers/BenefitAdminHeader.js";
import ExpotCsv from "services/ExpotCsv.js";
import RegBenefit from "views/pages/forms/RegBenefit.js";
import TablesHeader from "components/Headers/TablesHeader.js";



function StudyAdninPage() {
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const ID = pathArray[3];
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const { studyes, setStudyes } = useContext(StudyesContext);
  const { user } = useContext(UserContext);

  const [study, setStudy] = useState(null);
  const [answer, setAnswer] = useState([]);
  const [loadAnswer, setLoadAnswer] = useState(false);

  function callGet() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getStudyes()
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setStudyes([]);
                setErrorMsg({ error: true, msg: 'הטבות - error' });
              }
              else {
                callgetStudyComments();
                setStudyes(data);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setStudyes([]);
            setErrorMsg({ error: true, msg: 'הטבות - error c' });
          }
          );
      }
    }
  }

  function callgetStudyComments() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getStudyComments(user.ID, ID)
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setAnswer([]);
                setLoadAnswer(true);
              }
              else {
                setAnswer(data);
                setLoadAnswer(true);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setLoadAnswer(true);
            setAnswer([]);
            setErrorMsg({ error: true, msg: 'תשובות - error c' });
          }
          );
      }
    }
  }

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          הצג{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          רשומות.
        </label>
      </div>
    ),
  });

  const { SearchBar } = Search;

  function setThisStudy() {
    setStudy(studyes.filter(obj => {
      return obj.ID == ID;
    })[0])
  }

  useEffect(() => {
    callGet();
  }, [])

  useEffect(() => {
    if (studyes.length > 0) {
      setThisStudy();
    }
  }, [studyes])

  return (
    <>
      {(!study || !loadAnswer) ?
        <Row className="p-8 bg-achim-orange m-0">
          <h1 className="display-4 lh-100 text-achim-black">
            רק רגע, הפרטים בדרך...
          </h1>
        </Row>
        :
        <>
          <TablesHeader name={study.post_title} parentName="Tables" color="orange" add_text='לכל התכנים'
            add_link='/app/studyes-admin'
            add_icon={<Book size={30} className="mb-2" />} link={true} />
          <Container className="mt--6" fluid>
            <Row>
              <div className="col">
                <Card>
                  <ToolkitProvider
                    data={answer}
                    keyField="ID"
                    columns={[
                      {
                        dataField: "ID",
                        text: "ID",
                        sort: true,
                        sortFunc: (a, b, order, dataField, rowA, rowB) => {
                          if (order === 'asc') {
                            return b - a;
                          }
                          return a - b; // desc
                        }
                      },
                      {
                        dataField: "display_name",
                        text: "שם",
                        sort: true,
                      },
                      {
                        dataField: "user_email",
                        text: "אימייל",
                        sort: true,
                      },
                      {
                        dataField: "answer",
                        text: "תשובה",
                        sort: true,
                      },
                      {
                        dataField: "guide",
                        text: "מנחה",
                        sort: true,
                      },
                      {
                        dataField: "contact",
                        text: "איש קשר",
                        sort: true,
                      }
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <Row className="d-flex justify-content-between w-100 px-4 pb-1">
                          <div className="text-div">
                            ענו:
                          </div>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1"
                          >
                            <label className="ml-1">
                              חיפוש:
                            </label>
                            <SearchBar
                              className="form-control-sm"
                              placeholder="חיפוש וסינון"
                              {...props.searchProps}
                            />
                          </div>
                          <div className="text-div">
                            <ExpotCsv url={`/wp-json/achim/v1/benefit/${ID}/users/hours`} size='sm' color='info' text='ייצוא לאקסל' fileTitle={`תשובות למערך-${study.post_title}`} />
                          </div>
                        </Row>
                        {!loadAnswer && <Loader text="" />}
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                          className="text-right"
                          rowClasses="text-right hover-row-class"
                          id="react-bs-table"
                          defaultSorted={[{
                            dataField: 'create_time',
                            order: 'desc'
                          }]}
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Card>
              </div>
            </Row>
          </Container>
        </>}
    </>
  );
}
export default StudyAdninPage;
