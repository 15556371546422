import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  ButtonGroup,
  Button,
} from "reactstrap";

function BenefitsHeader({ name, typeView, setTypeView, activeView, setActiveView }) {

  function typeView1(type) {
    setTypeView(type)
  }
  
  function activeView1(status) {
    setActiveView(status)
  }

  return (
    <>
      <div className="header header-dark bg-achim-pink pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="slide-top header-body">
            <Row md="12" xl="12" className="mt-0 mt-sm-0 pt-0 pt-sm-4 mb--3 justify-content-center">
              <img
                alt="Image placeholder"
                src={require("assets/img/theme/door_gifts.svg").default}
                width="250"
              />
            </Row>
            <h2 className="display-2 text-center text-white font-weight-400">{name}</h2>
            <Row className="align-items-center justify-content-center pb-5">
              <ButtonGroup className="bg-white p-2 rounded-pill mb-3 mb-sm-0 mx-4">
                <Button
                  className={classnames(
                    "rounded-pill ml-1 button-group-1",
                    typeView === 0 ? "button-group-1-active" : ""
                  )}
                  color="light"
                  checked
                  onClick={() => typeView1(0)}
                >
                  הכל
                </Button>
                <Button
                  className={classnames(
                    "rounded-pill ml-1 button-group-1",
                    typeView === 1 ? "button-group-1-active" : ""
                  )} color="light"
                  onClick={() => typeView1(1)}
                >
                  דלתות
                </Button>
                <Button
                  className={classnames(
                    "rounded-pill ml-1 button-group-1",
                    typeView === 2 ? "button-group-1-active" : ""
                  )} color="light"
                  onClick={() => typeView1(2)}
                >
                  קורסים
                </Button>
                <Button
                  className={classnames(
                    "rounded-pill ml-1 button-group-1",
                    typeView === 3 ? "button-group-1-active" : ""
                  )} color="light"
                  checked
                  onClick={() => typeView1(3)}
                >
                  אירועים
                </Button>
                <Button
                  className={classnames(
                    "rounded-pill button-group-1",
                    typeView === 4 ? "button-group-1-active" : ""
                  )} color="light"
                  checked
                  onClick={() => typeView1(4)}
                >
                  מתנות
                </Button>
              </ButtonGroup>
              <ButtonGroup className="bg-white p-2 rounded-pill mx-4">
                <Button
                  className={classnames(
                    "rounded-pill ml-1 button-group-1 button-group-2",
                    activeView === 0 ? "button-group-2-active" : ""
                  )} color="light"
                  checked
                  onClick={() => activeView1(0)}
                >
                  הכל
                </Button>
                <Button
                  className={classnames(
                    "rounded-pill ml-1 button-group-1 button-group-2 bg-achim-orange",
                    activeView === 1 ? "button-group-2-active" : ""
                  )} color="light"
                  onClick={() => activeView1(1)}
                >
                  פעילים
                </Button>
                <Button
                  className={classnames(
                    "rounded-pill button-group-1 button-group-2",
                    activeView === 2 ? "button-group-2-active" : ""
                  )} color="light"
                  checked
                  onClick={() => activeView1(2)}
                >
                  ישנים
                </Button>
              </ButtonGroup>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

BenefitsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default BenefitsHeader;
