import React, { useState, useContext } from 'react';
import { Redirect } from "react-router-dom";
//context
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import postLottery from 'services/postLottery';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import ActionHeader from "components/Headers/ActionHeader.js";
import Pending from "components/custom/Pending";

function NewLottery() {
  const [pending, setPending] = useState(false);
  const [sent, setSent] = useState(false);
  const { user } = useContext(UserContext);
  const { hours, setHours } = useContext(HoursContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [formValues, setFormValues] = useState({
    title: '',
    sub_title: '',
    content: '',
    insight: '',
    image: '',
    active: 1,
  });
  const [conditions, setConditions] = useState([{
    more_than: 0,
    less_than: 6,
    tickets: 1
  }]);
  const [gifts, setGifts] = useState([{
    name: '',
    winner: '',
    winnerId: ''
  }]);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  const handleChangeConditions = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const n = target.id;

    const arr = [...conditions];
    arr[n] = {
      ...arr[n],
      [name]: value
    };
    setConditions(arr);
  }

  const handleChangeGifts = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const n = target.id;

    const arr = [...gifts];
    arr[n] = {
      ...arr[n],
      [name]: value
    };
    setGifts(arr);
  }

  function handleConditions(e) {
    e.preventDefault();
    const newElement = {
      more_than: 0,
      less_than: 0,
      tickets: 0
    }

    setConditions(oldArray => [...oldArray, newElement]);
  }

  function handleRemoveConditions(e) {
    const target = e.target;
    const n = target.id;

    const arr = [...conditions];
    arr.splice(n, 1);
    setConditions(arr);
  }


  function handleGifts(e) {
    e.preventDefault();
    const newElement = {
      name: '',
      winner: '',
      winnerId: ''
    }

    setGifts(oldArray => [...oldArray, newElement]);
  }

  function handleRemoveGifts(e) {
    const target = e.target;
    const n = target.id;

    const arr = [...gifts];
    arr.splice(n, 1);
    setGifts(arr);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setHour();
  }

  function setHour() {
    setPending(true);

    //const jsonConditions = conditions.map( c => JSON.stringify(c) );
    const jsonConditions = JSON.stringify(conditions);

    const values = {
      ...formValues,
      ['conditions']: jsonConditions
    };
    postLottery(values)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            setErrorMsg({ error: true, msg: 'העדכון נכשל, נסה/י שוב' });
            setPending(false);
          }
          else {
            setSent(true);
          }
        })
      .catch((err) => {
        setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  function conditionsForm(num) {
    return (
      <>
        <Row>
          <Col md="3">
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="more_than"
              >
                יותר מ-
              </label>
              <Input
                id={num}
                type="number"
                name="more_than"
                placeholder="יותר מ-"
                onChange={handleChangeConditions}
                onBlur={handleChangeConditions}
                value={conditions[num].more_than}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="less_than"
              >
                פחות מ-
              </label>
              <Input
                id={num}
                type="number"
                name="less_than"
                placeholder="פחות מ-"
                onChange={handleChangeConditions}
                onBlur={handleChangeConditions}
                value={conditions[num].less_than}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="tickets"
              >
                כרטיסים
              </label>
              <Input
                id={num}
                type="number"
                name="tickets"
                placeholder="כרטיסי הגרלה"
                onChange={handleChangeConditions}
                onBlur={handleChangeConditions}
                value={conditions[num].tickets}
              />
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="tickets"
              >
                הוסף
              </label>
              <Button
                block
                color="green"
                className="bg-achim-green text-white"
                onClick={handleConditions}
              >
                <i className="text-white fas fa-plus fa-xs"></i>
              </Button>
            </FormGroup>
          </Col>
          <Col md="1" className='mr-3'>
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="tickets"
              >
                הסר
              </label>
              <Button
                id={num}
                disabled={num === 0}
                block
                color="green"
                className="bg-achim-blue text-white"
                onClick={handleRemoveConditions}
              >
                <i className="text-white fas fa-minus fa-xs"></i>
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </>
    )
  }

  function giftsForm(num) {
    return (
      <>
        <Row>
          <Col md="3">
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="name"
              >
                הפרס
              </label>
              <Input
                id={num}
                type="text"
                name="name"
                placeholder="הפרס"
                onChange={handleChangeGifts}
                onBlur={handleChangeGifts}
                value={gifts[num].name}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="winner"
              >
                הזוכה
              </label>
              <Input
                id={num}
                type="text"
                name="winner"
                placeholder="שם הזוכה"
                onChange={handleChangeGifts}
                onBlur={handleChangeGifts}
                value={gifts[num].winner}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="winnerId"
              >
                מס' זוכה
              </label>
              <Input
                id={num}
                type="text"
                name="winnerId"
                placeholder="ID"
                onChange={handleChangeGifts}
                onBlur={handleChangeGifts}
                value={gifts[num].winnerId}
              />
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="tickets"
              >
                הוסף
              </label>
              <Button
                block
                color="green"
                className="bg-achim-green text-white"
                onClick={handleGifts}
              >
                <i className="text-white fas fa-plus fa-xs"></i>
              </Button>
            </FormGroup>
          </Col>
          <Col md="1" className='mr-3'>
            <FormGroup className="mb-3">
              <label
                className="form-control-label"
                htmlFor="tickets"
              >
                הסר
              </label>
              <Button
                id={num}
                disabled={num === 0}
                block
                color="green"
                className="bg-achim-blue text-white"
                onClick={handleRemoveGifts}
              >
                <i className="text-white fas fa-minus fa-xs"></i>
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      {sent && <Redirect to="/app/lotteries" push={true} />}
      <ActionHeader name="הגרלה חדשה" parentName="פעולות" color="orange" />
      <Container className="mt--7  pb-5">
        <Row className="justify-content-center" >
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                {pending && <Pending />}
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit}>
                    <Row>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="title"
                          >
                            שם ההגרלה
                          </label>
                          <Input
                            id="title"
                            type="text"
                            name="title"
                            placeholder="כותרת"
                            onChange={handleChange}
                            onBlur={handleChange}
                            required
                            value={formValues.title}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="sub_title"
                          >
                            כותרת משנה
                          </label>
                          <Input
                            id="sub_title"
                            type="text"
                            name="sub_title"
                            placeholder="כותרת משנה"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={formValues.sub_title}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="image"
                          >
                            תמונה
                          </label>
                          <Input
                            id="image"
                            type="text"
                            name="image"
                            placeholder="קישור לתמונה"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={formValues.image}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="active"
                          >
                            סטטוס הגרלה:
                          </label>
                          <Input
                            id="active"
                            type="select"
                            name="active"
                            onChange={handleChange}
                            onBlur={handleChange}
                            required
                            value={formValues.active}
                          >
                            <option ket={1} value={1}>פעיל</option>
                            <option ket={0} value={0}>לא פעיל</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="content"
                          >
                            פרטים נוספים
                          </label>
                          <Input
                            id="content"
                            rows="2"
                            type="textarea"
                            name="content"
                            placeholder="פירוט על ההגרלה"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={formValues.content}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className='mb-3'>
                      <Col md="6" className='border-dark border-left'>
                        <h3>כללים:</h3>
                        {conditions.map((condition, key) => (
                          <>
                            {conditionsForm(key)}
                          </>
                        ))}
                      </Col>
                      <Col md="6">
                        <h3>פרסים:</h3>
                        {gifts.map((condition, key) => (
                          <>
                            {giftsForm(key)}
                          </>
                        ))}
                      </Col>
                    </Row>
                    <Button
                      block
                      color="green"
                      className="bg-achim-orange text-white"
                      type="submit"
                    >
                      הוסף הגרלה  <i className="text-white fas fa-chevron-left fa-xs"></i>
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NewLottery;
