export default  function getStudyes() {

    let url = `https://achimlanefesh.org.il/wp-json/achim/v1/studysets?per=15&paged=1`;
    let options = {
        method: 'GET',
        credentials: 'include',
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}

