import React, { useState, useContext } from 'react';
//context
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import createTikcets from 'services/createTikcets';
import resetWinners from 'services/resetWinners';
// reactstrap components
import {
  Button,
  CardBody,
  Row,
  Col,
  CardHeader,
  FormGroup,
  Input
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";

function CreateTickets({ lottery, setgifts }) {
  const { title, ID } = lottery;
  const [pending, setPending] = useState(false);
  const [create, setCreate] = useState(false);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [formValues, setFormValues] = useState({
    method: 'time',
    time: '10',
    doublewin: false
  });

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  function postCreate() {
    setPending(true);
    createTikcets(ID)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            setErrorMsg({ error: true, msg: data });
            setPending(false);
          }
          else {
            setPending(false);
            setCreate(data[0].lottery);
            /* modalCallback(false); */
          }
        })
      .catch((err) => {
        setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  function postResetWinners() {
    setPending(true);
    resetWinners(ID)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            setErrorMsg({ error: true, msg: data });
            setPending(false);
          }
          else {
            setgifts(data);
            setPending(false);
          }
        })
      .catch((err) => {
        setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {pending && <Pending text="" />}
      <CardHeader className="text-center ">
        <h1 className='text-achim-black'>הגרלה: {title}</h1>
        <h3 className='text-achim-black'>ID: {ID}</h3>
      </CardHeader>
      <CardBody className="text-center">
        {create && <h1 className='text-achim-black'>{create} כרטיסים</h1>}
        <Row className="p-0 m-0">
          <Col>
            {!create ?
              <>
                <Button
                  color="dark"
                  className="bg-achim-pink achim-text-black border-0 mb-3"
                  size="md"
                  block
                  onClick={() => {
                    postResetWinners();
                  }}
                >
                  איפוס זוכים
                </Button>
                <Button
                  color="dark"
                  block
                  className="bg-achim-orange achim-text-black border-0"
                  size="md"
                  onClick={() => {
                    postCreate();
                  }}
                >
                  הכנת כרטיסים (שכפול)
                </Button>
              </>
              :
              <>
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="method"
                  >
                    צורת ההגרלה
                  </label>
                  <Input
                    id="method"
                    type="select"
                    name="method"
                    onChange={handleChange}
                    onBlur={handleChange}
                    required
                    value={formValues.method}
                  >
                    <option key={0} value={'time'}>טיימר</option>
                    <option key={1} value={'click'}>לחיצה</option>
                  </Input>
                </FormGroup>
                {formValues.method === 'time' &&
                  <FormGroup className="mb-3">
                    <label
                      className="form-control-label"
                      htmlFor="time"
                    >
                      זמן טיימר (בשניות)
                    </label>
                    <Input
                      id="time"
                      type="number"
                      name="time"
                      onChange={handleChange}
                      onBlur={handleChange}
                      required
                      value={formValues.time}
                    >
                    </Input>
                  </FormGroup>
                }
                <FormGroup className="mb-3">
                  <label
                    className="form-control-label"
                    htmlFor="doublewin"
                  >
                    לאפשר זכיה כפולה
                  </label>
                  <Input
                    id="doublewin"
                    type="select"
                    name="doublewin"
                    onChange={handleChange}
                    onBlur={handleChange}
                    required
                    value={formValues.doublewin}
                  >
                    <option key={0} value={false}>לא</option>
                    <option key={1} value={true}>כן</option>
                  </Input>
                </FormGroup>
                <a
                  href={`https://www.sh-pro.co.il/achim-lottery/index.html?id=${ID}&method=${formValues.method}&time=${formValues.time}&doublewin=${formValues.doublewin}`}
                  target={"_blank"}>
                  <Button
                    color="dark"
                    block
                    className="bg-achim-green achim-text-black border-0"
                    size="md"
                    onClick={() => {
                      postCreate();
                    }}
                  >
                    ביצוע ההגרלה
                  </Button>
                </a>
              </>
            }
          </Col>
        </Row>
      </CardBody>
    </>
  );
}

export default CreateTickets;