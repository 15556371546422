import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Card,
  Container,
  Row,
} from "reactstrap";
// core components
import TablesHeader from "components/Headers/TablesHeader.js";
import Loader from "components/custom/Loader";
//context
import StudyesContext from 'context/StudyesContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import getBenefitsAdmin from 'services/getBenefitsAdmin';
import { DoorOpen } from 'react-bootstrap-icons';
import getStudyes from 'services/getStudyes';


function StudyesTable() {
  const { studyes, setStudyes } = useContext(StudyesContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [pending, setPending] = useState(false);


  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          הצג{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          רשומות.
        </label>
      </div>
    ),
  });

  const { SearchBar } = Search;

  const history = useHistory();

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      let path = `study-admin/${row.ID}`;
      history.push(path);
    }
  };

  function callGet() {
    setPending(true);
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getStudyes()
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setStudyes([]);
                setPending(false);
                setErrorMsg({ error: true, msg: 'תכנים - error' });
              }
              else {
                setStudyes(data);
                setPending(false);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setStudyes([]);
            setErrorMsg({ error: true, msg: 'תכנם - error c' });
            setPending(false);
          }
          );
      }
    }
  }

  useEffect(() => {
    callGet();
  }, [])

  return (
    <>
      <TablesHeader name="תכני לימוד" parentName="Tables" color="orange" add_text='מערך חדש'
        add_link='https://achimlanefesh.org.il/wp-admin/post-new.php?post_type=studyset'
        add_icon={<DoorOpen size={30} className="mb-2" />} link={false} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={studyes}
                keyField="ID"
                columns={[
                  {
                    dataField: "ID",
                    text: "ID",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "post_title",
                    text: "שם",
                    sort: true,
                  }
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row className="d-flex justify-content-between w-100 px-4 pb-1">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label className="ml-1">
                          חיפוש:
                        </label>
                        <SearchBar
                          className="form-control-sm"
                          placeholder="חיפוש וסינון"
                          {...props.searchProps}
                        />
                      </div>
                    </Row>
                    {pending && <Loader text="" />}
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      rowEvents={rowEvents}
                      pagination={pagination}
                      bordered={false}
                      className="text-right"
                      rowClasses="text-right hover-row-class"
                      id="react-bs-table"
                      defaultSorted={[{
                        dataField: 'ID',
                        order: 'asc'
                      }]}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default StudyesTable;
