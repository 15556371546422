import React from 'react';
// reactstrap components
import {
  Card,
  Row,
  CardImgOverlay,
  CardBody,
  Col,
} from "reactstrap";
import { Book, Bookmark } from 'react-bootstrap-icons';
// nodejs library that concatenates classes
import classnames from "classnames";

function Study(props) {
  const { title, subject, type, image } = props.props;

  return (
    <div className="study-slider">
      <Card className="bg-darker rounded-0 text-white achim-shadow mb-0">
        <div
        >
          <div
            className="card-bg-image opacity-4"
            style={{
              backgroundImage: `url(${require(`assets/img/theme/studyset.jpg`).default})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '150px',
            }}
          />
        </div>
        <CardImgOverlay className="p-4 text-center">
          <div className="w-100 h-100 d-flex align-items-center text-center">
            <h5 className="w-100 h1 lh-110 text-white mb-2">
              {title}
            </h5>
          </div>
        </CardImgOverlay>
      </Card>
      <Card className="bg-lighter rounded-0 text-white achim-shadow">
        <CardBody className="py-2 px-4 bg-gradient--lighter border-top border-bottom text-achim-blue font-weight-400">
          <Row className="mb-1">
          <Col className="my-1 p-0">
            <div
              className={classnames(
                "col",
                type === 'door' ? "text-achim-green" : "text-achim-orange"
              )}
            >
              <Book size={15} className="m-0 ml-2" />
              <span
                className={classnames(
                  "h4 lh-100 font-weight-400",
                  type === 'door' ? "text-achim-green" : "text-achim-orange"
                )}
                >{subject}</span>
            </div>
            </Col>
            <Col className="my-1 p-0">
              <Bookmark size={15} className="m-0 ml-2" />
              <span className="">{type}</span>
              </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default Study;
