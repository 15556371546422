  import React, { useContext } from 'react';
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
//context
import UserContext from 'context/UserContext';
import SidenavOpenContext from 'context/SidenavOpenContext';
// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import BottomNavbar from "components/BottomNavbar/HomeBottomNavbar.js";

import routes from "routes.js";
import routesBottomNavbar from "routesBottomNavbar.js";

function AppLayout() {
  const { user } = useContext(UserContext);
  const role = user.roles[0];
  const { sidenavOpen, setSidenavOpen } = useContext(SidenavOpenContext);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.body.classList.add("rtl");
    document.documentElement.classList.add("rtl");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("rtl");
      document.documentElement.classList.remove("rtl");
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/app") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
      {role === "administrator" && <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        rtlActive
      />}
      <div className="main-content" ref={mainContentRef}>
      <AppNavbar />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/app/home" />
        </Switch>
        <BottomNavbar
          routes={routesBottomNavbar}
        />
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

export default AppLayout;
