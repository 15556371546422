import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect } from "react-router-dom";
//context
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
import HoursDetailsContext from 'context/HoursDetailsContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import postLotterySub from 'services/postLotterySub';
import getLotterySub from 'services/getLotterySub';
// reactstrap components
import {
  Card,
  CardImgOverlay,
  CardBody,
  Button
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";

function Lottery() {
  const { user } = useContext(UserContext);
  const { hours, setHours } = useContext(HoursContext);
  const { hoursDetails, setHoursDetails } = useContext(HoursDetailsContext);
  const { positive } = hours;
  const [reg, setReg] = useState(false);
  const [pending, setPending] = useState(false);
  const [sent, setSent] = useState(false);
  const { setErrorMsg } = useContext(ErrorMsgContext);

  function callGetLotterySub() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getLotterySub(12, user.ID)
          .then(
            (response) => {
              const { success, data } = response[0];
              if (!success) {
                setHours({ negative: 0, positive: 0, total: 0 });
                setErrorMsg({ error: true, msg: 'הגרלה - error' });
                setReg(false);
              }
              else {
                if (data === true) {
                  setHours(response[1].data[0]);
                  setReg(true);
                }
                else {
                  setHours(response[1].data[0]);
                  setReg(false);
                }
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setHours({ negative: 0, positive: 0, total: 0 });
            setErrorMsg({ error: true, msg: 'הגרלה - error' });
            setReg(false);
          }
          );
      }
    }
  }

  useEffect(() => {
   // callGetLotterySub();
  }, [])

  function tickets(amount) {
    if (amount < 5) { return 0; }
    else if (amount < 10) { return 1; }
    else if (amount < 20) { return 2; }
    else if (amount < 30) { return 3; }
    else if (amount < 50) { return 4; }
    else if (amount > 49) { return 5; }
    return 0;
  }

  function setHour() {
    setPending(true);
    postLotterySub(12, user.ID)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            setErrorMsg({ error: true, msg: 'העדכון נכשל, נסה/י שוב' });
            setPending(false);
          }
          else {
            setPending(false);
            setReg(true);
            setSent(true);
            setErrorMsg({ error: true, msg: 'נרשמת להגרלה!', color: 'success' });
          }
        })
      .catch((err) => {
        setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {sent && <Redirect to="/app/home" push={true} />}
      <div>
        {pending && <Pending />}
        <Card className="bg-darker rounded-0 text-white achim-shadow mb-0">
          <div
          >
            <div
              className="card-bg-image opacity-4"
              style={{
                backgroundImage: `url(${require(`assets/img/theme/lot.jpg`).default})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '150px',
              }}
            />
          </div>
          <CardImgOverlay className="p-4 text-center">
            <div className="w-100 h-100 d-flex align-items-center text-center">
              <h5 className="w-100 h1 lh-110 text-white mb-2">
                הגרלת אחים לנפש - קיץ 2023
              </h5>
            </div>
          </CardImgOverlay>
        </Card>
        <Card className="rounded-0 text-white achim-shadow text-center">
          <CardBody className="py-3 px-4 bg-gradient--lighter border-top border-bottom text-center">
            <div className="d-flex justify-content-center">
            <Link to={`/app/lottery/15`}>
                <Button size='sm' className="mt-1 bg-achim-pink text-white" color="pink"
                >
                  לדף ההגרלה
                </Button>
                </Link>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default Lottery;
