import React, { useContext, useState } from 'react';
//context
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
import NotificationsContext from 'context/NotificationsContext';
// nodejs library that concatenates classes
import classnames from "classnames";
// react library for routing
import { Link, NavLink as NavLinkRRD } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavbarBrand,
  NavItem,
  Nav,
  NavLink,
  Container,
  Row,
} from "reactstrap";
import { BoxArrowRight, Gear, QuestionOctagon, PersonCircle } from 'react-bootstrap-icons';

function AdminNavbar({ logo, theme, sidenavOpen, toggleSidenav }) {
  const { user, setUser } = useContext(UserContext);
  const { setHours } = useContext(HoursContext);
  const userFullname = `${user.data.meta.first_name[0]} ${user.data.meta.last_name[0]}`
  const { notifications } = useContext(NotificationsContext);
  const [isOpen, setIsOpen] = useState(false);
  const [profileIsOpen, setProfileIsOpen] = useState(false);

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  function loguot() {
    /* e.preventDefault() */
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
    setUser('');
    setHours('');

  }

  const createNotification = () => {
    return notifications.map((prop, key) => {
      return (
        <ListGroupItem
          key={key}
          className="list-group-item-action"
          href="#pablo"
          onClick={(e) => e.preventDefault()}
          tag="a"
        >
          <Row className="align-items-center">
            <div className="col ml--2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="mb-0 text-sm">{prop.subject}</h4>
                </div>
                <div className="text-right text-muted">
                  <small>{prop.create_time}</small>
                </div>
              </div>
              <p className="text-sm mb-0">
                {prop.notification}
              </p>
            </div>
          </Row>
        </ListGroupItem>
      )
    })
  }

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-expand " + theme
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto pr-0" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-0 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
{/*               <UncontrolledDropdown
                nav
                isOpen={isOpen}
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                onClick={() => setIsOpen(true)}
                toggle={() => setIsOpen(!isOpen)}
              >
                <DropdownToggle className="nav-link" color="" tag="a">
                  <span>
                    <i
                      className={classnames(
                        "ni ni-bell-55",
                        notifications && "bell-icon",
                      )}
                      data-bell-counter={notifications.length}
                    /></span>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-lg py-0 overflow-hidden"
                  right
                >
                  <div className="px-3 py-3">
                    {notifications ?
                      <h6 className="text-sm text-muted m-0">
                        יש לך <strong className="text-info">{notifications.length}</strong>{" "}
                        הודעות.
                      </h6>
                      : <h6 className="text-sm text-muted m-0">קראת את כל ההודעות
                      </h6>}
                  </div>
                  <ListGroup flush>
                    {createNotification()}
                  </ListGroup>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </Nav>
            <NavbarBrand {...navbarBrandProps}
              className="nav-logo"
            >
              <img
                alt={logo.imgAlt}
                src={logo.imgSrc}
              />
            </NavbarBrand>
            <Nav className="align-items-center mr-auto mr-md-0" navbar>
              <UncontrolledDropdown
                nav
                isOpen={profileIsOpen}
                onMouseOver={() => setProfileIsOpen(true)}
                onClick={() => setProfileIsOpen(true)}
                onMouseLeave={() => setProfileIsOpen(false)}
                toggle={() => setProfileIsOpen(!profileIsOpen)}
              >
                <DropdownToggle
                  className="nav-link pr-0" color="" tag="a"
                >
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/theme/user-01.jpg").default}
                      />
                    </span>
                    <Media className="mr-2 d-none d-lg-block">
                      {user && <span className="mb-0 text-sm font-weight-bold">
                        {userFullname}
                      </span>}
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h5 className="text-overflow m-0">שלום {userFullname}</h5>
                  </DropdownItem>
{/*                   <DropdownItem>
                    <NavLink
                      to={'/app/profile'}
                      tag={NavLinkRRD}
                    >
                      <i className="text-achim-black"><PersonCircle size={22} className="mb-1" /></i>
                      <span className="text-achim-black mr-2">הפרופיל שלי</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <NavLink
                      to={'/app/settings'}
                      tag={NavLinkRRD}
                    >
                      <i className="text-achim-black"><Gear size={22} className="mb-1" /></i>
                      <span className="text-achim-black mr-2">הגדרות</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <NavLink
                      to={'/action/tickets'}
                      tag={NavLinkRRD}
                    >
                      <i className="text-achim-black"><QuestionOctagon size={22} className="mb-1" /></i>
                      <span className="text-achim-black mr-2">תמיכה</span>
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem divider /> */}
                  <DropdownItem
                    href="#"
                    onClick={loguot}
                  >
                    <i><BoxArrowRight size={26} className="mb-1" /></i>
                    <span className="nav-link-text mr-2">יציאה</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};

export default AdminNavbar;
