import React, { useState } from "react";
// react library for routing
import { useLocation, NavLink as NavLinkRRD } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  NavLink,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Nav,
  Row,
} from "reactstrap";
import {
  Envelope,
  DoorOpen, DoorOpenFill, House, HouseFill, Book,
  BookFill, Clock, ClockFill, PlusCircle, PlusCircleFill, CalendarEvent, CalendarEventFill
} from 'react-bootstrap-icons';

function BottomNavbar() {
  const [actionIsOpen, setActionIsOpen] = useState(false);

  const routes = [
    {
      path: "/app/home",
      name: "בית",
      icon: <House size={26} className="mb-1" />,
      iconActive: <HouseFill size={26} className="mb-1" />,
      color: "text-achim-blue",
      layout: "",
    },
    {
      path: "/app/hours",
      name: "שעות לימוד",
      icon: <Clock size={26} className="mb-1" />,
      iconActive: <ClockFill size={26} className="mb-1" />,
      color: "text-achim-blue",
      layout: "",
    },
    {
      path: "/app/benefits",
      name: "הטבות",
      icon: <DoorOpen size={26} className="mb-1" />,
      iconActive: <DoorOpenFill size={26} className="mb-1" />,
      color: "text-achim-pink",
      layout: "",
    },
    {
      path: "/app/study",
      name: "תכנים",
      icon: <Book size={26} className="mb-1" />,
      iconActive: <BookFill size={26} className="mb-1" />,
      color: "text-achim-orange",
      layout: "",
    },
    {
      path: "/app/events",
      name: "אירועים",
      icon: <CalendarEvent size={26} className="mb-1" />,
      iconActive: <CalendarEventFill size={26} className="mb-1" />,
      color: "text-achim-green",
      layout: "",
    },
    {
      path: "/app/add-hour",
      name: "דיווח שעה",
      icon: <PlusCircle size={26} className="mb-1" />,
      iconActive: <PlusCircleFill size={26} className="mb-1" />,
      color: "text-achim-blue",
      layout: "",
    },
  ];

  const actionRouts = [
    {
      path: "/add-hour",
      name: "דיווח שעת לימוד",
      icon: <Clock size={23} />,
      layout: "/app",
      color: "bg-achim-green",
    },
    {
      path: "/tickets",
      name: "פתיחת פניה",
      icon: <Envelope size={23} />,
      layout: "/app",
      color: "bg-achim-blue",
    }
  ]

  const location = useLocation();
  // verifies if routeName is the one active (in browser input)
  const activeRouteIcon = (routeName, icon, iconActive) => {
    return location.pathname.indexOf(routeName) > -1 ? iconActive : icon;
  }; //active!!!
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }; //active!!!
  const activeRouteColor = (routeName, color) => {
    return location.pathname.indexOf(routeName) > -1 ? color : "";
  }; //active!!!

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      return (
        <Col className={activeRoute(prop.layout + prop.path) + ' p-0 m-0'} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            tag={NavLinkRRD}
            className="p-0"
          >
            {
              <div>
                <div className={classnames(
                  activeRouteColor(prop.layout + prop.path, prop.color)
                )}>
                  {activeRouteIcon(prop.layout + prop.path, prop.icon, prop.iconActive)}
                </div>
                <div className={classnames(
                  "h6 font-weight-normal lh-130 m-0",
                  activeRouteColor(prop.layout + prop.path, prop.color)
                )}
                >
                  <span>{prop.name}</span>
                </div>
              </div>
            }
          </NavLink>
        </Col>
      );
    });
  };

  return (
    <>
      <div
        className="d-xl-none fluid fixed-bottom navbar-light bg-white border-top shadow-lg"
      >
        <Row
          className="bottomNavbar text-center justify-content-around pt-2 pb-2 p-0 m-0"
        >
          {createLinks(routes)}
         {/*  <Col className='p-0 m-0'>
              <UncontrolledDropdown
                className="p-0 m-0"
                isOpen={actionIsOpen}
                toggle={() => setActionIsOpen(!actionIsOpen)}
              >
                <DropdownToggle className="nav-link p-0 m-0" color="" tag="a">
                  <div>
                    <div className={classnames(
                      activeRouteColor('action', 'text-achim-blue')
                    )}>
                      <PlusCircle size={26} className="mb-1" />
                    </div>
                    <div className={classnames(
                      "h6 font-weight-normal lh-130 m-0",
                      activeRouteColor('action', 'text-achim-blue')
                    )}
                    >
                      <span>פעולות</span>
                    </div>
                  </div>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-lg dropdown-menu-dark bg-default"
                  right
                >
                  <Row className="shortcuts px-4">
                    {createDropdown(actionRouts)}
                  </Row>
                </DropdownMenu>
              </UncontrolledDropdown>
          </Col> */}
        </Row>
      </div>
    </>
  );
}

export default BottomNavbar;
