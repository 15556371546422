import React, { useContext, useState, useEffect } from 'react';
//context
import ErrorMsgContext from 'context/ErrorMsgContext';
// reactstrap components
import {
  Alert,
} from "reactstrap";

function AlertError(type) {
  const { errorMsg, setErrorMsg } = useContext(ErrorMsgContext);
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMsg('')
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Alert color={errorMsg.color ? errorMsg.color : "warning"}
      isOpen={visible} toggle={onDismiss}
      className="fixed-top w-50 mx-auto mt-6 text-right pr-5 pl-1 alert-index"
    >
      {errorMsg.msg}
    </Alert >
  )
}

export default AlertError;