import React, { useState, useContext } from 'react';
import { Redirect } from "react-router-dom";
import Select2 from "react-select2-wrapper";
//context
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import postHours from 'services/postHours';
// reactstrap components
import {
  Button,
  CardBody,
  CardHeader,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
// core components
import Pending from "components/custom/Pending";
import { X } from 'react-bootstrap-icons';

function RegBenefit({ benefit, ID, modalCallback }) {
  const { post_title, meta } = benefit[0];
  const { hours_price, min_hours, hours_cost, payment } = meta;
  const type = meta["benefits-type"][0];
  const mentor = meta["instructor-name"][0];
  const redirect = meta["redirect"][0];
  const formredirect = meta["formredirect"][0];
  const formredirecturl = meta["formredirecturl"][0];
  
  formredirect == "true" && window.location.replace(formredirecturl);

  const [formValues, setFormValues] = useState({
    amount: 0 - hours_price,
    type: type === 'door' ? 'דלת' : type === 'event' ? 'אירוע' : 'קורס',
    day: '0',
    chevruta: ID,
    study_topic: post_title,
    insight: '0',
    note: '',
    date: dateToDate1(new Date()),
    time_to: '00:00:00',
    time_from: '00:00:00'
  });
  const [pending, setPending] = useState(false);
  const [sent, setSent] = useState(false);
  const { user } = useContext(UserContext);
  const { hours, setHours } = useContext(HoursContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [coupon, setCoupon] = useState(false);

  const giftOptions = [
    { option: "סניף חדרה - שכטמן 10 חדרה. מתחם מול החוף וילג'" },
    { option: 'סניף תל אביב- אבן גבירול 71, גן העיר' },
    { option: 'סניף רבי עקיבא בני ברק- רבי עקיבא 49' },
    { option: 'סניף רימונים בני ברק- אהרונוביץ 12' },
    { option: 'סניף באר שבע - קניון קריית הממשלה, התקווה 4' },
    { option: 'סניף ירושלים- ירמיהו 24' },
    { option: 'סניף חיפה - קניון שער הצפון. ההסתדרות 25, קריית אתא' },
  ];

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  function dateToDate1(date) {
    let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    let year = date.getFullYear() > 9 ? date.getFullYear() : '0' + date.getFullYear();
    let month = date.getMonth() > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    return `${day}/${month}/${year}`
  };


  function dateToDate(date) {
    const array = date.split('/');
    return `${array[2]}-${array[1]}-${array[0]}`;
  };

  const handleSubmitCoupon = async (e) => {
    e.preventDefault();
    if (formValues.note == Math.floor(ID * (ID / 2) + (new Date().getMonth() * 3))) {
      setCoupon(true);
      setFormValues({
        ...formValues,
        ['note']: 'קופון אישור מהרכז'
      });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setHour();
  }

  function setHour() {
    setPending(true);
    const values = {
      ...formValues,
      ['date']: dateToDate(formValues.date)
    };
    postHours(user.ID, values)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            console.log(response);
            setErrorMsg({ error: true, msg: 'העדכון נכשל, נסה/י שוב' });
            setPending(false);
          }
          else {
            setHours({
              ...hours,
              ['negative']: hours.negative * 1 - hours_price,
              ['total']: hours.total * 1 - hours_price
            });
            setErrorMsg({ error: true, msg: 'נרשמת בהצלחה!', color: 'success' });
            const redirect1 = redirect ? redirect : 'https://achimlanefesh.org.il/event-regist/'
            window.location.replace(redirect1);
            setSent(true);
          }
        })
      .catch((err) => {
        setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {sent && <Redirect to={payment == 'false' ? "/app/home" : '/app/payment'} push={true} />}

      {pending && <Pending />}
      <CardHeader
        className="bg-achim-pink text-right"
      >
        <a href="#"
          className="text-dark mb--6"
          onClick={() => modalCallback(false)}
        >
          <X size={25} className="mb--" />
        </a>
        <h1 className="lh-130 text-center text-achim-black">הרשמה ל
          {type === 'door' && 'דלת'}
          {type === 'course' && 'קורס'}
          {type === 'event' && 'אירוע'}
          {type === 'gift' && 'מתנה'}
          :
          <br />
          {post_title}</h1>
        <h3 className="text-center text-achim-black">מרצה: {mentor}</h3>
      </CardHeader>
      <CardBody className="py-3 px-4 px-md-5">
        <div className="text-center w-100 h2 text-achim-pink mb-4">
          {hours_cost == 'true' ?
            `עלות: ${hours_price} שעות לימוד, יש לך ${hours.total ? hours.total : '0'} שעות למימוש`
            :
            `מינימום ${min_hours} שעות לימוד צבורות. יש לך ${hours.positive || '0'} שעות, (עלות: ${hours_price} שעות)`
          }
        </div>
        {coupon === false && ((hours_cost == 'true' && (hours.total * 1) < (hours_price * 1)) || (hours_cost == 'false' && (hours.positive * 1) < (min_hours * 1))) ?
          <div className="text-center mb-4">
            <div className="display-3 text-center text-achim-orange lh-120">
              אין לך מספיק שעות להרשמה!
            </div>

            <Form role="form" onSubmit={handleSubmitCoupon} className="form-inline form-row mt-4 text-center">
              <label
                className="form-control-label m-0 ml-1"
                htmlFor="note"
              >
                קוד קופון
              </label>
              <Input
                id="note"
                type="text"
                size='sm'
                className="d-inline w-50 ml-1"
                name="note"
                placeholder="קוד קופון מהרכז"
                onChange={handleChange}
                onBlur={handleChange}
                value={formValues.note}
                required
              />
              <Button
                color="dark"
                className="text-white d-inline"
                type="submit"
                size='sm'
              >
                שלח קופון
                <i className="text-white fas fa-chevron-left fa-xs mr-1"></i>
              </Button>
            </Form>
            <Button
              onClick={() => modalCallback(false)}
              color="green"
              className="bg-achim-orange text-white text-center mt-4"
              type="submit"
            >
              ביטול
            </Button>
          </div>
          :
          <Form role="form" onSubmit={handleSubmit}>
            {(type === 'gift') && <FormGroup className="mb-3 mt--3">
              <label
                className="form-control-label"
                htmlFor="note"
              >
                מוקד חלוקה לאיסוף עצמי
              </label>
              <Input
                id="note"
                className="mr-1"
                type="select"
                name="note"
                onChange={handleChange}
                value={formValues.note}
              >
                {giftOptions.map((option) => (
                  <option value={option.option} key={option.option}>{option.option}</option>
                ))}
              </Input>
            </FormGroup>}
            {(type !== 'event' && type !== 'gift') && <FormGroup className="mb-3 mt--3">
              <label
                className="form-control-label"
                htmlFor="note"
              >
                מדוע?
              </label>
              <Input
                id="note"
                rows="2"
                type="textarea"
                name="note"
                placeholder="מדוע את/ה מעוניין/ת להשתתף בקורס זה?"
                onChange={handleChange}
                onBlur={handleChange}
                value={formValues.note}
                required
              />
            </FormGroup>}
            <FormGroup>
              <Button
                block
                color="green"
                className="bg-achim-pink text-white"
                type="submit"
              >
                הרשמה ל
                {type === 'door' && 'דלת'}
                {type === 'course' && 'קורס'}
                {type === 'event' && 'אירוע'}
                <i className="text-white fas fa-chevron-left fa-xs mr-1"></i>
              </Button>
            </FormGroup>
            <div className="mt-1">לתשומת לבך: בהרשמה להטבה תממש {hours_price} שעות שצברת! </div>
            {payment == 'true' && <div className="mt-1 h4">לאחר ההרשמה תועבר/י לדף התשלום! </div>}
          </Form>
        }
      </CardBody>

    </>
  );
}

export default RegBenefit;