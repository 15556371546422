import React from "react";
// reactstrap components
import { Container } from "reactstrap";

function ProfileHeader({ user_name }) {
  return (
    <>
      <div
        className="bg-gradient-info header pb-6 d-flex align-items-center"
      >
        <Container className="d-flex align-items-center pb-6 pt-5 pt-md-7" fluid>
            <h1 className="display-2 text-white w-100 text-center">שלום {user_name}</h1>
        </Container>
      </div>
    </>
  );
}

export default ProfileHeader;
