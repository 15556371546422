import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from "react-router-dom";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
//context
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import getLotterySubs from 'services/getLotterySubs';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Container,
  Col,
  Modal,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
//my components
import Loader from "components/custom/Loader";
import CreateTickets from '../forms/CreateTickets';
import LotteryAdminHeader from "components/Headers/LotteryAdminHeader.js";
import ExpotCsv from "services/ExpotCsv.js";

function LotteryAdminPage() {
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const ID = pathArray[3];
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [lottery, setLottery] = useState({});
  const [lotterySubs, setLotterySubs] = useState([]);

  const [pending, setPending] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [formModal, setformModal] = useState(false);
  const [formModal2, setformModal2] = useState(false);
  const [activeNav, setActiveNav] = useState(1);

  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          הצג{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          רשומות.
        </label>
      </div>
    ),
  });

  const { SearchBar } = Search;

  function modalCallback(status) {
    setformModal(status)
  }
  function modalCallback2(status) {
    setformModal2(status)
  }
  function setgifts(gifts) {
    setLottery({ ...lottery, gifts: gifts });
  }

  const toggleNavs = (e, index) => {
    if (e) e.preventDefault();
    setActiveNav(index);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setCurrentUser(row)
      setformModal(true)
    }
  };

  function activeData() {
    if (activeNav == 1) { return lotterySubs ? lotterySubs : [] }
    else if (activeNav == 2) { return lottery.conditions ? lottery.conditions : [] }
    else if (activeNav == 3) { return lottery.gifts ? lottery.gifts : [] }
    else { return [] }
  }

  function activefields() {

    if (activeNav == 1) {
      return [
        {
          dataField: "ID",
          text: "ID",
          sort: true,
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b; // desc
          }
        },
        {
          dataField: "display_name",
          text: "שם",
          sort: true,
        },
        {
          dataField: "user_email",
          text: "אימייל",
          sort: true,
        },
        {
          dataField: "phone",
          text: "פלאפון",
          sort: true,
        },
        {
          dataField: "guide",
          text: "מנחה",
          sort: true,
        },
        {
          dataField: "note",
          text: "הערה",
          sort: true,
        },
        {
          dataField: "contact",
          text: "איש קשר",
          sort: true,
        },
        {
          dataField: "positive",
          text: "שעות שנצברו",
          sort: true,
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b; // desc
          }
        },
        {
          dataField: "create_time",
          text: "תאריך",
          sort: true,
        },
      ]
    }
    else if (activeNav == 2) {
      return [
        {
          dataField: "ID",
          text: "ID",
          sort: true,
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b; // desc
          }
        },
        {
          text: 'פחות מ',
          dataField: 'less_than',
          sort: true,
        },
        {
          text: 'יותר מ',
          dataField: 'more_than',
          sort: true,
        },
        {
          text: 'כרטיסים',
          dataField: 'tickets',
          sort: true,
        }
      ]
    }
    else if (activeNav == 3) {
      return [
        {
          dataField: "ID",
          text: "ID",
          sort: true,
          sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (order === 'asc') {
              return b - a;
            }
            return a - b; // desc
          }
        },
        {
          text: 'הפרס',
          dataField: 'gift',
          sort: true,
        },
        {
          text: 'הזוכה',
          dataField: 'user_name',
          sort: true,
        },
        {
          text: 'מספר זוכה',
          dataField: 'user',
          sort: true,
        }
      ]
    }
  }

  function callGet() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      setPending(true);
      if (token) {
        getLotterySubs(ID)
          .then(
            (response) => {
              const { success, data } = response[0];
              if (!success) {
                setLottery({ title: 'הגרלה - שגיאה' });
                setLotterySubs([{ ID: "אין נרשמים" }]);
                setPending(false);
              }
              else {
                setLottery(data);
                if (response[1].success) {
                  setLotterySubs(response[1].data);
                } else {
                  setLotterySubs([{ ID: "אין נרשמים" }]);
                }
                setPending(false);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setLottery({ title: 'הגרלה - שגיאה' });
            setLotterySubs([]);
            setErrorMsg({ error: true, msg: 'נרשמים - error c' });
            setPending(false);
          }
          );
      }
    }
  }

  useEffect(() => {
    callGet();
  }, [])

  return (
    <>
      <LotteryAdminHeader title={lottery.title} sub_title={lottery.sub_title}
        date={lottery.date} active={lottery.active}
        color="orange" id={lottery.ID} modalCallback={modalCallback2}
      />
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={formModal}
        toggle={() => setformModal(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0">
            <h2 classnames="text-center p-3"> ביטול רישום להגרלה - לא זמין</h2>
          </Card>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        backdrop={true}
        backdropClassName="bg-achim-black"
        size="md"
        isOpen={formModal2}
        toggle={() => setformModal2(false)}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0">
            <CreateTickets
              lottery={lottery}
              modalCallback={setformModal2}
              setgifts={setgifts}
            />
          </Card>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={activeData()}
                keyField="ID"
                columns={activefields()}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row className="d-flex justify-content-between w-100 px-4 pb-1">
                      <Nav className="justify-content-center ml-2 mb-4" pills>
                        <NavItem className="m-0 px-1">
                          <NavLink
                            className={classnames("py-1 px-3 form-control-sm", {
                              active: activeNav === 1,
                            })}
                            href="#pablo"
                            onClick={(e) => toggleNavs(e, 1)}
                          >
                            <span>נרשמים</span>
                          </NavLink>
                        </NavItem>
                        <NavItem className="m-0 px-1">
                          <NavLink
                            className={classnames("py-1 px-3 form-control-sm", {
                              active: activeNav === 2,
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={(e) => toggleNavs(e, 2)}
                          >
                            <span>תנאים</span>
                          </NavLink>
                        </NavItem>
                        <NavItem className="m-0 px-1">
                          <NavLink
                            className={classnames("py-1 px-3 form-control-sm", {
                              active: activeNav === 3,
                            })}
                            data-toggle="tab"
                            href="#pablo"
                            onClick={(e) => toggleNavs(e, 3)}
                          >
                            <span>פרסים</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label className="ml-1">
                          חיפוש:
                        </label>
                        <SearchBar
                          className="form-control-sm"
                          placeholder="חיפוש וסינון"
                          {...props.searchProps}
                        />
                      </div>
                      <div className="text-div">
                        <ExpotCsv url={`/wp-json/achim/v1/lottery/${ID}/subs?lottery=${ID}`} size='sm' color='info' text='ייצוא לאקסל' fileTitle={`נרשמים ל-${lottery.title}`} />
                      </div>
                    </Row>
                    {pending && <Loader text="" />}
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      rowEvents={rowEvents}
                      bordered={false}
                      className="text-right"
                      rowClasses="text-right hover-row-class"
                      id="react-bs-table"
                      defaultSorted={[{
                        dataField: 'ID',
                        order: 'desc'
                      }]}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
export default LotteryAdminPage;
