import React, { useState, useContext } from 'react';
//context
import UserContext from 'context/UserContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import PostPassport from 'services/PostPassport';
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Form,
    Input,
    Modal,
    Col,
} from "reactstrap";
import Pending from "components/custom/Pending";


export default function PassportModal() {
    const [formModal, setformModal] = useState(true);
    const { user, setUser } = useContext(UserContext);
    const { setErrorMsg } = useContext(ErrorMsgContext);
    const [pending, setPending] = useState(false);
    const [passport, setPassport] = useState(null);
    const [passportState, setPassportState] = useState(null);

    const validateCustomStylesForm = (value) => {
        if (value === "" || !is_israeli_id_number(value)) {
            setPassportState("invalid");
            return false;
        } else {
            setPassportState("valid");
            return true;
        }
    }

    function is_israeli_id_number(id) {
        id = String(id).trim();
        if (id.length > 9 || isNaN(id)) return false;
        id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
            return Array.from(id, Number).reduce((counter, digit, i) => {
                const step = digit * ((i % 2) + 1);
                return counter + (step > 9 ? step - 9 : step);
            }) % 10 === 0;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateCustomStylesForm(passport)) postForm();
    }

    function postForm() {
        setPending(true);
        PostPassport(user.ID, passport)
            .then(
                (response) => {
                    const { success, data } = response;
                    if (!success) {
                        setErrorMsg({ error: true, msg: 'העדכון נכשל, נסה/י שוב' });
                        setPending(false);
                    }
                    else {
                        setUser(data);
                        setformModal(false);
                    }
                })
            .catch((err) => {
                setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
                console.log('Error! c');
                setPending(false);
            }
            );
    }

    return (
        <>
            {!user.data.meta.passport &&
                <Modal
                    className="modal-dialog-centered"
                    size="sm"
                    isOpen={formModal}
                    toggle={() => setformModal(false)}
                    backdrop="static"
                >
                    {pending && <Pending />}
                    <div className="modal-body p-0">
                        <Card className="bg-secondary border-0 mb-0 pt-2">
                            <CardBody className="px-lg-5 py-lg-5">
                                <div className="text-center mb-4">
                                    <h3>שלום {user.data.meta.first_name[0]}</h3>
                                    לקבלת ההטבות, נא לעדכן מס' תעודת זהות
                                </div>
                                <Form className="needs-validation" noValidate role="form" onSubmit={handleSubmit}>
                                    <Col>
                                        <Input
                                            id="validationCustom01"
                                            name="passport"
                                            placeholder="תעודת זהות"
                                            type="text"
                                            valid={passportState === "valid"}
                                            invalid={passportState === "invalid"}
                                            onChange={(e) => {
                                                setPassport(e.target.value);
                                                validateCustomStylesForm(e.target.value);
                                            }}
                                            onBlur={(e) => {
                                                validateCustomStylesForm(passport);
                                            }}
                                        />
                                        <div className="valid-feedback">מעולה!</div>
                                    </Col>
                                    <div className="text-center">
                                        <Button
                                            className="my-4" color="info" type="submit">
                                            עדכון תעודת זהות
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Modal>
            }
        </>
    )
}