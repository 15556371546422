export default function getBenefitUser(id, user_id) {
    let token = localStorage.getItem("token");
    let url = `/wp-json/achim/v1/benefits/${id}/user/${user_id}`;
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}