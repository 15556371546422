import React, { useContext, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
//context
import HoursContext from 'context/HoursContext';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
// nodejs library that concatenates classes
import classnames from "classnames";
import {
  PlusCircle
} from 'react-bootstrap-icons';

function HoursHeader({ typeView, setTypeView }) {
  const { hours } = useContext(HoursContext);
  const { negative, positive, total } = hours;
  const location = useLocation();

  function typeView1(type) {
    setTypeView(type)
  }

  useEffect(() => {
    location.search === '?negative' ? setTypeView('negative') : setTypeView('positive');
  }, [])

  return (
    <>
      <div className="header bg-achim-blue pb-6 pt-1">
        <Container fluid>
          <div className="position-fixed text-white pt-3 p-md-4 slide-down">
            <Link to="/app/add-hour">
              <div className="text-center text-white"> <PlusCircle size={35} className="mb-2" /></div>
              <h2 className="h5 text-center text-white lh-100 font-weight-300">דיווח שעת לימוד</h2>
            </Link>
          </div>
          <Row md="12" xl="12" className="mt-0 mt-sm-0 pt-0 pt-sm-4 mb--2 justify-content-center">
            <img
              alt="Image placeholder"
              src={require("assets/img/theme/clock_books2.svg").default}
              width="250"
            />
          </Row>
          <h2 className="display-3 text-center text-white font-weight-400">{total ? total : 0} שעות למימוש</h2>
          <div className="header-body">
            <Row className="justify-content-center">
              <Col sm="10"  md="8" xl="6">
                <Card className="card-stats mb-3">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col
                        className="col text-center border-left border-light"
                        onClick={() => typeView1('positive')}
                      >
                        <a
                          href="#"
                          onClick={(e) => e.preventDefault()}
                        >
                          <h3
                            className={classnames(
                              "display-2 m-0 mb-1 lh-110",
                              typeView === 'positive' ? "text-achim-blue" : "text-light"
                            )}
                          >
                            {positive ? positive : 0}
                          </h3>
                          <p
                            className={classnames(
                              "mb-0 lh-130 font-weight-500",
                              typeView === 'positive' ? "text-achim-blue" : "text-light"
                            )}
                          >
                            שעות שצברתי
                          </p>
                          <i
                            className={classnames(
                              "fas fa-chevron-down fa-xs",
                              typeView === 'positive' ? "text-achim-blue" : "text-light"
                            )}
                          ></i>
                        </a>
                      </Col>
                      <Col
                        className="col text-center"
                        onClick={() => typeView1('negative')}
                      >
                        <a
                          href="#"
                          onClick={(e) => e.preventDefault()}
                        >
                          <h3
                            className={classnames(
                              "display-2 m-0 mb-1 lh-110",
                              typeView === 'negative' ? "text-achim-blue" : "text-light"
                            )}
                          >
                            {Math.abs(negative)}
                          </h3>
                          <p
                            className={classnames(
                              "mb-0 lh-130 font-weight-500",
                              typeView === 'negative' ? "text-achim-blue" : "text-light"
                            )}
                          >
                            שעות שניצלתי
                          </p>
                          <i
                            className={classnames(
                              "fas fa-chevron-down fa-xs",
                              typeView === 'negative' ? "text-achim-blue" : "text-light"
                            )}
                          ></i>
                        </a>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

HoursHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default HoursHeader;
