import React, { useContext, useEffect, useState } from 'react';
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
//context
import HoursContext from 'context/HoursContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
import HoursDetailsContext from 'context/HoursDetailsContext';
//services
import getSummaries from 'services/getSummaries';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import CardsHeader from "components/Headers/CardsHeader.js";
import DaysBar from "views/pages/dashboards/DaysBar.js";
import HoursBar from "views/pages/dashboards/HoursBar.js";
import Pending from "components/custom/Pending";
import Loader from "components/custom/Loader2";


import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";

import {
  chartExample1
} from "variables/chartLine1.js";

function Dashboard() {
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const { hours, setHours } = useContext(HoursContext);
  const { hoursDetails, setHoursDetails } = useContext(HoursDetailsContext);
  const [hoursWithoutBonus, setHoursWithoutBonus] = useState([]);
  const [weekSummary, setWeekSummary] = useState([]);
  const [monthSummary, setMonthSummary] = useState([]);
  const [weekByDaysSummary, setWeekByDaysSummary] = useState([]);
  const [monthByDaysSummary, setMonthByDaysSummary] = useState([]);
  const [summaryByMonts, setSummaryByMonts] = useState([]);
  const [daysOfWeekSummary, setDaysOfWeekSummary] = useState([]);
  const [hoursOfDaySummary, setHoursOfDaySummary] = useState([]);
  const [usersSummary, setUsersSummary] = useState([]);
  const [pending, setPending] = useState(false);

  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");

  function getRows() {

    if (hoursDetails.length < 1 || hoursDetails === "No record found") {
      return <tr>
        <th scope="row" colSpan="4" className="text-center">אין מידע</th>
      </tr>
    } else {
      if (hoursDetails.length) {
        const hoursDetails2 = hoursDetails.slice(0, 7);
        return hoursDetails2.map((item, key) => {
          return (
            <tr key={key} className="text-right">
              <th scope="row">{item.display_name}</th>
              <td>{item.type}</td>
              <td>{item.amount}</td>
              <td>{item.study_topic}</td>
              <td>{item.create_time}</td>
            </tr>
          );
        });
      }
    }
  }

  chartExample1.data2 = (canvas) => {
    return {
      labels: monthByDaysSummary.map(obj => { return obj.date }),
      datasets: [
        {
          label: "Performance",
          data: monthByDaysSummary.map(obj => { return obj.positive }),
        },
      ],
    };
  }
  chartExample1.data1 = (canvas) => {
    return {
      labels: summaryByMonts.map(obj => { return obj.month }),
      datasets: [
        {
          label: "Performance",
          data: summaryByMonts.map(obj => { return obj.positive }),
        },
      ],
    };
  }


  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }


  function callGet() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      setPending(true);
      if (token) {
        getSummaries()
          .then(
            (response) => {
              setHours(response[0].data[0]);
              setWeekSummary(response[1].data[0]);
              setWeekByDaysSummary(response[2].data);
              setMonthByDaysSummary(response[3].data);
              setSummaryByMonts(response[4].data);
              setDaysOfWeekSummary(response[5].data);
              setHoursOfDaySummary(response[6].data);
              setHoursWithoutBonus(response[7].data[0]);
              const usersSum = response[8].data
              const usersSumStudents = usersSum.find((obj2) => obj2.capabilities.includes('student'));
              setUsersSummary(usersSumStudents);
              setMonthSummary(response[9].data[0]);
              setHoursDetails(response[10].data);
              setPending(false);
            })
          .catch((err) => {
            setPending(false);
            console.log('Error! c', err);
            setErrorMsg({ error: true, msg: 'דשבורד Error' });
          }
          );
      }
    }
  }

  useEffect(() => {
    callGet();
  }, [])

  return (
    <>
      <CardsHeader name="דשבורד ניהול"
        hours={hours} weekSummary={weekSummary} pending={pending} monthSummary={monthSummary}
        hoursWithoutBonus={hoursWithoutBonus} usersSummary={usersSummary}
      />
      <Container className="mt--6 text-right" fluid>
        <Row>
          <Col xl="7">
            {pending && <Loader />}
            <Card className="bg-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h2 text-white mb-0">שעות לימוד</h5>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem className="m-0 px-1">
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">הכל</span>
                          <span className="d-md-none">הכל</span>
                        </NavLink>
                      </NavItem>
                      <NavItem className="m-0 px-1">
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">חודש</span>
                          <span className="d-md-none">חודש</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    id="chart-sales-dark"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="5">
            {pending && <Loader />}
            <Card>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">שעות / הרשמות אחרונות</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">שם</th>
                    <th>סוג</th>
                    <th>שעות</th>
                    <th>נושא</th>
                    <th>תאריך</th>
                  </tr>
                </thead>
                <tbody>
                  {getRows()}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="5">
            {pending && <Loader />}
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h2 mb-0">ימי לימוד</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <DaysBar dataToBar={daysOfWeekSummary} />
              </CardBody>
            </Card>
          </Col>
          <Col xl="7">
            {pending && <Loader />}

            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h5 className="h2 mb-0">שעות הלימוד ביממה</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <CardBody>
                  <HoursBar dataToBar={hoursOfDaySummary} />
                </CardBody>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
