export default function PostPassport(user, data) {
    let token = localStorage.getItem("token");
    let url = `/wp-json/achim/v1/user/${user}/passport`;

    let formData = new FormData();
    formData.append('passport', data);
    /* for (const name in data) {
        formData.append(name, data[name]);
    } */

    let headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Authorization', 'Bearer ' + token);

    let options = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers,
        body: formData
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}