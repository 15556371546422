import React from 'react';
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import CardsHeader from "components/Headers/StudyHeader.js";
import Lottery from "views/pages/benefits/Lottery";
//services


function Lotteries() {

  return (
    <>
      <CardsHeader name="הגרלות" parentName="Components" />
      <Container className="mt--6" >
        <Row className="slide-top card-wrapper justify-content-center px-3 px-sm-0 ">
              <Col md="6" lg="5" xl="4" className="">
                <Lottery
                />
              </Col>
          </Row>
      </Container>
    </>
  );
}

export default Lotteries;
