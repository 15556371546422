import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Card,
  Container,
  Row,
} from "reactstrap";
// core components
import TablesHeader from "components/Headers/TablesHeader.js";
import Loader from "components/custom/Loader";
//context
import BenefitsContext from 'context/BenefitsContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import getBenefitsAdmin from 'services/getBenefitsAdmin';
import { DoorOpen } from 'react-bootstrap-icons';


function BenefitsTable() {
  const { benefits, setBenefits } = useContext(BenefitsContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [pending, setPending] = useState(false);


  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          הצג{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          רשומות.
        </label>
      </div>
    ),
  });

  const { SearchBar } = Search;

  const history = useHistory();

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      let path = `benefit-admin/${row.ID}`;
      history.push(path);
    }
  };

  function callGet() {
    if (localStorage.getItem("token")) {
      setPending(true);
      let token = localStorage.getItem("token");
      if (token) {
        getBenefitsAdmin()
          .then(
            (response) => {
              const { success, data } = response[0];
              const { success: success2, data: data2 } = response[1];
              if (!success || !success2) {
                setBenefits([]);
                setErrorMsg({ error: true, msg: 'הטבות - error' });
                setPending(false);
              }
              else {
                let reformattedArray = data.map(obj => {
                  obj.start_date = obj.meta['start-date'];
                  let benefit = data2.find((obj2) => obj2.chevruta == obj.ID);
                  obj.registered = benefit ? benefit.subs : '';
                  obj.type = benefit ? benefit.type : '';
                  switch (obj.meta['benefits-type'][0]) {
                    case 'door':
                      obj.type = 'דלת';
                      break
                    case 'course':
                      obj.type = 'קורס';
                      break;
                    case 'event':
                      obj.type = 'אירוע';
                      break;
                    default:
                      obj.type = 'קורס';
                  }
                  obj.status =
                    obj.meta['active-benefits'][0] === 'true' ?
                      '- הרשמה פתוחה'
                      : obj.meta['open-soon'] === 'true' ?
                        'ההרשמה בקרוב'
                        : 'הרשמה סגורה';
                  obj.coupon = Math.floor(obj.ID * (obj.ID / 2) + (new Date().getMonth() * 3));
                  return obj
                })
                setBenefits(reformattedArray);
                setPending(false);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setBenefits([]);
            setErrorMsg({ error: true, msg: 'הטבות - error c' });
            setPending(false);
          }
          );
      }
    }
  }

  useEffect(() => {
    callGet();
  }, [])

  return (
    <>
      <TablesHeader name="הטבות" parentName="Tables" color="pink" add_text='הטבה חדשה'
        add_link='https://achimlanefesh.org.il/wp-admin/post-new.php?post_type=benefits'
        add_icon={<DoorOpen size={30} className="mb-2" />} link={false} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={benefits}
                keyField="ID"
                columns={[
                  {
                    dataField: "ID",
                    text: "ID",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "post_title",
                    text: "ההטבה",
                    sort: true,
                  },
                  {
                    dataField: "type",
                    text: "סוג",
                    sort: true,
                  },
                  {
                    dataField: "coupon",
                    text: "קוד קופון",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "registered",
                    text: "נרשמו",
                    sort: true,
                    sortFunc: (a, b, order, dataField, rowA, rowB) => {
                      if (order === 'asc') {
                        return b - a;
                      }
                      return a - b; // desc
                    }
                  },
                  {
                    dataField: "status",
                    text: "סטטוס",
                    sort: true,
                  },
                  {
                    dataField: "start_date",
                    text: "תאריך",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row className="d-flex justify-content-between w-100 px-4 pb-1">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label className="ml-1">
                          חיפוש:
                        </label>
                        <SearchBar
                          className="form-control-sm"
                          placeholder="חיפוש וסינון"
                          {...props.searchProps}
                        />
                      </div>
                      {/* <div className="text-div">
                        <ExpotCsv url="https://achimlanefesh.org.il/wp-json/achim/v1/benefits?per=15&paged=1" size='sm' color='info' text='ייצוא לאקסל' fileTitle='הטבות' />
                      </div> */}
                    </Row>
                    {pending && <Loader text="" />}
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      rowEvents={rowEvents}
                      pagination={pagination}
                      bordered={false}
                      className="text-right"
                      rowClasses="text-right hover-row-class"
                      id="react-bs-table"
                      defaultSorted={[{
                        dataField: 'ID',
                        order: 'asc'
                      }]}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default BenefitsTable;
