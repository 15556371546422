import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import { ArrowRight, Pen, PersonPlus, Window } from "react-bootstrap-icons";

function TimelineHeader({ title, mentor, id, color, modalCallback }) {
  return (
    <>
      <div className={classnames("header", "bg-achim-" + color, "pb-6 pt-1")}>
        <div className="text-right pt-4 px-4 bg-achim-pink">
          <Link to={`/app/benefits-admin`} className="text-white">
            <ArrowRight size={19} className="my-0 mr-0 ml-1" /> לכל ההטבות
          </Link>
        </div>
        <Container fluid>
          <Row className="align-items-center text-center">
            <Col>
              <h1 className="display-2 text-white mb-0 m-auto">
                {title}
              </h1>
              <h2 className="text-white mb-0 m-auto">
                {mentor}
              </h2>
            </Col>
          </Row>
          <Row className="text-center justify-content-center">
            <div className="text-white pt-3 p-md-4 slide-down">
              <a href="#" onClick={() => modalCallback(true)}>
                <div className="text-center text-white">
                  <PersonPlus size={30} className="mb-2" />
                </div>
                <h2 className="h5 text-center text-white lh-100 font-weight-300">הוספת נרשמ/ת</h2>
              </a>
            </div>
            <div className="text-white pt-3 p-md-4 slide-down">
              <a href={`https://achimlanefesh.org.il/wp-admin/post.php?post=${id}&action=edit`}
                target={"_blank"}>
                <div className="text-center text-white">
                  <Pen size={30} className="mb-2" />
                </div>
                <h2 className="h5 text-center text-white lh-100 font-weight-300">עריכת הטבה</h2>
              </a>
            </div>
            <div className="text-white pt-3 p-md-4 slide-down">
              <Link to={`/app/benefit/${id}`}>
                <div className="text-center text-white">
                  <Window size={30} className="mb-2" />
                </div>
                <h2 className="h5 text-center text-white lh-100 font-weight-300">דף ההטבה</h2>
              </Link>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
