import React, { useContext } from 'react';
//context
import StudyesContext from 'context/StudyesContext';
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import CardsHeader from "components/Headers/EventsHeader.js";
import EventSlider from "./EventSlider";

function Events() {
  const { studyes } = useContext(StudyesContext);

  const createBenefits = () => {
    return studyes.map((prop, key) => {
      return (
        <Col key={key} lg="3">
          <EventSlider
            props={prop}
          />
        </Col>
      )
    })
  }

  return (
    <>
      <CardsHeader name="אירועים" parentName="Components" />
      <Container className="mt--6" fluid>
        <Row className="card-wrapper">
          {createBenefits()}
        </Row>
      </Container>
    </>
  );
}

export default Events;
