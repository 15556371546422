import React, { useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
//context
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import getHours from 'services/getHours';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  PlusCircle
} from 'react-bootstrap-icons';

function CardsHeaderAchim({ name, parentName }) {
  const { hours, setHours } = useContext(HoursContext);
  const { user } = useContext(UserContext);
  const { negative, positive, total } = hours;
  const { setErrorMsg } = useContext(ErrorMsgContext);

  function callGetHours() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getHours(user.ID)
          .then(
            (response) => {
              const { success, data } = response[1];
              if (!success) {
                setHours({ negative: 0, positive: 0, total: 0 });
                setErrorMsg({ error: true, msg: 'שעות לימוד - שגיאת שרת' });
              }
              else {
                setHours(data[0]);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setHours({ negative: 0, positive: 0, total: 0 });
            setErrorMsg({ error: true, msg: 'שעות לימוד - שגיאת שרת' });
          }
          );
      }
    }
  }

  useEffect(() => {
    callGetHours();
  }, [])

  return (
    <>
      <div fluid className="bg-achim-blue px-lg-5">
        <Row className="p-0 m-0">
          <Col md="6" xl="5"
            className="d-flex align-items-center
            py-md-4 pt-xl-0 pt-4"
          >
            <Container fluid>
              <div className="header-body">
                <div className="text-white pb-2 slide-top">
                  <div className="display-0 text-center">{total ? total : 0}</div>
                  <h2 className="display-4 text-center text-white lh-100">שעות למימוש</h2>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="card-stats mb-3">
                      <CardBody>
                        <Row className="align-items-center">
                          <Col className="col text-center border-left border-success">
                            <Link to="/app/hours?positive">
                              <h3 className="display-2 m-0 mb-1 lh-110 text-achim-green">
                                {positive ? positive : 0}
                              </h3>
                              <p className="mb-0 lh-130 font-weight-500 text-achim-green">
                                שעות שצברתי <i className="fas fa-chevron-left fa-xs"></i>
                              </p>
                            </Link>
                          </Col>
                          <Col className="col text-center">
                            <Link to="/app/hours?negative">
                              <h3 className="display-2 m-0 mb-1 lh-110 text-achim-blue">
                                {negative ? Math.abs(negative) : 0}
                              </h3>
                              <p className="mb-0 lh-130 font-weight-500 text-achim-blue">
                                שעות שניצלתי <i className="fas fa-chevron-left fa-xs"></i>
                              </p>
                            </Link>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* <Col md="6" xl="4">
                    <Link to="/app/benefits">
                      <Card className="card-stats mb-3">
                        <CardBody className="">
                          <Row className="align-items-center">
                            <Col className="col-auto pl-1" md="12" lg="2">
                              <div className="icon icon-shape bg-gradient-achim-pink text-white rounded-circle shadow">
                                <DoorOpen size={26} />
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <h3 className="display-2 m-0 mb-1 lh-110 text-achim-pink">
                                פותחים דלתות
                              </h3>
                              <p className="mb-0 lh-130 text-achim-pink">
                                לכל ההטבות <i className="fas fa-chevron-left fa-xs"></i>
                              </p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                  <Col md="6" xl="4">
                    <Link to="/app/study">
                      <Card className="card-stats mb-3">
                        <CardBody className="">
                          <Row className="align-items-center">
                            <Col className="col-auto pl-1" md="12" lg="2">
                              <div className="icon icon-shape bg-gradient-achim-orange text-white rounded-circle shadow">
                                <Book size={26} />
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <h3 className="display-2 m-0 mb-1 lh-110 text-achim-orange">
                                תכני לימוד
                              </h3><p className="mb-0 lh-130 text-achim-orange">
                                שעה של הנאה <i className="fas fa-chevron-left fa-xs"></i>
                              </p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col> */}
                </Row>
                <div className="text-white pb-2 slide-top">
                  <Link to="/app/add-hour">
                    <div className="text-center text-white"> <PlusCircle size={35} className="mb-2" /></div>
                    <h2 className="h3 text-center text-white lh-100 pb-1">דיווח שעת לימוד</h2>
                  </Link>
                </div>
              </div>
            </Container>
          </Col>
          <Col md="6" xl="7" className="mt--5 mt-sm-0 pt-0 pt-sm-4 d-flex align-items-end">
            <img
              alt="Image placeholder"
              src={require("assets/img/theme/clock_books2.svg").default}
              width="100%"
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

CardsHeaderAchim.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeaderAchim;
