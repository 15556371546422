import React, { useContext, useEffect, useState } from 'react';
//context
import BenefitsContext from 'context/BenefitsContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import BenefitsHeader from "components/Headers/BenefitsHeader.js";
import Benefit from "./Benefit";
import Lottery from "views/pages/benefits/Lottery";
//services
import getBenefits from 'services/getBenefits';


function Benefits() {
  const { benefits, setBenefits } = useContext(BenefitsContext);
  const [filterBenefits, setFilterBenefits] = useState(benefits);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [typeView, setTypeView] = useState(0);
  const [activeView, setActiveView] = useState(0);
  const [isLoad, setIsLoad] = useState(benefits.length);

  function handletypeView(type) {
    setTypeView(type);
    filterBenefits1(type, activeView);
  };

  function handleActiveView(status) {
    setActiveView(status);
    filterBenefits1(typeView, status);
  };

  function filterBenefits1(type, status) {
    setFilterBenefits(benefits.filter(function (benefit) {
      switch (type) {
        case 0:
          switch (status) {
            case 0:
              return benefit.ID;
            case 1:
              return benefit.meta["active-benefits"][0] === 'true';
            case 2:
              return benefit.meta["active-benefits"][0] === 'false';
            default:
              return benefit.ID;
          }
        case 1:
          switch (status) {
            case 0:
              return benefit.meta["benefits-type"][0] === 'door';
            case 1:
              return benefit.meta["benefits-type"][0] === 'door' && benefit.meta["active-benefits"][0] === 'true';
            case 2:
              return benefit.meta["benefits-type"][0] === 'door' && benefit.meta["active-benefits"][0] === 'false';
            default:
              return benefit.meta["benefits-type"][0] === 'door';
          }
        case 2:
          switch (status) {
            case 0:
              return benefit.meta["benefits-type"][0] === 'course';
            case 1:
              return benefit.meta["benefits-type"][0] === 'course' && benefit.meta["active-benefits"][0] === 'true';
            case 2:
              return benefit.meta["benefits-type"][0] === 'course' && benefit.meta["active-benefits"][0] === 'false';
            default:
              return benefit.meta["benefits-type"][0] === 'course';
          }
        case 3:
          switch (status) {
            case 0:
              return benefit.meta["benefits-type"][0] === 'event';
            case 1:
              return benefit.meta["benefits-type"][0] === 'event' && benefit.meta["active-benefits"][0] === 'true';
            case 2:
              return benefit.meta["benefits-type"][0] === 'event' && benefit.meta["active-benefits"][0] === 'false';
            default:
              return benefit.meta["benefits-type"][0] === 'event';
          }
        case 4:
          switch (status) {
            case 0:
              return benefit.meta["benefits-type"][0] === 'gift';
            case 1:
              return benefit.meta["benefits-type"][0] === 'gift' && benefit.meta["active-benefits"][0] === 'true';
            case 2:
              return benefit.meta["benefits-type"][0] === 'gift' && benefit.meta["active-benefits"][0] === 'false';
            default:
              return benefit.meta["benefits-type"][0] === 'gift';
          }
        default:
          return benefit.ID
      }
    }
    ));
  }

  function callGet() {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (token) {
        getBenefits()
          .then(
            (response) => {
              const { success, data } = response;
              if (!success) {
                setBenefits([]);
                setIsLoad(true);
                setErrorMsg({ error: true, msg: 'הטבות - error' });
              }
              else {
                function compare(a, b) {
                  if (a.meta['active-benefits'] < b.meta['active-benefits']) {
                    return 1;
                  }
                  if (a.meta['active-benefits'] > b.meta['active-benefits']) {
                    return -1;
                  }
                  return 0;
                }
                data.sort(compare);
                setBenefits(data);
                setFilterBenefits(data);
                setIsLoad(true);
              }
            })
          .catch((err) => {
            console.log('Error! c', err);
            setBenefits([]);
            setErrorMsg({ error: true, msg: 'הטבות - error c' });
            setIsLoad(true);
          }
          );
      }
    }
  }

  useEffect(() => {
    callGet();
  }, [])

  const createBenefits = () => {
    return filterBenefits.map((prop, key) => {
      return (
        <Col key={key} md="6" lg="5" xl="4" className="scale-in-center d-flex align-self-stretch">
          <Benefit
            key={key}
            props={prop}
          />
        </Col>
      )
    })
  }

  return (
    <>
      <BenefitsHeader name="הדלתות נפתחות"
        typeView={typeView} setTypeView={handletypeView}
        activeView={activeView} setActiveView={handleActiveView}
      />
      <Container className="mt--6" >
        <Row className="slide-top card-wrapper justify-content-center px-3 px-sm-0 ">
          <Col className="px-2 scale-in-center " md="6" lg="5" xl="4">
            <Lottery />
          </Col>
          {isLoad ?
            <>
              {createBenefits()}
            </>
            :
            <div>
              <Row className="w-100 p-0 m-0 justify-content-center">
                <img
                  alt="load"
                  className=""
                  style={{
                    maxHeight: 128,
                    maxWidth: 160
                  }}
                  src={require("assets/img/brand/nefesh to achim.svg").default}
                />

              </Row>
              <Row className="p-0 m-0 w-100 justify-content-center">
                <h1 className="load-text">ההטבות בדרך</h1>
              </Row>
            </div>
          }
        </Row>
      </Container>
    </>
  );
}

export default Benefits;
