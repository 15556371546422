import React, { useContext } from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './swiper.css'
//components
import Study from "views/pages/studyes/StudySlider";
//context
import StudyesContext from 'context/StudyesContext';
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Zoom]);

export default () => {

  const { studyes } = useContext(StudyesContext);

  function slidesPerView() {
    if (window.innerWidth < 500) {
      return 1;
    } else if (window.innerWidth < 1000){
      return 1;
    } else if (window.innerWidth < 1500){
      return 1;
    }else{
      return 1;
    }
  }


  const slides = () => {
    return studyes.map((item, key) => {
      return (
        <SwiperSlide
          key={key}
        >
          <Study
            className=""
            props={item}
          />
        </SwiperSlide>
      );
    });
  }

  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={slidesPerView()}
      navigation
      scrollbar
    >
      {slides()}
    </Swiper>
  );
};