import React, { useContext, useState } from 'react';
//context
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
// react library for routing
import { useLocation, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
import {
  BoxArrowRight, List, PersonCircle,
  DoorOpen, DoorOpenFill, House, HouseFill, Book,
  BookFill, Clock, ClockFill, PlusCircle, PlusCircleFill, CalendarEvent, CalendarEventFill
} from 'react-bootstrap-icons';

function AppNavbar() {
  const [profileIsOpen, setProfileIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const userFullname = `${user.data.meta.first_name[0]} ${user.data.meta.last_name[0]}`
  const { setHours } = useContext(HoursContext);

  const toggle = () => setIsOpen(!isOpen);

  const routes = [
    {
      path: "/app/home",
      name: "דף הבית",
      icon: <House size={22} className="mb-1" />,
      iconActive: <HouseFill size={22} className="mb-1" />,
      color: "text-achim-blue",
      layout: "",
    },
    {
      path: "/app/hours",
      name: "שעות לימוד",
      icon: <Clock size={22} className="mb-1" />,
      iconActive: <ClockFill size={22} className="mb-1" />,
      color: "text-achim-blue",
      layout: "",
    },
    {
      path: "/app/benefits",
      name: "הטבות",
      icon: <DoorOpen size={22} className="mb-1" />,
      iconActive: <DoorOpenFill size={22} className="mb-1" />,
      color: "text-achim-pink",
      layout: "",
    },
    {
      path: "/app/study",
      name: "תכני לימוד",
      icon: <Book size={22} className="mb-1" />,
      iconActive: <BookFill size={22} className="mb-1" />,
      color: "text-achim-orange",
      layout: "",
    },
    {
      path: "/app/events",
      name: "אירועים",
      icon: <CalendarEvent size={22} className="mb-1" />,
      iconActive: <CalendarEventFill size={22} className="mb-1" />,
      color: "text-achim-green",
      layout: "",
    },
    {
      path: "/app/add-hour",
      name: "דיווח שעה",
      icon: <PlusCircle size={22} className="mb-1" />,
      iconActive: <PlusCircleFill size={22} className="mb-1" />,
      color: "text-achim-blue",
      layout: "",
    },
  ];

  function loguot() {
    /* e.preventDefault() */
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
    setUser('');
    setHours('');

  }

  const location = useLocation();
  // verifies if routeName is the one active (in browser input)
  const activeRouteIcon = (routeName, icon, iconActive) => {
    return location.pathname.indexOf(routeName) > -1 ? iconActive : icon;
  }; //active!!!
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }; //active!!!
  const activeRouteColor = (routeName, color) => {
    return location.pathname.indexOf(routeName) > -1 ? color : "";
  }; //active!!!

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      return (
        <NavItem
          className={activeRoute(prop.layout + prop.path)}
          key={key}
        >
          <NavLink
            to={prop.layout + prop.path}
            className="mx-2 py-lg-0"
            tag={Link}
          >
            <div
              className={
                classnames(
                  "d-flex justify-content-center",
                  activeRouteColor(prop.layout + prop.path, prop.color)
                )}>
              {activeRouteIcon(prop.layout + prop.path, prop.icon, prop.iconActive)}
            </div>
            <div
              className=""
              className={classnames(
                "nav-link-inner--text text-center",
                activeRouteColor(prop.layout + prop.path, prop.color)
              )}
            >
              <b>{prop.name}</b>
            </div>
          </NavLink>
          <vr></vr>
        </NavItem >
      );
    });
  };


  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-light bg-white
        container-fluid shadow-xl
        position-sticky fixed-top"
        expand="lg"
        id="navbar-main"
      >
        <button
          aria-controls="navbar-collapse"
          aria-expanded={false}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-target="#navbar-collapse"
          data-toggle="collapse"
          id="navbar-collapse"
          type="button"
          onClick={toggle}
        >
          <span className="" >
            <List size={32} className="" />
          </span>
        </button>
        <Link to="/">
          <img
            alt="..."
            src={require("assets/img/brand/blue.png").default}
            width={80}
          />
        </Link>
        <UncontrolledCollapse
          className="navbar-custom-collapse"
          navbar
          toggler="#navbar-collapse"
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-close" xs="6">
                <button
                  aria-controls="navbar-collapse"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                  data-target="#navbar-collapse"
                  data-toggle="collapse"
                  id="navbar-collapse"
                  type="button"
                >
                  <span />
                  <span />
                </button>
              </Col>
              <Col className="collapse-brand" xs="6">
                <Link to="/">
                  <img
                    alt="..."
                    src={require("assets/img/brand/blue.png").default}
                  />
                </Link>
              </Col>
            </Row>
          </div>
          <Nav className="mr-auto ml-auto" navbar>
            {createLinks(routes)}
          </Nav>
        </UncontrolledCollapse>
        <Nav navbar className="align-items-center p-0" >
          <UncontrolledDropdown
            nav
            isOpen={profileIsOpen}
            onMouseOver={() => setProfileIsOpen(true)}
            onClick={() => setProfileIsOpen(true)}
            onMouseLeave={() => setProfileIsOpen(false)}
            toggle={() => setProfileIsOpen(!profileIsOpen)}
          >
            <DropdownToggle
              className="nav-link" color="" tag="a"
            >
              <Media className="align-items-center">
                <PersonCircle size={30} />
                <Media className="mr-2">
                  {user && <span className="d-none d-lg-block mb-0 text-sm font-weight-bold">
                    {userFullname}
                  </span>}
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className="noti-title" header tag="div">
                <h5 className="text-overflow m-0">שלום {userFullname}</h5>
              </DropdownItem>
              <DropdownItem>
                <NavLink
                  to={'/app/profile'}
                  tag={Link}
                >
                  <i className="text-achim-black"><PersonCircle size={22} className="mb-1" /></i>
                  <span className="text-achim-black mr-2">הפרופיל שלי</span>
                </NavLink>
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={loguot}
              >
                <i><BoxArrowRight size={22} className="mb-1" /></i>
                <span className="nav-link-text mr-2">יציאה</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    </>
  );
}

export default AppNavbar;
