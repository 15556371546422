export default function getBenefitsAdmin() {
    let token = localStorage.getItem("token");
    let urls = [
        `https://achimlanefesh.org.il/wp-json/achim/v1/benefits?per=15&paged=1`,
        `/wp-json/achim/v1/benefits/subs/count`
    ]
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return Promise.all(urls.map(u => fetch(u, options))).then(responses =>
        Promise.all(responses.map(res => res.json()))
    ).then(res => {
        return res;
    })
}