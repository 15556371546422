export default function getQueriesHours(data) {
    const token = localStorage.getItem("token");
    const { date_from, date_to, all, bonus, group_by, filter_by, filter_like } = data;
    const urls = [
        `/wp-json/achim/v1/hours/queries?limit=15&offset=0&date_from=${date_from}&date_to=${date_to}&all=${all}&bonus=${bonus}
        &group_by=${group_by}&filter_by=${filter_by}&filter_like=${filter_like}&summary=0`,
        `/wp-json/achim/v1/hours/queries?limit=15&offset=0&date_from=${date_from}&date_to=${date_to}&all=${all}&bonus=${bonus}
        &group_by=${group_by}&filter_by=${filter_by}&filter_like=${filter_like}&summary=1`
    ]
    const options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return Promise.all(urls.map(u => fetch(u, options))).then(responses =>
        Promise.all(responses.map(res => res.json()))
    ).then(res => {
        return res;
    })
}