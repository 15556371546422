import React, { useContext, useState } from "react";
//context
import UserContext from 'context/UserContext';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";

function Profile() {
  const { user } = useContext(UserContext);
  const [formValues, setFormValues] = useState({
    id: user.ID,
    email: user.data.user_login,
    first_name: user.data.meta.first_name,
    last_name: user.data.meta.last_name,
    phone: user.data.meta.phone,
    guide: user.data.meta.guide,
    contact: user.data.meta.contact,
    city: '',
    country: '',
    note: '',
  });

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  return (
    <>
      <ProfileHeader user_name={formValues.first_name} />
      <Container className="mt--8" fluid>
        <Row className="justify-content-center">
          <Col className="order-xl-1" xl="8">
            <Card className="px-lg-4">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8" className="text-right">
                    <h3 className="mb-0">פרופיל משתמש</h3>
                  </Col>
                  <Col className="text-left" xs="4">
                    <Button
                      color="light"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                      disabled
                    >
                      תצוגה בלבד
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    פרטים אישיים
                  </h6>
                  <div>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="first_name"
                          >
                            שם פרטי
                          </label>
                          <Input
                            id="first_name"
                            placeholder="שם פרטי"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleChange}
                            required
                            value={formValues.first_name}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="last_name"
                          >
                            שם משפחה
                          </label>
                          <Input
                            id="last_name"
                            placeholder="שם משפחה"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleChange}
                            required
                            value={formValues.last_name}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="phone"
                          >
                            פלאפון
                          </label>
                          <Input
                            id="phone"
                            placeholder="פלאפון"
                            type="phone"
                            onChange={handleChange}
                            onBlur={handleChange}
                            required
                            value={formValues.phone}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="email"
                          >
                            אימייל
                          </label>
                          <Input
                            id="email"
                            placeholder="jesse@example.com"
                            type="אימייל"
                            onChange={handleChange}
                            onBlur={handleChange}
                            required
                            value={formValues.email}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    אחים לנפש
                  </h6>
                  <div>
                    <Row>
                    <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="guide"
                          >
                            חברותא
                          </label>
                          <Input
                            id="guide"
                            placeholder="חברותא"
                            type="text"
                            value={formValues.guide}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="contact"
                          >
                            איש קשר
                          </label>
                          <Input
                            id="contact"
                            placeholder="איש קשר"
                            type="text"
                            value={formValues.contact}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                   {/*  <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            מדינה
                          </label>
                          <Input
                            id="input-country"
                            placeholder="מדינה"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-street"
                          >
                            רחוב
                          </label>
                          <Input
                            id="input-street"
                            placeholder="רחוב"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            מס' בית
                          </label>
                          <Input
                            id="input-country"
                            placeholder="מס' בית"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            מס' דירה
                          </label>
                          <Input
                            id="input-postal-code"
                            placeholder="מס' דירה"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            קומה
                          </label>
                          <Input
                            id="input-postal-code"
                            placeholder="קומה"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            כניסה
                          </label>
                          <Input
                            id="input-country"
                            placeholder="כניסה"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            מיקוד
                          </label>
                          <Input
                            id="input-postal-code"
                            placeholder="מיקוד"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            הערות לכתובת
                          </label>
                          <Input
                            id="input-postal-code"
                            placeholder="הערות לכתובת"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profile;
