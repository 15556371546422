export default function getHours(userId) {
    let token = localStorage.getItem("token");
    let urls = [
        `/wp-json/achim/v1/hours/${userId}?limit=100&offset=0`,
        `/wp-json/achim/v1/hours/${userId}/summary`
    ]
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return Promise.all(urls.map(u => fetch(u, options))).then(responses =>
        Promise.all(responses.map(res => res.json()))
    ).then(res => {
        return res;
    })
}

function getHours2(userId) {
    let token = localStorage.getItem("token");
    let url = `/wp-json/achim/v1/hours/${userId}/summary`;
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return fetch(url, options)
        .then((response) => {
            return response.json();
        })
        .then((jsonObject) => {
            return jsonObject;
        });
}

function getHours1() {
    let token = localStorage.getItem("token");
    let url = '/wp-json/achim/v1/hours/1/summary';
    let options = {
        method: 'GET',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    return fetch(url, options)
        .then(response => response.json())
        .then(data => {
            return fetch(url, options);
        })
        .then(response => response.json())
        .then(data => {
            const { negative, positive, total } = data.data[0];
            return data;
        })
        .catch(err => {
            console.error('Request failed', err)
        })


}

//// call
/*
function getHoursSummary() {
    getHours()
        .then(
            (response) => {
                const { success, data } = response;
                if (!success) {
                    setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
                }
                else {
                    setHours(data[0]);
                }
            })
        .catch(() => {
            setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
            console.log('Error! c');
        }
        );
}
*/

