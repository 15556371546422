import React from 'react';
// reactstrap components
import {
  Card,
  Row,
  Col,
  CardImgOverlay,
  CardBody
} from "reactstrap";
import { CalendarEvent, GeoAlt } from 'react-bootstrap-icons';

function Event() {

  return (
    <div className="study-slider">
      <Card className="bg-darker rounded-0 text-white achim-shadow mb-0">
        <div
        >
          <div
            className="card-bg-image opacity-4"
            style={{
              backgroundImage: `url(${require(`assets/img/theme/4.jpg`).default})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '150px',
            }}
          />
        </div>
        <CardImgOverlay className="p-4 text-center">
          <div className="w-100 h-100 d-flex align-items-center text-center">
            <h5 className="w-100 h1 lh-110 text-white mb-2">
              בקרוב: ארכיון האירועים
            </h5>
          </div>
        </CardImgOverlay>
      </Card>
      <Card className="bg-lighter rounded-0 text-white achim-shadow">
        <CardBody className="py-2 px-4 bg-gradient--lighter border-top border-bottom text-achim-green font-weight-400">
          <Row>
          <Col className="my-1 p-0">
            <div
              className="col"
            >
              <CalendarEvent size={17} className="m-0 ml-2" />
              <span
                className="h4 lh-100 font-weight-500 text-achim-green"
                 >כל האירועים</span>
            </div>
          </Col>
          <Col className="my-1 p-0">
            <div className="col">
              <GeoAlt size={17} className="m-0 ml-2" />
              <span className="h3 lh-100 font-weight-500 text-achim-green">שידור zoom</span>
            </div>
          </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default Event;
