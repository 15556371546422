import React, { useState, useContext } from 'react';
import { Redirect } from "react-router-dom";
//context
import UserContext from 'context/UserContext';
import HoursContext from 'context/HoursContext';
import ErrorMsgContext from 'context/ErrorMsgContext';
//services
import postHours from 'services/postHours';
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import ActionHeader from "components/Headers/ActionHeader.js";
import Pending from "components/custom/Pending";

function HoursAdd() {
  const [pending, setPending] = useState(false);
  const [sent, setSent] = useState(false);
  const { user } = useContext(UserContext);
  const guide = user.data.meta.guide;
  const { hours, setHours } = useContext(HoursContext);
  const { setErrorMsg } = useContext(ErrorMsgContext);
  const [formValues, setFormValues] = useState({
    amount: 1,
    type: 'לימוד בחברותא',
    day: 'היום',
    chevruta: guide,
    study_topic: '',
    insight: '',
    note: '',
    date: dateToDate1(new Date()),
    time_to: timeToTime(new Date()),
    time_from: timeToTime(new Date(new Date().getTime() - (1 * 60 * 60 * 1000)))
  });

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormValues({
      ...formValues,
      [name]: value
    });
  }

  function handleReactDatetimeChange(who, date) {
    let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    let year = date.getFullYear() > 9 ? date.getFullYear() : '0' + date.getFullYear();
    let month = date.getMonth() > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    date = `${day}/${month}/${year}`
    setFormValues({
      ...formValues,
      [who]: date
    });
  };

  function dateToDate1(date) {
    let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    let year = date.getFullYear() > 9 ? date.getFullYear() : '0' + date.getFullYear();
    let month = date.getMonth() > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    return `${day}/${month}/${year}`
  };

  const handleReacttimeChange = (who, date) => {
    setFormValues({
      ...formValues,
      [who]: (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
        ':' + (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())
    });
  };

  function timeToTime(date) {
    return (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' + (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes());
  };

  function dateToDate(date) {
    const array = date.split('/');
    return `${array[2]}-${array[1]}-${array[0]}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //אלה שלא נגעו בהם
    setHour();
  }

  function setHour() {
    setPending(true);
    const values = {
      ...formValues,
      ['date']: dateToDate(formValues.date)
    };
    postHours(user.ID, values)
      .then(
        (response) => {
          const { success, data } = response;
          if (!success) {
            setErrorMsg({ error: true, msg: 'העדכון נכשל, נסה/י שוב' });
            setPending(false);
          }
          else {
            setHours({
              ...hours,
              ['positive']: hours.positive * 1 + 1,
              ['total']: hours.total * 1 + 1
            });
            setErrorMsg({ error: true, msg: 'צברת שעה!' , color: 'success'});
            setSent(true);
          }
        })
      .catch((err) => {
        setErrorMsg({ error: true, msg: 'בעיית תקשורת, תיכף נשוב' });
        console.log('Error! c');
        setPending(false);
      }
      );
  }

  return (
    <>
      {sent && <Redirect to="/app/home" push={true} />}
      <ActionHeader name="דיווח שעת לימוד" parentName="פעולות"  color="blue"/>
      <Container className="mt--7  pb-5">
        <Row className="justify-content-center" >
          <Col lg="6">
            <div className="card-wrapper">
              <Card>
                {pending && <Pending />}
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit}>
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="type"
                      >
                        בחר/י:
                      </label>
                      <Input
                        id="type"
                        type="select"
                        name="type"
                        onChange={handleChange}
                        onBlur={handleChange}
                        required
                        value={formValues.type}
                      >
                        <option>לימוד בחברותא</option>
                        <option>חבר מביא חבר</option>
                        <option >חוג בית</option>
                      </Input>
                    </FormGroup>
                    <Row>
                      {/*  <Col md="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="day"
                          >
                            מתי:
                          </label>
                          <Input
                            id="day"
                            type="select"
                            name="day"
                            onChange={handleChange}
                            onBlur={handleChange}
                            required
                            value={formValues.day}
                          >
                            <option>היום</option>
                            <option>אתמול</option>
                            <option>תאריך אחר</option>
                          </Input>
                        </FormGroup>
                      </Col> */}
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="date"
                          >
                            התאריך
                          </label>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "תאריך",
                              id: "date",
                              name: "date",
                            }}
                            value={formValues.date}
                            dateFormat="DD/MM/YYYY"
                            timeFormat={false}
                            onChange={(e) =>
                              handleReactDatetimeChange("date", e._d)
                            }
                            required
                            closeOnSelect={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="time_from"
                          >
                            משעה
                          </label>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "משעה",
                              id: "time_from",
                              name: "time_from",
                              required: true
                            }}
                            value={formValues.time_from}
                            timeFormat="HH:mm"
                            dateFormat={false}
                            onChange={(e) =>
                              handleReacttimeChange("time_from", e._d)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="time_to"
                          >
                            עד שעה
                          </label>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "עד שעה",
                              id: "time_to",
                              name: "time_to",
                              required: true
                            }}
                            value={formValues.time_to}
                            timeFormat="HH:mm"
                            dateFormat={false}
                            input={true}
                            onChange={(e) =>
                              handleReacttimeChange("time_to", e._d)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="chevruta"
                          >
                            החברותא שלי:
                          </label>
                          <Input
                            id="chevruta"
                            type="text"
                            name="chevruta"
                            placeholder="החברותא שלי"
                            onChange={handleChange}
                            onBlur={handleChange}
                            required
                            value={formValues.chevruta}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="study_topic"
                          >
                            הנושא הנלמד
                          </label>
                          <Input
                            id="study_topic"
                            type="text"
                            name="study_topic"
                            placeholder="הנושא הנלמד"
                            onChange={handleChange}
                            onBlur={handleChange}
                            required
                            value={formValues.study_topic}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="insight"
                          >
                            תובנה שאספתי לדרך
                          </label>
                          <Input
                            id="insight"
                            rows="2"
                            type="textarea"
                            name="insight"
                            placeholder="תובנה שאספתי לדרך"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={formValues.insight}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="note"
                          >
                            הערות
                          </label>
                          <Input
                            id="note"
                            rows="2"
                            type="textarea"
                            name="note"
                            placeholder="הערות"
                            onChange={handleChange}
                            onBlur={handleChange}
                            value={formValues.note}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button
                      block
                      color="green"
                      className="bg-achim-green text-white"
                      type="submit"
                    >
                      לשלוח  <i className="text-white fas fa-chevron-left fa-xs"></i>
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HoursAdd;
