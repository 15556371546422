/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
} from "reactstrap";

function TimelineHeader({ name, parentName, color }) {
  return (
    <>
      <div className={`header header-dark bg-achim-${color} pb-7 content__title content__title--calendar`}>
        <Container fluid>
          <div className="slide-top header-body">
            <Row className="d-none align-items-center py-4">
              <Breadcrumb
                className="d-md-inline-block ml-lg-4"
                listClassName="breadcrumb-links breadcrumb-dark"
              >
                <BreadcrumbItem className="ml-2">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <i className="fas fa-home" />
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    {parentName}
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem aria-current="page" className="active">
                  {name}
                </BreadcrumbItem>
              </Breadcrumb>
            </Row>
            <Row className="align-items-center py-4">
              <h6 className="display-2 text-white mb-0 m-auto">
                {name}
              </h6>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
